import fonts from './fonts.json';
import grades from './grades.json';
import menus from './menus.json';
import prefectures from './prefectures.json';
import ranks from './ranks.json';
import termStatus from './termStatus.json';
import viewpoints from './viewpoints.json';

const i18n = {
  fonts,
  grades,
  menus,
  prefectures,
  termStatus,
  ranks,
  viewpoints,
};

export default i18n;
