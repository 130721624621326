import { Box, Button, Stack, SxProps, Theme, Typography } from '@mui/material';
import React, { memo, ReactNode } from 'react';

type Props = {
  onClose: () => void;
};

const Note = ({
  prefix = '・',
  sx,
  children,
}: {
  prefix?: string;
  sx?: SxProps<Theme>;
  children: ReactNode;
}) => (
  <Stack direction="row" gap={1} sx={{ m: 0, p: 0, ...sx }}>
    <Box sx={{ lineHeight: 1.6 }}>{prefix}</Box>
    <Box>{children}</Box>
  </Stack>
);

export const TrialDetail = memo(({ onClose }: Props) => (
  <Stack
    direction="column"
    justifyContent="space-between"
    spacing={2}
    sx={{
      flex: 1,
    }}
  >
    <Box>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 0, md: 4 }}>
        <Box sx={{ width: { xs: '100%', md: '50%' } }}>
          <Stack spacing={1} sx={{ mb: 4 }}>
            <Note prefix="1.">全書体の手本閲覧/解説動画の視聴</Note>
            <Note prefix="2.">全体成績（他ユーザー様の作品）閲覧</Note>
            <Note prefix="3.">
              <Box component="span" sx={{ typography: 'body2', fontWeight: 'bold' }}>
                1回に限り
              </Box>
              <Box component="span">作品の提出</Box>
              <Typography sx={{ fontSize: 12 }}>
                ご提出いただいた作品につきましては、審査員から評価コメントおよび段級位を受け取ることができます。認定された段級位は本登録後も引き継がれます。
              </Typography>
            </Note>
          </Stack>
        </Box>
        <Box sx={{ width: { xs: '100%', md: '50%' } }}>
          <Box sx={{ fontWeight: 'bold', mb: 1 }}>競書スケジュール</Box>
          <Box sx={{ mb: { xs: 2, md: 0 }, ml: 1 }}>
            <Box sx={{ fontWeight: 'bold' }}>毎月25日</Box>
            <Box>・翌月度の課題公開</Box>
            <Box>・前月度の成績発表</Box>
            <Box sx={{ fontWeight: 'bold', mt: 1 }}>毎月10日</Box>
            <Box>・当月の課題提出期限</Box>
          </Box>
        </Box>
      </Stack>
      <Stack direction={{ xs: 'column-reverse', md: 'row' }} spacing={4} sx={{ pb: 2 }}>
        <Box sx={{ width: { xs: '100%', md: '50%' } }}>
          <Box sx={{ fontWeight: 'bold', mb: 1 }}>トライアルの最大期間：60日</Box>
          <Box sx={{ fontSize: 12 }}>
            ご提出いただいた作品につきましては、審査員から評価コメントおよび段級位を受け取ることができます。認定された段級位は本登録後も引き継がれます。
          </Box>
        </Box>
        <Box sx={{ width: { xs: '100%', md: '50%' } }}>
          <Box sx={{ fontSize: 12, fontWeight: 'bold' }}>例：4月</Box>
          <img src="/schedule.svg" alt="" width="100%" />
        </Box>
      </Stack>
    </Box>
    {/* トライアル説明を閉じるボタン */}
    <Button
      variant="contained"
      sx={{
        height: 41,
        borderRadius: 1,
        bgcolor: '#f7fafa',
        color: '#1976d2',
        border: '1px solid #1976d2',
        boxShadow: 0,
        '&:hover': {
          bgcolor: '#f7fafa',
          color: '#1976d2',
          border: '1px solid #1976d2',
        },
      }}
      onClick={onClose}
    >
      トライアルの説明を閉じる
    </Button>
  </Stack>
));
TrialDetail.displayName = 'TrialDetail';
