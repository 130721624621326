import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogTitle, IconButton } from '@mui/material';
import React from 'react';

interface CompletionDialogProps {
  open: boolean;
  onClose: () => void;
  hasNextUser: boolean;
  onclickNextUser: () => void;
}

const CompletionDialog: React.FC<CompletionDialogProps> = ({
  open,
  onClose,
  hasNextUser,
  onclickNextUser,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{ '& .MuiDialog-paper': { width: '80%' } }}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center' }}>
        {hasNextUser ? '次の人の採点に進めます' : '採点が完了しました!'}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      {hasNextUser ? (
        <DialogActions>
          <Button
            variant="contained"
            fullWidth
            onClick={() => {
              onClose();
              onclickNextUser();
            }}
            disabled={!hasNextUser}
          >
            次へ
          </Button>
        </DialogActions>
      ) : null}
    </Dialog>
  );
};

export default CompletionDialog;
