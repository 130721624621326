import { Circle, ExpandCircleDown, ExpandLess } from '@mui/icons-material';
import {
  Box,
  Chip,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useState } from 'react';

import Loader from '@/components/Loader';
import { AssignmentStudentCaseWithStatus, FontCase, OpenObject } from '@/types';

type Props = {
  preStudentCases: AssignmentStudentCaseWithStatus[];
  esStudentCases: AssignmentStudentCaseWithStatus[];
  jhsStudentCases: AssignmentStudentCaseWithStatus[];
  loading: boolean;
  onClickItem: (font: FontCase, gradeId?: number) => void;
  clearState: () => void;
  selectedFont: FontCase | undefined;
};

export default function StudentFontList({
  preStudentCases,
  esStudentCases,
  jhsStudentCases,
  loading,
  onClickItem,
  clearState,
  selectedFont,
}: Props) {
  const studentInfoList = [
    { key: 'pre', label: '幼年の部', caseList: preStudentCases },
    { key: 'es', label: '小学生の部', caseList: esStudentCases },
    { key: 'jhs', label: '中学生の部', caseList: jhsStudentCases },
  ];

  const [listOpenStatus, setListGradeOpenStatus] = useState<OpenObject>({
    pre_student: true,
    es_first: true,
    es_second: true,
    es_third: true,
    es_fourth: true,
    es_fifth: true,
    es_sixth: true,
    jhs_first: true,
    jhs_second: true,
    jhs_third: true,
    first: true,
    second: true,
    third: true,
  });

  const handleClick = (key: keyof OpenObject) => {
    setListGradeOpenStatus({ ...listOpenStatus, [key]: !listOpenStatus[key] });
    clearState();
  };

  return loading ? (
    <Loader />
  ) : (
    <Box>
      {studentInfoList.map(({ key, label, caseList }) => (
        <List
          key={key}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              {label}
            </ListSubheader>
          }
        >
          {caseList.map((studentCase) => (
            <React.Fragment key={studentCase.open_key}>
              <ListItemButton onClick={() => handleClick(studentCase.open_key)}>
                {listOpenStatus[studentCase.open_key] ? (
                  <ExpandCircleDown fontSize="small" sx={{ color: grey[600] }} />
                ) : (
                  <ExpandLess />
                )}
                <ListItemText primary={studentCase.value} />
              </ListItemButton>
              <Collapse in={!listOpenStatus[studentCase.open_key]} timeout="auto" unmountOnExit>
                {studentCase.fonts_array.map((font) => (
                  <ListItemButton
                    key={font.name}
                    disabled={!font.handbook_id.length}
                    onClick={() => onClickItem(font, studentCase.grade_id)}
                    selected={
                      font.handbook_id[0]
                        ? selectedFont?.handbook_id[0] === font.handbook_id[0]
                        : false
                    }
                  >
                    <Circle fontSize="small" sx={{ color: grey[600] }} />
                    <ListItemText primary={font.name} />
                    <Chip
                      label={
                        font.font_submitter_count === undefined
                          ? '提出数0名'
                          : '提出数' + `${font.font_submitter_count}名`
                      }
                    />
                  </ListItemButton>
                ))}
              </Collapse>
            </React.Fragment>
          ))}
        </List>
      ))}
    </Box>
  );
}
