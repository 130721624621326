import { Box, Divider, List, ListItem, Typography } from '@mui/material';
import React from 'react';

type Props = {
  comments: string[] | undefined;
};

export default function CommentContent({ comments }: Props) {
  return (
    <Box
      sx={{
        flex: 1,
        border: 1,
        borderColor: 'grey.400',
        borderRadius: 2,
        p: 2,
        pb: 1,
        ml: [0, 1],
        mt: [1, 0],
      }}
    >
      <Typography sx={{ color: 'grey.400', fontSize: 10 }}>コメント</Typography>
      <List sx={{ p: 0 }}>
        {comments?.map((comment, i) => (
          <ListItem
            key={i}
            sx={{ px: 0, borderTop: i !== 0 ? 1 : 0, borderTopColor: i !== 0 ? 'grey.200' : '' }}
          >
            {i !== 0 && <Divider component="div" />}
            <Box>{comment}</Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
