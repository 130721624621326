import { Chip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';

import { RankArchivesInner } from '@/generated/types/typescript-axios';
import { Font } from '@/types';

type Props = {
  isStudent: boolean;
  fontCases: Font[];
  nickname: string;
  rankChanges: RankArchivesInner[] | undefined;
  targetFontId: number;
  handleClickFont: (fontId: number) => void;
};

export default function Heading({
  isStudent,
  fontCases,
  nickname,
  rankChanges,
  targetFontId,
  handleClickFont,
}: Props) {
  const allFontsId = 0;
  const overallFontId = 100;
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      gap={2}
      flexWrap="wrap"
      sx={{ mb: 3, px: [1, 0] }}
    >
      {/* ユーザー名 */}
      <Typography>{nickname}</Typography>
      {/* 書体Chips */}
      <Stack direction="row" spacing={1} sx={{ overflow: 'auto' }}>
        {/* すべて */}
        <Chip
          color={targetFontId === allFontsId ? 'primary' : 'default'}
          label="すべて"
          variant="outlined"
          onClick={targetFontId === allFontsId ? undefined : () => handleClickFont(allFontsId)}
        />
        {/* 総合 */}
        {!isStudent ? (
          <Chip
            color={targetFontId === overallFontId ? 'primary' : 'default'}
            label="総合"
            variant={
              rankChanges?.find((rankChangesItem) => {
                return rankChangesItem?.font?.id == null;
              })?.results?.length
                ? 'outlined'
                : 'filled'
            }
            onClick={
              rankChanges?.find((rankChangesItem) => {
                return rankChangesItem?.font?.id == null;
              })?.results?.length
                ? targetFontId === overallFontId
                  ? undefined
                  : () => handleClickFont(overallFontId)
                : undefined
            }
          />
        ) : null}
        {/* 各書体 */}
        {fontCases?.map(({ id, name }) => (
          <Chip
            key={id}
            color={targetFontId === id ? 'primary' : 'default'}
            label={name}
            variant={
              rankChanges?.find((rankChangesItem) => {
                return rankChangesItem?.font?.id == id;
              })
                ? 'outlined'
                : 'filled'
            }
            onClick={
              rankChanges?.find((rankChangesItem) => {
                return rankChangesItem?.font?.id == id;
              })
                ? () => handleClickFont(id)
                : undefined
            }
          />
        ))}
      </Stack>
    </Stack>
  );
}
