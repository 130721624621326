import { GradeId } from '@/types';

export type Grade = {
  id: GradeId;
  value: string;
};

export type GradeGroup = {
  label: string;
  grades: Grade[];
};

export const GradeList = [
  { label: '幼年の部', grades: [{ id: GradeId.PRE_STUDENT, value: '幼年' }] },
  {
    label: '小学生の部',
    grades: [
      { id: GradeId.ES_FIRST, value: '1年生' },
      { id: GradeId.ES_SECOND, value: '2年生' },
      { id: GradeId.ES_THIRD, value: '3年生' },
      { id: GradeId.ES_FOURTH, value: '4年生' },
      { id: GradeId.ES_FIFTH, value: '5年生' },
      { id: GradeId.ES_SIXTH, value: '6年生' },
    ],
  },
  {
    label: '中学生の部',
    grades: [
      { id: GradeId.JHS_FIRST, value: '1年生' },
      { id: GradeId.JHS_SECOND, value: '2年生' },
      { id: GradeId.JHS_THIRD, value: '3年生' },
    ],
  },
];

export const findGrade = (GradeList: GradeGroup[], selectGrade: number) => {
  const result = { label: '', value: '' };

  for (const group of GradeList) {
    for (const grade of group.grades) {
      if (grade.id === selectGrade) {
        result.label = group.label;
        result.value = grade.value;
        break;
      }
    }

    if (result.label) break;
  }

  return result;
};
