import { Box, Button, Link, Stack, SxProps, Theme, Typography } from '@mui/material';
import React, { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useToast } from '@/components/ToastProvider';
import { authSelector } from '@/services/authSlice';
import { startTrial, userSelector } from '@/services/userSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { AsyncStatus } from '@/types';

const LOGIN_URL = process.env.REACT_APP_SAASUS_URL ?? '';

const Note = ({
  prefix = '・',
  sx,
  children,
}: {
  prefix?: string;
  sx?: SxProps<Theme>;
  children: ReactNode;
}) => (
  <Stack direction="row" gap={1} sx={{ m: 0, p: 0, ...sx }}>
    <Box sx={{ lineHeight: 1.6 }}>{prefix}</Box>
    <Box>{children}</Box>
  </Stack>
);

const Plan = () => {
  const { t } = useTranslation();
  const { account } = useAppSelector(authSelector);
  const dispatch = useAppDispatch();
  const { status } = useAppSelector(userSelector);
  const navigate = useNavigate();
  const { toast } = useToast();
  const { state } = useLocation();

  useEffect(() => {
    if (account.is_subscribed || (account.subscription_status && !state))
      window.location.href = LOGIN_URL;
  }, []);

  useEffect(() => {
    if (status.trial === AsyncStatus.SUCCESS) {
      toast({ message: t('pages:sign_up:messages:trial_success') });
      navigate('/');
    }
    if (status.trial === AsyncStatus.FAILED) {
      toast({ message: t('pages:sign_up:messages:trial_error'), type: 'error' });
    }
  }, [status]);

  const handleTrialStart = () => {
    dispatch(startTrial());
  };

  const handleCourseStart = () => {
    const queryParameter = `?prefilled_email=${account.email}&client_reference_id=${account.id}`;
    const url =
      account.course_type === 1
        ? process.env.REACT_APP_STRIPE_PAYMENT_LINK_URL
        : process.env.REACT_APP_STRIPE_PAYMENT_LINK_URL_STUDENT;
    window.open(url + queryParameter, '_blank');
  };

  return (
    <>
      <Box sx={{ maxWidth: 800, m: 'auto', px: [2, 2, 0], py: 6 }}>
        {/* タイトル */}
        <Box sx={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center', mb: [4, 6] }}>
          プラン選択
        </Box>

        {/* プラン一覧 */}
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={[4, 6]} sx={{ fontSize: 14 }}>
          {/* トライアル */}
          <Stack
            sx={{
              borderRadius: '16px',
              boxShadow: 2,
              bgcolor: '#f7fafa',
              flex: 1,
              position: 'relative',
            }}
          >
            {/* トライアルタイトル */}
            <Box
              sx={{
                bgcolor: '#00a76b29',
                borderTopRightRadius: '16px',
                borderTopLeftRadius: '16px',
                fontSize: 18,
                fontWeight: 'bold',
                py: 3,
                textAlign: 'center',
              }}
            >
              トライアル
            </Box>
            {/* トライアル詳細 */}
            <Stack
              direction="column"
              justifyContent="space-between"
              spacing={2}
              sx={{
                flex: 1,
                px: 2,
                pt: 4,
                pb: 7,
              }}
            >
              <Box>
                <Box sx={{ mb: 4 }}>
                  オンライン競書「才幹」では、全ての新規登録ユーザー様へ、サービスお試し期間を設けております。
                </Box>
                <Stack spacing={1} sx={{ mb: 4 }}>
                  <Note prefix="1.">全書体の手本閲覧/解説動画の視聴</Note>
                  <Note prefix="2.">全体成績（他ユーザー様の作品）閲覧</Note>
                  <Note prefix="3.">
                    <Box component="span" sx={{ typography: 'body2', fontWeight: 'bold' }}>
                      1回に限り
                    </Box>
                    <Box component="span">作品の提出</Box>
                    <Typography sx={{ fontSize: 12 }}>
                      ご提出いただいた作品につきましては、審査員から評価コメントおよび段級位を受け取ることができます。認定された段級位は本登録後も引き継がれます。
                    </Typography>
                  </Note>
                </Stack>

                <Box sx={{ mb: 4 }}>
                  <Box sx={{ fontWeight: 'bold', mb: 1 }}>競書スケジュール</Box>
                  <Box>競書スケジュールは、一般コース/学生コースと同じです。</Box>
                </Box>

                <Box sx={{ fontWeight: 'bold', mb: 1 }}>トライアルの最大期間：60日</Box>
                <Box sx={{ fontSize: 12 }}>
                  ご提出いただいた作品につきましては、審査員から評価コメントおよび段級位を受け取ることができます。認定された段級位は本登録後も引き継がれます。
                </Box>
              </Box>
              {/* トライアル開始ボタン */}
              <Button
                variant="contained"
                sx={{
                  mt: 6,
                  height: 41,
                  borderRadius: 1,
                  bgcolor: '#f7fafa',
                  color: '#1976d2',
                  border: '1px solid #1976d2',
                  boxShadow: 0,
                  '&:hover': {
                    bgcolor: '#f7fafa',
                    color: '#1976d2',
                    border: '1px solid #1976d2',
                  },
                }}
                onClick={handleTrialStart}
              >
                トライアルで開始
              </Button>
            </Stack>
          </Stack>

          {/* 一般コース */}
          <Stack
            sx={{
              borderRadius: '16px',
              boxShadow: 2,
              bgcolor: '#f7fafa',
              flex: 1,
              position: 'relative',
            }}
          >
            <Box>
              <Stack
                direction="row"
                sx={{
                  bgcolor: '#1e88e529',
                  borderTopRightRadius: '16px',
                  borderTopLeftRadius: '16px',
                  justifyContent: 'center',
                  py: 3,
                  gap: 0.5,
                }}
              >
                <Box
                  sx={{
                    fontSize: 18,
                    fontWeight: 'bold',
                  }}
                >
                  才幹
                </Box>
                <Box sx={{ fontSize: 18, fontWeight: 'bold' }}>
                  {account.course_type === 1 ? '一般コース' : '学生コース'}
                </Box>
                <Box sx={{ fontSize: 18 }}>入会</Box>
              </Stack>
            </Box>
            <Stack
              direction="column"
              justifyContent="space-between"
              spacing={2}
              sx={{
                flex: 1,
                px: 2,
                pt: 4,
                pb: 7,
              }}
            >
              <Box sx={{ mb: 4 }}>
                <Box sx={{ mb: 4 }}>
                  {account.course_type === 1
                    ? '一般コースでは、全9書体（楷書、行書、草書、隷書、臨書、条幅、実用書、ペン字、仮名）を学び段級位の取得が可能です。'
                    : '学生コースでは、全3書体（半紙毛筆、条幅、硬筆）を学び段級位の取得が可能です。'}
                </Box>
                <Box sx={{ fontWeight: 'bold', mb: 1 }}>競書スケジュール</Box>
                <Box sx={{ mb: 2, ml: 1 }}>
                  <Box sx={{ fontWeight: 'bold' }}>毎月25日</Box>
                  <Box>・翌月度の課題公開</Box>
                  <Box>・前月度の成績発表</Box>
                  <Box sx={{ fontWeight: 'bold', mt: 1 }}>毎月10日</Box>
                  <Box>・当月の課題提出期限</Box>
                </Box>
                <Box sx={{ fontSize: 12, fontWeight: 'bold' }}>例：4月</Box>
                <img src="/schedule.svg" alt="" width="100%" />

                <Box sx={{ mt: 2 }}>
                  {account.course_type === 1 ? (
                    <>
                      <Note>高校生、大学生は一般コースになります。</Note>
                      <Note>新規入会の決済以降、毎月25日に翌月分が自動で引き落としされます。</Note>
                      <Note sx={{ mt: 1 }}>
                        事務局へ学生証明書をご提出いただくと学割（1000円引き）が適用されます。学生割引の申請は
                        <Link
                          href="https://ribbon-jury-464.notion.site/3db0dd6aaa244fedb6106116e6c8e44e"
                          sx={{ typography: 'body2', fontWeight: 'bold' }}
                        >
                          こちら
                        </Link>
                      </Note>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          mt: 2,
                          py: 0.5,
                          color: '#000',
                          borderBottom: '1px solid #bdbebe',
                        }}
                      >
                        <Box sx={{ fontSize: 18, fontWeight: 'bold' }}>一般コース</Box>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                        <Box sx={{ fontSize: 18, fontWeight: 'bold' }}>合計額</Box>
                        <Box sx={{ fontSize: 18, fontWeight: 'bold' }}>¥ 3,000（税込）</Box>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Note>新規入会の決済以降、毎月25日に翌月分が自動で引き落としされます。</Note>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          py: 0.5,
                          color: '#000',
                          borderBottom: '1px solid #bdbebe',
                          mb: 1,
                        }}
                      >
                        <Box sx={{ fontSize: 18, fontWeight: 'bold' }}>
                          学生コース（幼年・小学生・中学生）
                        </Box>
                      </Box>
                      <Stack direction="row" justifyContent="space-between">
                        <Box sx={{ fontSize: 18, fontWeight: 'bold' }}>合計額</Box>
                        <Box sx={{ fontSize: 18, fontWeight: 'bold' }}>¥ 1,000（税込）</Box>
                      </Stack>
                    </>
                  )}
                </Box>
              </Box>
              {/* 入会ボタン */}
              <Button
                variant="contained"
                sx={{
                  mt: 6,
                  height: 41,
                  borderRadius: 1,
                  bgcolor: 'primary.main',
                  color: 'white',
                  boxShadow: 0,
                  '&:hover': {
                    bgcolor: 'primary.dark',
                  },
                }}
                onClick={handleCourseStart}
              >
                {account.course_type === 1 ? '一般コースで開始' : '学生コースで開始'}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};
export default Plan;
