import { Box, Button, Container, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { useToast } from '@/components/ToastProvider';
import useAccount from '@/hooks/useAccount';
import { authSelector, fetchUserInfo } from '@/services/authSlice';
import { createUser, userSelector } from '@/services/userSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { AsyncStatus, URL, User } from '@/types';

export default function SignUpConfirmation() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { account } = useAppSelector(authSelector);
  const { toast } = useToast();
  const { defaultUserValues } = useAccount();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { status } = useAppSelector(userSelector);
  const [user, setUser] = useState<User>(defaultUserValues);

  const LOGIN_URL = process.env.REACT_APP_SAASUS_URL ?? '';

  useEffect(() => {
    if (state) {
      setUser({
        ...user,
        id: account?.id ?? '',
        last_name: state.last_name,
        first_name: state.first_name,
        nickname: state.nickname,
        course_type: state.course_type.value,
        email: state.email,
        gender_id: state.gender.value,
        birthday: state.birthday,
        prefecture: state.prefecture,
        is_experienced: state.is_experienced.value,
        parent_last_name: state.parent_last_name || '-',
        parent_first_name: state.parent_first_name || '-',
      });
    }
  }, [state]);

  useEffect(() => {
    if (status.create === AsyncStatus.SUCCESS) {
      toast({ message: t('pages:sign_up:messages:register_success') });

      dispatch(fetchUserInfo()).then((res) => {
        if (res.meta.requestStatus === 'fulfilled') {
          navigate(URL.PLAN, { state });
        } else {
          window.location.href = LOGIN_URL;
        }
      });
    }
    if (status.create === AsyncStatus.FAILED) {
      toast({ message: t('pages:sign_up:messages:register_error'), type: 'error' });
    }
  }, [status]);

  const onSubmitForm = () => {
    dispatch(createUser(user));
  };

  const onClickCancel = () => {
    navigate(URL.SIGN_UP_REGISTRATION, { state });
  };

  return state ? (
    <Container maxWidth="xs" sx={{ py: [6, 10] }}>
      <Typography variant="h5" textAlign="center" sx={{ fontWeight: 'bold', mb: 6 }}>
        {t('pages:sign_up:title')}
      </Typography>
      <Stack component="form" noValidate spacing={2} sx={{ mb: 6 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ mb: 1, color: 'grey' }}>{t('pages:sign_up:labels:email')}</Typography>
          <Typography>{state.email}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ mb: 1, color: 'grey' }}>
            {t('pages:sign_up:labels:nickname')}
          </Typography>
          <Typography>{state.nickname}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ mb: 1, color: 'grey' }}>{t('pages:sign_up:labels:name')}</Typography>
          <Typography>{`${state.last_name} ${state.first_name}`}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ mb: 1, color: 'grey' }}>{t('pages:sign_up:labels:gender')}</Typography>
          <Typography>{state.gender.label}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ mb: 1, color: 'grey' }}>
            {t('pages:sign_up:labels:prefecture')}
          </Typography>
          <Typography>{state.prefecture}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ mb: 1, color: 'grey' }}>
            {t('pages:sign_up:labels:birthday')}
          </Typography>
          <Typography>{dayjs(state.birthday).format('YYYY年MM月DD日')}</Typography>
        </Box>
        {state.parent_first_name ? (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{ mb: 1, color: 'grey' }}>
              {t('pages:sign_up:labels:parent_name')}
            </Typography>
            <Typography>{`${state.parent_last_name} ${state.parent_first_name}`}</Typography>
          </Box>
        ) : null}
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ mb: 1, color: 'grey' }}>
            {t('pages:sign_up:labels:experience')}
          </Typography>
          <Typography>{state.is_experienced.label}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ mb: 1, color: 'grey' }}>{t('pages:sign_up:labels:course')}</Typography>
          <Typography sx={{ fontWeight: 'bold', color: '#173a5e' }}>
            {state.course_type.label}
          </Typography>
        </Box>
      </Stack>

      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 4 }}>
        <Button type="button" onClick={onClickCancel}>
          {t('pages:sign_up:buttons:back')}
        </Button>
        <Button type="button" color="primary" variant="contained" onClick={onSubmitForm}>
          {t('pages:sign_up:buttons:register')}
        </Button>
      </Box>
    </Container>
  ) : (
    <Navigate to={URL.SIGN_UP_REGISTRATION} />
  );
}
