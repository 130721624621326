import { ExpandLess } from '@mui/icons-material';
import { Box, Stack } from '@mui/system';
import React from 'react';

import { ResultsItemsInnerRankCurrent } from '@/generated/types/typescript-axios/api';

type Props = {
  currentRank: ResultsItemsInnerRankCurrent | undefined;
  isRankUp?: boolean;
};

export default function RankContent({ currentRank, isRankUp = false }: Props) {
  const isNoRank = currentRank === undefined || currentRank.id === null;

  return !isNoRank ? (
    <Stack direction="row" alignItems="center" sx={{ fontSize: 24 }}>
      {isRankUp && <ExpandLess sx={{ color: 'success.light' }} />}
      <Box
        component="span"
        sx={{
          color: isRankUp ? 'success.light' : 'initial',
        }}
      >
        {currentRank.name}
      </Box>
    </Stack>
  ) : (
    <Box sx={{ color: 'grey.400' }}>---</Box>
  );
}
