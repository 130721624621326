import { LoadingButton } from '@mui/lab';
import {
  Box,
  Container,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useToast } from '@/components/ToastProvider';
import { InputClassroom } from '@/generated/types/typescript-axios';
import { classroomSelector, clearStatus, postClassroom } from '@/services/classroomSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { AsyncStatus } from '@/types';

const validationRules = {
  require: {
    required: '必須項目です',
  },
  email: {
    pattern: {
      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      message: '有効なメールアドレスを入力してください',
    },
  },
};

const studentAttributes = [
  { value: '1', label: '学生のみ' },
  { value: '2', label: '一般（高校生以上）' },
  { value: '3', label: '混合' },
  { value: '4', label: 'その他' },
];

export default function ClassroomRegistration() {
  const dispatch = useAppDispatch();
  const { status } = useAppSelector(classroomSelector);
  const { toast } = useToast();

  const loading = useMemo(() => status.post === AsyncStatus.LOADING, [status]);

  useEffect(() => {
    if (status.post === AsyncStatus.SUCCESS) {
      toast({ message: '教室を登録しました' });
      dispatch(clearStatus());
    }
    if (status.post === AsyncStatus.FAILED) {
      toast({ message: '登録に失敗しました', type: 'error' });
      dispatch(clearStatus());
    }
  }, [status.post]);

  const { handleSubmit, control, reset } = useForm<InputClassroom>({
    mode: 'onChange',
    defaultValues: {
      name: '',
      teacher_email: '',
      student_type: 1,
    },
  });

  const handleFormSubmit = async (data: InputClassroom) => {
    dispatch(postClassroom(data));
    reset();
  };

  return (
    <Container maxWidth="sm" sx={{ py: [6, 10] }}>
      <Typography variant="h5" align="center" mb={2}>
        教室新規登録
      </Typography>
      <Box component="form" noValidate onSubmit={handleSubmit(handleFormSubmit)}>
        <Stack spacing={3} sx={{ mb: 6 }}>
          <Controller
            name="name"
            control={control}
            rules={validationRules.require}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                type="text"
                label="教室名"
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="teacher_email"
            control={control}
            rules={{
              ...validationRules.require,
              ...validationRules.email,
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                type="email"
                label="代表先生のメールアドレス"
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="student_type"
            control={control}
            rules={validationRules.require}
            render={({ field, fieldState }) => (
              <>
                <RadioGroup {...field} sx={{ margin: 0 }}>
                  <Typography variant="subtitle1">生徒の属性</Typography>
                  <Box>
                    {studentAttributes.map(({ value, label }) => (
                      <FormControlLabel
                        key={value}
                        value={value}
                        control={<Radio />}
                        label={label}
                      />
                    ))}
                  </Box>
                </RadioGroup>
                {fieldState.error && <p>{fieldState.error.message}</p>}
              </>
            )}
          />
          <LoadingButton loading={loading} type="submit" variant="contained" color="primary">
            登録
          </LoadingButton>
        </Stack>
      </Box>
    </Container>
  );
}
