import { ChevronRight } from '@mui/icons-material';
import { Box, Button, Divider, Grid, Pagination, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Stack } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Loader from '@/components/Loader';
import Chart from '@/components/wholeResult/details/Chart';
import CommentContent from '@/components/wholeResult/details/CommentContent';
import { fontList } from '@/const/result';
import { fetchResults, resultSelector } from '@/services/resultSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { AsyncStatus, FontTypeIDs, URL } from '@/types';

const color = fontList.find((font) => font.fontId === FontTypeIDs.RINSHO)?.color;
const resultsNumPerPage = 5;
type Props = {
  targetYear: number;
  targetMonth: number;
};

const Rinsho = ({ targetYear, targetMonth }: Props) => {
  const { results, status: resultStatus } = useAppSelector(resultSelector);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // Responsive themes
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    dispatch(
      fetchResults({
        year: targetYear,
        month: targetMonth,
        fontId: FontTypeIDs.RINSHO,
        pageId: page,
        rankId: '',
      })
    );
  }, [targetYear, targetMonth, page]);

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    window.scrollTo({ top: 0 });
  };

  const handleClickAccount = (userId?: string) => {
    navigate(`${URL.RESULT}?user_id=${userId}`);
  };

  return (
    <>
      {resultStatus.bulkFetch === AsyncStatus.SUCCESS ? (
        <Box sx={{ pb: 8, pt: [2, 4], px: [1, 0], maxWidth: 680, m: 'auto' }}>
          {results?.items?.length ? (
            <>
              {/* 作品/グラフ or 成績 */}
              <Stack spacing={4} sx={{ mb: 8 }}>
                {results?.items?.map((result, i) => (
                  <Box
                    key={i}
                    sx={{
                      border: isMobile ? 0 : 1,
                      borderColor: 'grey.300',
                      borderRadius: 4,
                      p: [0, 1],
                    }}
                  >
                    {isMobile && i !== 0 && <Divider sx={{ mb: 2, width: '100%' }} />}

                    <Box>
                      {/* ユーザ情報 */}
                      <Button
                        variant="text"
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                          color: 'initial',
                          textTransform: 'initial',
                          mb: 1,
                        }}
                        onClick={() => handleClickAccount(result.user?.id)}
                      >
                        <Stack direction="row" alignItems="center" gap={2}>
                          {/* 書体/段級位 */}
                          <Stack
                            justifyContent="center"
                            sx={{
                              borderWidth: 2,
                              borderColor: `${color}`,
                              borderRadius: '100%',
                              borderStyle: 'solid',
                              color: `${color}`,
                              height: 50,
                              width: 50,
                            }}
                          >
                            <Box sx={{ fontSize: 18, fontWeight: 'bold' }}>
                              {result?.font?.name}
                            </Box>
                          </Stack>
                          {/* ユーザ名 */}
                          <Typography sx={{ fontWeight: 'bold' }}>{result.user?.name}</Typography>
                        </Stack>
                        <ChevronRight sx={{ color: 'grey.700' }} />
                      </Button>

                      {/* データ */}
                      <Grid
                        container
                        sx={{
                          borderColor: 'grey.300',
                          borderRadius: 4,
                          p: [0, 1],
                        }}
                      >
                        {/* 作品 */}
                        <Grid item xs={12} sm={6}>
                          <Chart item={result} />
                        </Grid>
                        {/* コメント */}
                        <Grid item xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                          <CommentContent comments={result.comments} />
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                ))}
              </Stack>
              {(results?.total ?? 0) > resultsNumPerPage ? (
                <Stack alignItems="center">
                  <Pagination
                    count={Math.ceil((results?.total ?? 0) / resultsNumPerPage)}
                    page={page}
                    size="large"
                    onChange={handleChangePage}
                  />
                </Stack>
              ) : null}
            </>
          ) : (
            <Box sx={{ m: 'auto', p: 20, textAlign: 'center' }}>
              該当者なし、もしくは、非公開ユーザーのため表示できる情報がありません
            </Box>
          )}
        </Box>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Rinsho;
