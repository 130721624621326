import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import { GradeId } from '@/types';

export default function useGrade() {
  const { t } = useTranslation();

  const getGradeNameById = (gradeId: number | null | undefined) => {
    switch (gradeId) {
      case 1:
        return t('common:grades:pre_school');
      case 2:
        return t('common:grades:es_1');
      case 3:
        return t('common:grades:es_2');
      case 4:
        return t('common:grades:es_3');
      case 5:
        return t('common:grades:es_4');
      case 6:
        return t('common:grades:es_5');
      case 7:
        return t('common:grades:es_6');
      case 8:
        return t('common:grades:jhs_1');
      case 9:
        return t('common:grades:jhs_2');
      case 10:
        return t('common:grades:jhs_3');
      case null:
        return t('common:grades:adult');
      default:
        return '';
    }
  };

  const getGradeObjectByBirthday = (birthDate: Dayjs) => {
    const fiscalYear = dayjs().subtract(3, 'months').year();
    const fiscalYearBasedDate = dayjs().year(fiscalYear);
    const studentThresholdDate = fiscalYearBasedDate
      .subtract(15, 'year')
      .month(3)
      .date(1)
      .format('YYYY/MM/DD');

    const grade1_start = fiscalYearBasedDate
      .subtract(6, 'year')
      .month(3)
      .date(2)
      .format('YYYY/MM/DD');
    const grade2_start = fiscalYearBasedDate
      .subtract(7, 'year')
      .month(3)
      .date(2)
      .format('YYYY/MM/DD');
    const grade3_start = fiscalYearBasedDate
      .subtract(8, 'year')
      .month(3)
      .date(2)
      .format('YYYY/MM/DD');
    const grade4_start = fiscalYearBasedDate
      .subtract(9, 'year')
      .month(3)
      .date(2)
      .format('YYYY/MM/DD');
    const grade5_start = fiscalYearBasedDate
      .subtract(10, 'year')
      .month(3)
      .date(2)
      .format('YYYY/MM/DD');
    const grade6_start = fiscalYearBasedDate
      .subtract(11, 'year')
      .month(3)
      .date(2)
      .format('YYYY/MM/DD');
    const grade7_start = fiscalYearBasedDate
      .subtract(12, 'year')
      .month(3)
      .date(2)
      .format('YYYY/MM/DD');
    const grade8_start = fiscalYearBasedDate
      .subtract(13, 'year')
      .month(3)
      .date(2)
      .format('YYYY/MM/DD');
    const grade9_start = fiscalYearBasedDate
      .subtract(14, 'year')
      .month(3)
      .date(2)
      .format('YYYY/MM/DD');
    const grade10_start = fiscalYearBasedDate
      .subtract(15, 'year')
      .month(3)
      .date(2)
      .format('YYYY/MM/DD');

    if (!birthDate.isAfter(studentThresholdDate)) {
      return { id: GradeId.ADULT, label: t('common:grades:adult') };
    } else if (birthDate.isBetween(grade2_start, grade1_start) || birthDate.isSame(grade2_start)) {
      return { id: GradeId.ES_FIRST, label: t('common:grades:es_1') };
    } else if (birthDate.isBetween(grade3_start, grade2_start) || birthDate.isSame(grade3_start)) {
      return { id: GradeId.ES_SECOND, label: t('common:grades:es_2') };
    } else if (birthDate.isBetween(grade4_start, grade3_start) || birthDate.isSame(grade4_start)) {
      return { id: GradeId.ES_THIRD, label: t('common:grades:es_3') };
    } else if (birthDate.isBetween(grade5_start, grade4_start) || birthDate.isSame(grade5_start)) {
      return { id: GradeId.ES_FOURTH, label: t('common:grades:es_4') };
    } else if (birthDate.isBetween(grade6_start, grade5_start) || birthDate.isSame(grade6_start)) {
      return { id: GradeId.ES_FIFTH, label: t('common:grades:es_5') };
    } else if (birthDate.isBetween(grade7_start, grade6_start) || birthDate.isSame(grade7_start)) {
      return { id: GradeId.ES_SIXTH, label: t('common:grades:es_6') };
    } else if (birthDate.isBetween(grade8_start, grade7_start) || birthDate.isSame(grade8_start)) {
      return { id: GradeId.JHS_FIRST, label: t('common:grades:jhs_1') };
    } else if (birthDate.isBetween(grade9_start, grade8_start) || birthDate.isSame(grade9_start)) {
      return { id: GradeId.JHS_SECOND, label: t('common:grades:jhs_2') };
    } else if (
      birthDate.isBetween(grade10_start, grade9_start) ||
      birthDate.isSame(grade10_start)
    ) {
      return { id: GradeId.JHS_THIRD, label: t('common:grades:jhs_3') };
    } else {
      return { id: GradeId.PRE_STUDENT, label: t('common:grades:pre_school') };
    }
  };

  return { getGradeObjectByBirthday, getGradeNameById };
}
