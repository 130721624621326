import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CancelIcon from '@mui/icons-material/Cancel';
import { AppBar, Box, IconButton, Modal, Stack, Toolbar, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import { Grade, GradeGroup, GradeList } from '@/const/grade';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: '90%',
    sm: 680,
  },
  bgcolor: 'background.paper',
  border: '1px solid #aaa',
  boxShadow: 24,
  textAlign: 'center',
  borderRadius: 2,
};

type ModalProps = {
  showModal: boolean;
  handleCloseModal: () => void;
  isMobile: boolean;
};

const GradeItem = ({ grade }: { grade: Grade }) => (
  <Box
    sx={{
      borderBottom: '1px solid #546d7529',
      ':hover': { backgroundColor: '#546d751a' },
    }}
  >
    <Link to={'?grade_id=' + grade.id} style={{ textDecoration: 'none' }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ py: 1, px: 2, color: 'initial' }}
      >
        <Box sx={{ fontWeight: 'bold' }}>{grade.value}</Box>
        <Box sx={{ fontSize: 8 }}>
          <ArrowForwardIosIcon fontSize="inherit" />
        </Box>
      </Stack>
    </Link>
  </Box>
);

const GradeGroupBox = ({ group }: { group: GradeGroup }) => (
  <Box
    sx={{
      borderRadius: 1,
      border: '1px solid #546d7529',
    }}
  >
    <Box
      sx={{
        backgroundColor: '#1e88e529',
        color: '#1e88e5',
        fontSize: 16,
        fontWeight: 700,
        textAlign: 'center',
        py: 1,
      }}
    >
      {group.label}
    </Box>
    {group.grades.map((grade, j) => (
      <GradeItem key={j} grade={grade} />
    ))}
  </Box>
);

const GradeSelectionModal = ({ showModal, handleCloseModal, isMobile }: ModalProps) => {
  return (
    <Modal open={showModal} onClose={handleCloseModal}>
      <Box sx={modalStyle}>
        <AppBar
          position="relative"
          color="default"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          }}
        >
          <Toolbar variant="dense">
            <Typography sx={{ fontSize: 16, fontWeight: 'bold', height: '100%' }}>
              学年を選択してください
            </Typography>
            <IconButton
              onClick={handleCloseModal}
              sx={{
                position: 'absolute',
                right: 8,
                backgroundColor: '#f5f5f5',
              }}
            >
              <CancelIcon sx={{ backgroundColor: '#333', color: '#f5f5f5', borderRadius: '50%' }} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box p={3}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr',
              gridTemplateRows: '1fr',
              gap: 3,
            }}
          >
            {GradeList.map((group, i) => (
              <Box
                key={i}
                sx={
                  isMobile
                    ? null
                    : i === 1
                    ? { gridRow: '1/3', gridColumn: '1/2' }
                    : { gridColumn: '2/3' }
                }
              >
                <GradeGroupBox group={group} />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default GradeSelectionModal;
