import { Warning } from '@mui/icons-material';
import { FormControl, FormHelperText, Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import { Control, Controller } from 'react-hook-form';

import { ImageUpload } from '@/components/ImageUpload';
import { VideoUpload } from '@/components/VideoUpload';
import { CreateHandbook } from '@/generated/types/typescript-axios';

type Props = {
  type: 'demonstration' | 'explanation';
  control: Control<CreateHandbook, any>;
  imageFiles: File[];
  imageUrls: string[];
  videoFiles: File[];
  videoUrls: string[];
  setImages: React.Dispatch<React.SetStateAction<File[]>>;
  setVideos: React.Dispatch<React.SetStateAction<File[]>>;
};

const validationRules = {
  comment: {
    maxLength: { value: 1000, message: '1000文字以内で入力してください' },
  },
  theme: {
    maxLength: { value: 1000, message: '1000文字以内で入力してください' },
  },
};

export const RegisterContent = ({
  type,
  control,
  imageFiles,
  imageUrls,
  videoFiles,
  videoUrls,
  setImages,
  setVideos,
}: Props) => {
  const isDemonstration = type === 'demonstration';

  return (
    <Grid container spacing={1} justifyContent="space-between">
      <Grid item xs={6} md={6} sx={{ mb: 5 }}>
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: 'bold',
            color: '#333',
          }}
        >
          {`${isDemonstration ? '手本' : '解説'}の登録・更新`}
        </Typography>
      </Grid>
      <Grid item xs={6} md={6} sx={{ textAlign: 'right', mb: 5 }}>
        {/* <Button
          variant="contained"
          sx={{
            backgroundColor: red[300],
            '&:hover': {
              background: red[200],
            },
          }}
        >
          内容のリセット
        </Button> */}
      </Grid>
      {isDemonstration ? (
        <Grid item xs={12}>
          <Controller
            name="theme"
            control={control}
            rules={validationRules.comment}
            render={({ field, fieldState }) => (
              <FormControl error={!!fieldState.error?.message} sx={{ width: '100%' }}>
                <TextField
                  {...field}
                  error={fieldState.error ? true : false}
                  label="お題の文字"
                  variant="outlined"
                  fullWidth
                />
                <FormHelperText sx={{ position: 'absolute', top: '100%', m: 0.5 }}>
                  {fieldState.error?.message}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <Typography
          sx={{
            mb: 1,
            color: 'red',
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            border: '1px solid red',
            fontSize: 12,
            p: 1,
            borderRadius: 1,
          }}
        >
          <Warning fontSize={'small'} />
          内容を更新する際には、画像、動画に変更がなくとも、登録する画像、動画を手本/解説ともに再度選択、アップロードしてください。
          (再選択がないと画像・動画が削除されてしまいます。)
        </Typography>
      </Grid>
      <Grid item md={6} xs={12} sx={{ mb: 2 }}>
        <Typography sx={{ mb: 1 }}>画像</Typography>
        <ImageUpload images={imageFiles} imageUrls={imageUrls} setImages={setImages} />
      </Grid>
      <Grid item md={6} xs={12} sx={{ mb: 2 }}>
        <Typography sx={{ mb: 1 }}>動画</Typography>
        <VideoUpload videos={videoFiles} videoUrls={videoUrls} setVideos={setVideos} />
      </Grid>
      {isDemonstration ? (
        <Grid item xs={12} sx={{ mb: 5 }}>
          <Controller
            name="explanation.comment"
            control={control}
            rules={validationRules.comment}
            render={({ field, fieldState }) => (
              <FormControl error={!!fieldState.error?.message} sx={{ width: '100%' }}>
                <TextField
                  {...field}
                  error={fieldState.error ? true : false}
                  label="説明・詳細 (1000文字以内)"
                  variant="outlined"
                  fullWidth
                  rows={4}
                  multiline
                />
                <FormHelperText sx={{ position: 'absolute', top: '100%', m: 0.5 }}>
                  {fieldState.error?.message}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default RegisterContent;
