import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';

import assignmentReducer from '@/services/assignmentSlice';
import authReducer from '@/services/authSlice';
import classroomReducer from '@/services/classroomSlice';
import commentTemplateReducer from '@/services/commentTemplateSlice';
import dateReducer from '@/services/dateSlice';
import fontReducer from '@/services/fontSlice';
import handbookAvailTermReducer from '@/services/handbookAvailTermSlice';
import handbookReducer from '@/services/handbookSlice';
import paymentMethodReducer from '@/services/paymentMethodSlice';
import rankChangesReducer from '@/services/rankChangesSlice';
import resultReducer from '@/services/resultSlice';
import subscriptionReducer from '@/services/subscriptionSlice';
import timelineReducer from '@/services/timelineSlice';
import userReducer from '@/services/userSlice';
import viewpointReducer from '@/services/viewpointSlice';

const logger = createLogger({
  collapsed: true,
  diff: true,
});

export const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    paymentMethod: paymentMethodReducer,
    handbook: handbookReducer,
    assignment: assignmentReducer,
    font: fontReducer,
    handbookAvailTerm: handbookAvailTermReducer,
    viewpoint: viewpointReducer,
    commentTemplate: commentTemplateReducer,
    result: resultReducer,
    rankChanges: rankChangesReducer,
    timeline: timelineReducer,
    subscription: subscriptionReducer,
    classroom: classroomReducer,
    date: dateReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(logger),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
