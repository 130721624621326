import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { MouseEvent, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import DeadlineAlert from '@/components/DeadlineAlert';
import TrialStatus from '@/components/trialStatus';
import { subscriptionStatus } from '@/const/account';
import { Pages } from '@/const/url';
import { authSelector } from '@/services/authSlice';
import { dateSelector, fetchDate } from '@/services/dateSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

import Footer from './Footer';
import Header from './Header';
import SideMenu from './SideMenu';

const LOGIN_URL = process.env.REACT_APP_SAASUS_URL ?? '';

const Layout = () => {
  const { date } = useAppSelector(dateSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const { account } = useAppSelector(authSelector);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleAccountMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuOpen = () => {
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  const handleMenuItemClick = (key: string, path: string) => {
    handleMenuClose();
    if (key === 'logout') {
      localStorage.clear();
      window.location.href = LOGIN_URL;
    } else {
      navigate(path);
    }
  };

  useEffect(() => {
    dispatch(fetchDate());
  }, [dispatch]);

  const dayOfCurrentMonth = date ? dayjs(date).date() : dayjs().date();
  const isDeadLineAlert =
    Pages[path]?.deadlineAlert && dayOfCurrentMonth >= 3 && dayOfCurrentMonth <= 10;

  return (
    <>
      <Box sx={{ minHeight: 'calc(100vh - 30px)' }}>
        {/* Header navigation */}
        <Header
          nickname={account?.nickname ?? ''}
          menuEl={anchorEl}
          handleAccountMenuOpen={handleAccountMenuOpen}
          handleMenuOpen={handleMenuOpen}
          handleMenuClose={handleMenuClose}
          handleMenuItemClick={handleMenuItemClick}
        />

        {/* Drawer menu */}
        <SideMenu
          accountRoles={account.roles ?? []}
          open={isMenuOpen}
          handleMenuItemClick={handleMenuItemClick}
          onClose={handleMenuClose}
        />

        {/* Main contents */}
        <Box
          component="main"
          sx={{
            paddingTop: {
              xs: 7,
              sm: 8,
            },
          }}
        >
          {isDeadLineAlert ? (
            <Box sx={{ p: [1, 4] }}>
              <DeadlineAlert />
            </Box>
          ) : null}
          {Pages[path]?.title ? (
            <Typography
              variant="h2"
              align="center"
              sx={{ mt: 6, fontSize: 20, fontWeight: 'bold' }}
            >
              {Pages[path].title}
            </Typography>
          ) : null}
          {[
            subscriptionStatus.IN_TRIAL,
            subscriptionStatus.TRIAL_EXPIRED,
            subscriptionStatus.UNPAID,
          ].includes(account.subscription_status as string) && Pages[path]?.isDisplayTrial ? (
            <TrialStatus />
          ) : null}
          <Outlet />
        </Box>
      </Box>

      {/* Footer */}
      <Footer />
    </>
  );
};

export default Layout;
