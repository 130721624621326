import { Logout, Person } from '@mui/icons-material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { MouseEvent, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Nullable, URL } from '@/types';

type Props = {
  nickname: string;
  menuEl: Nullable<HTMLElement>;
  handleAccountMenuOpen: (event: MouseEvent<HTMLElement>) => void;
  handleMenuOpen: () => void;
  handleMenuClose: () => void;
  handleMenuItemClick: (key: string, path: string) => void;
};

type AccountMenuItem = {
  key: string;
  text: string;
  icon: ReactNode;
  path: string;
};

const accountMenuItems: AccountMenuItem[] = [
  { key: 'myPage', text: 'マイページ', icon: <Person />, path: URL.MY_PAGE },
  { key: 'logout', text: 'ログアウト', icon: <Logout />, path: '' },
];

export default function Header({
  nickname,
  menuEl,
  handleAccountMenuOpen,
  handleMenuOpen,
  handleMenuClose,
  handleMenuItemClick,
}: Props) {
  return (
    <AppBar position="fixed" color="default">
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={handleMenuOpen}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <Box sx={{ display: 'flex' }}>
            <Link to={'/'}>
              <img src="/logo.png" alt="競書才幹" height={40} style={{ verticalAlign: 'bottom' }} />
            </Link>
          </Box>
        </Typography>
        <Button
          color="inherit"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mr: 1,
            minWidth: 'auto',
          }}
          href={URL.HELP_TOP}
          target="_blank"
        >
          <HelpOutlineIcon />
          <Box sx={{ fontSize: 10 }}>ヘルプ</Box>
        </Button>
        <Button
          sx={{
            py: 1.846,
            px: 1,
          }}
          size="large"
          aria-label="account of current user"
          aria-controls="account-icon"
          aria-haspopup="true"
          onClick={handleAccountMenuOpen}
          color="inherit"
        >
          <Box
            sx={{
              display: { xs: 'none', sm: 'block' },
              textTransform: 'none',
              fontSize: 12,
              pr: 1,
            }}
          >
            {nickname}
          </Box>
          <AccountCircle />
        </Button>
        <Menu
          id="account-icon"
          anchorEl={menuEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={!!menuEl}
          onClose={handleMenuClose}
        >
          {accountMenuItems.map(({ key, text, icon, path }) => (
            <MenuItem key={key} onClick={() => handleMenuItemClick(key, path)} sx={{ px: 3 }}>
              {icon}
              <Typography sx={{ ml: 1 }}>{text}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
