import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import {
  CommentTemplate,
  CommentTemplateApi,
  CommentTemplateCategory,
} from '@/generated/types/typescript-axios';
import { RootState } from '@/store';
import { AsyncStatus } from '@/types';
import { ApiConfig } from '@/utils/apiConfig';

export type TemplateComment = {
  category: CommentTemplateCategory;
  items: CommentTemplate[];
};

type CommentTemplateState = {
  commentTemplates: CommentTemplate[];
  commentTemplateCategories: CommentTemplateCategory[];
  commentTemplateComments: TemplateComment[];
  status: { bulkFetch: AsyncStatus };
};

const initialState: CommentTemplateState = {
  commentTemplates: [],
  commentTemplateCategories: [],
  commentTemplateComments: [],
  status: {
    bulkFetch: AsyncStatus.IDLE,
  },
};

export const fetchCommentTemplates = createAsyncThunk(
  'fetchCommentTemplates',
  async (courseType: number, thunkAPI) => {
    try {
      const apiConfig = ApiConfig();
      const commentTemplateApi = new CommentTemplateApi(apiConfig);
      const { data } = await commentTemplateApi.getCommentTemplates(courseType);
      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const commentTemplateSlice = createSlice({
  name: 'commentTemplate',
  initialState,
  reducers: {
    clearStatus: (state: CommentTemplateState) => {
      state.status.bulkFetch = AsyncStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCommentTemplates.pending, (state) => {
        state.status.bulkFetch = AsyncStatus.LOADING;
      })
      .addCase(
        fetchCommentTemplates.fulfilled,
        (state, { payload }: { payload: CommentTemplate[] }) => {
          const templateCategories = _.uniqBy(
            payload.map(({ category }) => category as CommentTemplateCategory),
            'id'
          );
          // Normalize categories
          state.commentTemplateCategories = templateCategories;
          // Normalize comments
          state.commentTemplateComments = templateCategories.map((category) => ({
            category,
            items: payload.filter((item) => item?.category?.id === category.id),
          }));

          state.commentTemplates = payload;
          state.status.bulkFetch = AsyncStatus.SUCCESS;
        }
      )
      .addCase(fetchCommentTemplates.rejected, (state) => {
        state.status.bulkFetch = AsyncStatus.FAILED;
      });
  },
});

export const { clearStatus } = commentTemplateSlice.actions;

export const commentTemplateSelector = (state: RootState) => state.commentTemplate;

export default commentTemplateSlice.reducer;
