import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Viewpoint, ViewpointApi } from '@/generated/types/typescript-axios';
import { RootState } from '@/store';
import { AsyncStatus } from '@/types';
import { ApiConfig } from '@/utils/apiConfig';

type ViewpointState = {
  viewpoints: Viewpoint[];
  status: { bulkFetch: AsyncStatus };
};

const initialState: ViewpointState = {
  viewpoints: [],
  status: {
    bulkFetch: AsyncStatus.IDLE,
  },
};

export const fetchViewpoints = createAsyncThunk(
  'fetchViewpoints',
  async (fontId: number, thunkAPI) => {
    try {
      const apiConfig = ApiConfig();
      const viewpointApi = new ViewpointApi(apiConfig);
      const { data } = await viewpointApi.getViewpoints(fontId);
      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const viewpointSlice = createSlice({
  name: 'viewpoint',
  initialState,
  reducers: {
    clearStatus: (state: ViewpointState) => {
      state.status.bulkFetch = AsyncStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchViewpoints.pending, (state) => {
        state.status.bulkFetch = AsyncStatus.LOADING;
      })
      .addCase(fetchViewpoints.fulfilled, (state, { payload }: { payload: Viewpoint[] }) => {
        state.viewpoints = payload;
        state.status.bulkFetch = AsyncStatus.SUCCESS;
      })
      .addCase(fetchViewpoints.rejected, (state) => {
        state.status.bulkFetch = AsyncStatus.FAILED;
      });
  },
});

export const { clearStatus } = viewpointSlice.actions;

export const viewpointSelector = (state: RootState) => state.viewpoint;

export default viewpointSlice.reducer;
