import { Box, Button, Chip, Container, Stack, Tab, Tabs } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Loader from '@/components/Loader';
import BarChart from '@/components/wholeResult/BarChart';
import Rinsho from '@/components/wholeResult/Rinsho';
import TableArea from '@/components/wholeResult/TableArea';
import useRanks from '@/hooks/useRanks';
import useTermStatus from '@/hooks/useTermStatus';
import { authSelector } from '@/services/authSlice';
import { fetchFonts, fontSelector } from '@/services/fontSlice';
import { fetchResultsAmount, resultSelector } from '@/services/resultSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { AsyncStatus, CourseId, FontTypeIDs, Nullable, URL } from '@/types';
import isStudentAtDate from '@/utils/isStudentAtDate';

const WholeResult = () => {
  const { t } = useTranslation();
  const { fonts, status: fontStatus } = useAppSelector(fontSelector);
  const { amount, status: resultStatus } = useAppSelector(resultSelector);
  const { account } = useAppSelector(authSelector);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { lastReleaseRankDate } = useTermStatus();
  const { adultRankNames, studentRankNames } = useRanks();
  const [targetYear, setTargetYear] = useState<number>(lastReleaseRankDate.year());
  const [targetMonth, setTargetMonth] = useState<number>(lastReleaseRankDate.month() + 1);
  const [activeTab, setActiveTab] = useState(isStudentAtDate(account.birthday) ? 1 : 0);
  const [targetFontId, setTargetFontId] = useState<number | undefined>(
    isStudentAtDate(account.birthday) ? 10 : 0
  );

  // TODO マジックナンバーを潰す
  const tableData = {
    fontName: fonts.find((font) => font.id === targetFontId)?.name ?? '総合',
    groups: [
      {
        name: activeTab === 0 ? '師範・準師範' : '特待生',
        sum: amount?.amount?.shihan ?? 0,
        ranks:
          amount?.rank?.slice(0, activeTab === 0 ? 2 : 1).map((rank, i) => ({
            rankName: activeTab === 0 ? adultRankNames[i] : studentRankNames[i],
            count: rank.amount ?? 0,
            path: `${URL.WHOLE_RESULT}/${targetYear}/${targetMonth}/rank/${rank.id}/${
              targetFontId === 0 ? 'overall' : `font/${targetFontId}`
            }`,
          })) ?? [],
      },
      {
        name: '段',
        sum: amount?.amount?.dan ?? 0,
        ranks:
          amount?.rank
            ?.slice(activeTab === 0 ? 2 : 1, activeTab === 0 ? 11 : 10)
            .map((rank, i) => ({
              rankName: activeTab === 0 ? adultRankNames[i + 2] : studentRankNames[i + 1],
              count: rank.amount ?? 0,
              path: `${URL.WHOLE_RESULT}/${targetYear}/${targetMonth}/rank/${rank.id}/${
                targetFontId === 0 ? 'overall' : `font/${targetFontId}`
              }`,
            })) ?? [],
      },
      {
        name: '級',
        sum: amount?.amount?.kyu ?? 0,
        ranks:
          amount?.rank
            ?.slice(activeTab === 0 ? 11 : 10, activeTab === 0 ? 21 : 20)
            .map((rank, i) => ({
              rankName: activeTab === 0 ? adultRankNames[i + 11] : studentRankNames[i + 10],
              count: rank.amount ?? 0,
              path: `${URL.WHOLE_RESULT}/${targetYear}/${targetMonth}/rank/${rank.id}/${
                targetFontId === 0 ? 'overall' : `font/${targetFontId}`
              }`,
            })) ?? [],
      },
    ],
  };

  // タブ切り替え時に表示される書体を切り替え
  useEffect(() => {
    if (activeTab === 0) {
      // 一般タブ選択時
      dispatch(fetchFonts(CourseId.ADULT));
    } else {
      // 学生タブ選択時
      dispatch(fetchFonts(CourseId.STUDENT));
    }
  }, [activeTab]);

  useEffect(() => {
    if (targetFontId != FontTypeIDs.RINSHO) {
      dispatch(
        fetchResultsAmount({
          year: targetYear,
          month: targetMonth,
          fontId: targetFontId === 0 ? undefined : targetFontId,
        })
      );
    }
  }, [targetYear, targetMonth, targetFontId]);

  // 表示される書体が切り替わったら、書体の選択状態を初期化
  useEffect(() => {
    if (fontStatus.fetch === AsyncStatus.SUCCESS) {
      if (activeTab === 0) {
        setTargetFontId(0);
      } else {
        setTargetFontId(fonts[0].id);
      }
    }
  }, [fontStatus.fetch]);

  const handleChangeYearMonth = (date: Nullable<Dayjs>) => {
    const year = Number(date?.format('YYYY'));
    const month = Number(date?.format('M'));

    if (!!year && !!month) {
      setTargetYear(year);
      setTargetMonth(month);
    }
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleClickFont = (fontId: number | undefined) => {
    setTargetFontId(fontId);
  };

  const onClickNavigation = () => {
    navigate(`${URL.WHOLE_RESULT}/high-scores/${targetYear}/${targetMonth}/font/${targetFontId}`);
  };

  return (
    <Container sx={{ p: [1, 4] }} maxWidth="md">
      {/* 年月選択 */}
      <Box sx={{ mb: 4 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
          <DatePicker
            views={['year', 'month']}
            value={dayjs(`${targetYear}-${targetMonth}`)}
            label="年月を選択"
            format="YYYY/MM"
            onChange={handleChangeYearMonth}
          />
        </LocalizationProvider>
      </Box>

      {/* 一般/学生タブ */}
      <Tabs
        value={activeTab}
        onChange={handleChangeTab}
        sx={{ borderBottom: 1, borderColor: 'divider', pt: 1 }}
      >
        <Tab label="一般" />
        <Tab label="学生" />
      </Tabs>

      {fontStatus.fetch === AsyncStatus.SUCCESS &&
      resultStatus.fetchAmount === AsyncStatus.SUCCESS ? (
        <Box sx={{ mt: 1 }}>
          {/* 書体選択ボタン */}
          <Stack direction="row" spacing={1} sx={{ overflow: 'auto', mb: 4, py: 1 }}>
            {/* 総合 */}
            {activeTab === 0 ? (
              <Chip
                color={targetFontId === 0 ? 'primary' : 'default'}
                label="総合"
                variant="outlined"
                onClick={() => handleClickFont(0)}
                sx={{ px: 1 }}
              />
            ) : undefined}
            {/* 各書体 */}
            {fonts.map(({ id, name }) => (
              <Chip
                key={id}
                color={targetFontId === id ? 'primary' : 'default'}
                label={name}
                variant="outlined"
                onClick={() => handleClickFont(id)}
                sx={{ px: 1 }}
              />
            ))}
          </Stack>
          {targetFontId != FontTypeIDs.RINSHO ? (
            <>
              {/* グラフのコンポーネント */}
              <Box sx={{ mb: 4, height: { xs: 480, md: 320 } }}>
                <BarChart
                  courseType={activeTab === 0 ? 'adult' : 'student'}
                  ranks={amount?.rank ?? []}
                  userRankOrder={amount?.user_rank_order ?? undefined}
                />
              </Box>
              {targetFontId !== 0 && (
                <Box sx={{ mb: 4 }}>
                  <Button
                    variant="outlined"
                    onClick={onClickNavigation}
                    sx={{ width: '100%', py: 1, fontSize: 16 }}
                  >
                    {t('pages:whole_result.showHighScoresResult')}
                  </Button>
                </Box>
              )}
              {/* 人数表示のコンポーネント */}
              <TableArea data={tableData} />
            </>
          ) : (
            <Rinsho targetYear={targetYear} targetMonth={targetMonth} />
          )}
        </Box>
      ) : (
        <Loader />
      )}
    </Container>
  );
};

export default WholeResult;
