import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import dayjs from 'dayjs';
import * as React from 'react';

export default function DeadlineAlert() {
  return (
    <Alert severity="warning">
      <AlertTitle>提出期限：{`${dayjs().month() + 1}月10日 23:59`}</AlertTitle>
      期限を過ぎると受付できません
    </Alert>
  );
}
