import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import { RegisterForm, User } from '@/types';

export default function useAccount() {
  const { t } = useTranslation();

  // 初期値の設定
  const defaultValues: RegisterForm = {
    email: '',
    nickname: '',
    last_name: '',
    first_name: '',
    gender_id: '',
    prefecture: '',
    birthday: null,
    parent_last_name: '',
    parent_first_name: '',
    is_experienced: '',
    course_type: null,
    id: undefined,
  };

  const defaultUserValues: User = {
    roles: [
      {
        role_name: 'student',
        display_name: t('pages:sign_up:labels:saikan_student'),
      },
    ],
    id: '',
    last_name: '',
    first_name: '',
    nickname: '',
    course_type: 0,
    email: '',
    gender_id: 0,
    birthday: '',
    prefecture: '',
    is_experienced: false,
    parent_last_name: '',
    parent_first_name: '',
    completed_sign_up: true,
  };

  // 性別
  const genderList = [
    { value: '1', label: t('pages:sign_up:labels:female') },
    { value: '2', label: t('pages:sign_up:labels:male') },
    { value: '3', label: t('pages:sign_up:labels:no_answer') },
  ];

  // 都道府県リスト
  const prefectureList = [
    { value: 1, label: t('common:prefectures:hokkaido') },
    { value: 2, label: t('common:prefectures:aomori') },
    { value: 3, label: t('common:prefectures:iwate') },
    { value: 4, label: t('common:prefectures:miyagi') },
    { value: 5, label: t('common:prefectures:akita') },
    { value: 6, label: t('common:prefectures:yamagata') },
    { value: 7, label: t('common:prefectures:fukushima') },
    { value: 8, label: t('common:prefectures:ibaraki') },
    { value: 9, label: t('common:prefectures:tochigi') },
    { value: 10, label: t('common:prefectures:gunma') },
    { value: 11, label: t('common:prefectures:saitama') },
    { value: 12, label: t('common:prefectures:chiba') },
    { value: 13, label: t('common:prefectures:tokyo') },
    { value: 14, label: t('common:prefectures:kanagawa') },
    { value: 15, label: t('common:prefectures:niigata') },
    { value: 16, label: t('common:prefectures:toyama') },
    { value: 17, label: t('common:prefectures:ishikawa') },
    { value: 18, label: t('common:prefectures:fukui') },
    { value: 19, label: t('common:prefectures:yamanashi') },
    { value: 20, label: t('common:prefectures:nagano') },
    { value: 21, label: t('common:prefectures:gifu') },
    { value: 22, label: t('common:prefectures:shizuoka') },
    { value: 23, label: t('common:prefectures:aichi') },
    { value: 24, label: t('common:prefectures:mie') },
    { value: 25, label: t('common:prefectures:shiga') },
    { value: 26, label: t('common:prefectures:kyoto') },
    { value: 27, label: t('common:prefectures:osaka') },
    { value: 28, label: t('common:prefectures:hyogo') },
    { value: 29, label: t('common:prefectures:nara') },
    { value: 30, label: t('common:prefectures:wakayama') },
    { value: 31, label: t('common:prefectures:tottori') },
    { value: 32, label: t('common:prefectures:shimane') },
    { value: 33, label: t('common:prefectures:okayama') },
    { value: 34, label: t('common:prefectures:hiroshima') },
    { value: 35, label: t('common:prefectures:yamaguchi') },
    { value: 36, label: t('common:prefectures:tokushima') },
    { value: 37, label: t('common:prefectures:kagawa') },
    { value: 38, label: t('common:prefectures:ehime') },
    { value: 39, label: t('common:prefectures:kochi') },
    { value: 40, label: t('common:prefectures:fukuoka') },
    { value: 41, label: t('common:prefectures:saga') },
    { value: 42, label: t('common:prefectures:nagasaki') },
    { value: 43, label: t('common:prefectures:kumamoto') },
    { value: 44, label: t('common:prefectures:oita') },
    { value: 45, label: t('common:prefectures:miyazaki') },
    { value: 46, label: t('common:prefectures:kagoshima') },
    { value: 47, label: t('common:prefectures:okinawa') },
  ];

  // 書道経験
  const experienceList = [
    { id: 0, value: 'true', label: t('pages:sign_up:labels:experience_yes') },
    { id: 1, value: 'false', label: t('pages:sign_up:labels:experience_no') },
  ];

  // バリデーションルールの定義
  const validationRules = {
    nickname: {
      required: t('pages:sign_up:messages:nickname_required'),
      maxLength: { value: 30, message: t('pages:sign_up:messages:nickname_max_length') },
    },
    last_name: {
      required: t('pages:sign_up:messages:last_name_required'),
      maxLength: { value: 20, message: t('pages:sign_up:messages:name_max_length') },
    },
    first_name: {
      required: t('pages:sign_up:messages:first_name_required'),
      maxLength: { value: 20, message: t('pages:sign_up:messages:name_max_length') },
    },
    gender_id: {
      validate: (val: string) => {
        if (val === '') {
          return t('pages:sign_up:messages:gender_required');
        }
        return true;
      },
    },
    prefecture: {
      required: t('pages:sign_up:messages:prefecture_required'),
    },
    birthday: {
      validate: (val: Dayjs | null) => {
        if (val === null) {
          return t('pages:sign_up:messages:birthday_required');
        }
        if (!val.format('YYYYMMDD').match(/^\d{8}$/g)) {
          return t('pages:sign_up:messages:birthday_format');
        }
        return true;
      },
    },
    parent_last_name: {
      required: t('pages:sign_up:messages:last_name_required'),
      maxLength: { value: 20, message: t('pages:sign_up:messages:name_max_length') },
    },
    parent_first_name: {
      required: t('pages:sign_up:messages:first_name_required'),
      maxLength: { value: 20, message: t('pages:sign_up:messages:name_max_length') },
    },
    is_experienced: {
      required: t('pages:sign_up:messages:experience_required'),
    },
  };

  return {
    defaultValues,
    defaultUserValues,
    experienceList,
    genderList,
    prefectureList,
    validationRules,
  };
}
