import CircleIcon from '@mui/icons-material/Circle';
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Select,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { esStudentCases, jhsStudentCases, preStudentCases } from '@/const';
import useGrade from '@/hooks/useGrade';
import useIsAvailTerm from '@/hooks/useTermStatus';
import { authSelector } from '@/services/authSlice';
import {
  fetchHandbooksAvailTerms,
  handbookAvailTermsSelector,
} from '@/services/handbookAvailTermSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

const courseList = [
  { key: 'pre', label: '幼年', cases: preStudentCases },
  { key: 'el', label: '小学生', cases: esStudentCases },
  { key: 'jhs', label: '中学生', cases: jhsStudentCases },
];
const adultCourseType = 1;
const adultId = 0;

export default function MonthsList() {
  const navigate = useNavigate();
  const { handbookAvailTerms } = useAppSelector(handbookAvailTermsSelector);
  const dispatch = useAppDispatch();
  const { account } = useAppSelector(authSelector);
  const { getGradeObjectByBirthday } = useGrade();
  const { termList } = useIsAvailTerm();
  const courseType = account?.course_type ?? 0;
  const courseInfo = getGradeObjectByBirthday(dayjs(account?.birthday ?? ''));
  const [selectValue, setSelectValue] = useState<number>(courseInfo.id);

  useEffect(() => {
    dispatch(fetchHandbooksAvailTerms());
  }, [dispatch]);

  const onClickNavigation = (
    year: number | undefined,
    month: number | undefined,
    gradeId?: number | undefined
  ) => {
    if (selectValue === adultId) {
      navigate(`/handbooks/${year}/${month}/adult`);
    } else {
      navigate(`/handbooks/${year}/${month}/grade/${gradeId}`);
    }
  };

  const handleChange = (e: any) => {
    setSelectValue(e.target.value);
  };

  return (
    <Box sx={{ p: [1, 4], bgcolor: 'background.paper' }}>
      <Box sx={{ maxWidth: 360 }}>
        <FormControl sx={{ m: 2 }}>
          <InputLabel htmlFor="grouped-native-select">一般 / 学生</InputLabel>
          <Select
            onChange={handleChange}
            native
            id="grouped-native-select"
            label="Grouping"
            sx={{ minWidth: '220px' }}
            value={selectValue}
          >
            {courseType === adultCourseType && (
              <optgroup label="一般">
                <option value={adultId}>一般</option>
              </optgroup>
            )}
            {courseList.map(({ key, label, cases }) => (
              <optgroup key={key} label={label}>
                {cases.map(({ grade_id, value }) => (
                  <option key={grade_id} value={grade_id}>
                    {value}
                  </option>
                ))}
              </optgroup>
            ))}
          </Select>
        </FormControl>
        <List>
          {handbookAvailTerms.map((handbookAvailTerm, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton
                onClick={() =>
                  onClickNavigation(handbookAvailTerm.year, handbookAvailTerm.month, selectValue)
                }
              >
                <CircleIcon fontSize="small" sx={{ color: grey[600], mr: 1 }} />
                <ListItemText
                  primary={`${handbookAvailTerm.year}年${handbookAvailTerm.month}月 競書課題`}
                />
                <Chip
                  label={
                    termList.filter((term) => term.id === handbookAvailTerm.term_status)[0].name ??
                    ''
                  }
                  variant="outlined"
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
}
