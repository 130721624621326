import { ExpandLess } from '@mui/icons-material';
import { Box, Stack } from '@mui/system';
import React from 'react';

type Props = {
  rank: any | undefined;
};

export default function RankContent({ rank }: Props) {
  const isRankUp = rank?.is_rank_up;
  const isNoRank = rank === undefined || rank.current.id === null;
  return !isNoRank ? (
    <Stack direction="row" alignItems="center" sx={{ fontSize: 24 }}>
      {isRankUp && <ExpandLess sx={{ color: 'success.light' }} />}
      <Box
        component="span"
        sx={{
          color: isRankUp ? 'success.light' : 'warning.light',
        }}
      >
        {rank?.current.name}
      </Box>
    </Stack>
  ) : (
    <Box sx={{ color: 'grey.400' }}>---</Box>
  );
}
