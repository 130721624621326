import { Box, Button, Stack } from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useCallback, useState } from 'react';

import { subscriptionStatus } from '@/const/account';
import { authSelector } from '@/services/authSlice';
import { useAppSelector } from '@/store/hooks';

import { TrialDetail } from './TrialDetail';
const API_ENDPOINT_URL = process.env.REACT_APP_API_ENDPOINT ?? '';

// ボタンを押したときの表示を切り替える関数
const TrialStatus = () => {
  const { account } = useAppSelector(authSelector);
  const [showTrialDetail, setShowTrialDetail] = useState(false);

  const openTrialDetails = useCallback(() => {
    setShowTrialDetail(true);
  }, []);

  const closeTrialDetails = useCallback(() => {
    setShowTrialDetail(false);
  }, []);

  const startRegistration = useCallback(() => {
    const queryParameter = `?prefilled_email=${account.email}&client_reference_id=${account.id}`;
    const url =
      account.course_type === 1
        ? process.env.REACT_APP_STRIPE_PAYMENT_LINK_URL
        : process.env.REACT_APP_STRIPE_PAYMENT_LINK_URL_STUDENT;
    window.open(url + queryParameter, '_blank');
  }, []);

  const trialEndDate = account?.trial_end_date
    ? dayjs(account.trial_end_date).format('YYYY/MM/DD')
    : '';
  const trialStatus = account?.subscription_status ?? '';

  const confirmPaymentMethod = async () => {
    const response = await axios.get(`${API_ENDPOINT_URL}/subscriptions/payment`, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: `Bearer ${localStorage.getItem('SaaSusIdToken')}`,
      },
      withCredentials: true,
    });
    const data = response.data;
    const redirectURL = data.url;

    window.location.href = redirectURL;
  };

  return (
    <Box sx={{ maxWidth: 800, mb: 2, mt: 4, mx: 'auto', px: [2, 2, 0] }}>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={[4, 6]} sx={{ fontSize: 14 }}>
        {/* トライアル */}
        <Stack
          sx={{
            borderRadius: '16px',
            boxShadow: 2,
            bgcolor: '#f7fafa',
            flex: 1,
            position: 'relative',
          }}
        >
          {/* トライアルタイトル */}
          {/* トライアル期間を過ぎたら別の内容を表示 */}
          {trialStatus === subscriptionStatus.TRIAL_EXPIRED ? (
            <>
              <Box
                sx={{
                  bgcolor: '#ef6c0029',
                  borderTopRightRadius: '16px',
                  borderTopLeftRadius: '16px',
                  fontSize: 18,
                  fontWeight: 'bold',
                  py: 3,
                  textAlign: 'center',
                }}
              >
                トライアルは終了しました
              </Box>
              <Box sx={{ fontSize: 14, textAlign: { xs: 'left', md: 'center' }, px: 2, py: 4 }}>
                <Box>トライアルのご利用ありがとうございました。</Box>
                <Box>引き続きご利用される場合は、本入会の手続きにお進みください。</Box>
              </Box>
              <Button
                variant="contained"
                sx={{
                  mx: 2,
                  mb: 2,
                  height: 41,
                  borderRadius: 1,
                  bgcolor: 'primary.main',
                  color: 'white',
                  boxShadow: 0,
                  '&:hover': {
                    bgcolor: 'primary.dark',
                  },
                }}
                onClick={startRegistration}
              >
                本入会に進む
              </Button>
            </>
          ) : trialStatus === subscriptionStatus.IN_TRIAL ? (
            <>
              <Box
                sx={{
                  bgcolor: '#00a76b29',
                  borderTopRightRadius: '16px',
                  borderTopLeftRadius: '16px',
                  fontSize: 18,
                  fontWeight: 'bold',
                  py: [2, 3],
                  textAlign: 'center',
                }}
              >
                現在トライアル中
              </Box>
              <Stack spacing={[2, 4]} sx={{ p: [2, 4] }}>
                <Box sx={{ fontSize: 18, fontWeight: 'bold', textAlign: 'center' }}>
                  トライアル期間: {trialEndDate}まで
                </Box>
                <Button
                  variant="contained"
                  sx={{
                    mx: 2,
                    mb: 2,
                    height: 41,
                    borderRadius: 1,
                    bgcolor: 'primary.main',
                    color: 'white',
                    boxShadow: 0,
                    '&:hover': {
                      bgcolor: 'primary.dark',
                    },
                  }}
                  onClick={startRegistration}
                >
                  本入会に進む
                </Button>
                {showTrialDetail ? (
                  // トライアル詳細説明
                  <TrialDetail onClose={closeTrialDetails} />
                ) : (
                  <Button
                    variant="contained"
                    sx={{
                      mx: 2,
                      mb: 2,
                      height: 41,
                      borderRadius: 1,
                      bgcolor: '#f7fafa',
                      color: '#1976d2',
                      border: '1px solid #1976d2',
                      boxShadow: 0,
                      '&:hover': {
                        bgcolor: '#f7fafa',
                        color: '#1976d2',
                        border: '1px solid #1976d2',
                      },
                    }}
                    onClick={openTrialDetails}
                  >
                    トライアルの説明を見る
                  </Button>
                )}
              </Stack>
            </>
          ) : trialStatus === subscriptionStatus.UNPAID ? (
            <>
              <Box
                sx={{
                  bgcolor: '#d81b6052',
                  borderTopRightRadius: '16px',
                  borderTopLeftRadius: '16px',
                  fontSize: 18,
                  fontWeight: 'bold',
                  py: 3,
                  textAlign: 'center',
                }}
              >
                お支払いの確認ができておりません
              </Box>
              <Box sx={{ fontSize: 14, textAlign: { xs: 'left', md: 'center' }, px: 2, py: 4 }}>
                <Box>
                  お客様が設定した支払い方法での決済ができませんでした。支払い方法を確認してください。
                </Box>
                <Box>月末までにお支払いの確認ができなかった場合、一部機能に制限がかかります。</Box>
              </Box>
              <Button
                variant="contained"
                sx={{
                  mx: 2,
                  mb: 2,
                  height: 41,
                  borderRadius: 1,
                  bgcolor: 'primary.main',
                  color: 'white',
                  boxShadow: 0,
                  '&:hover': {
                    bgcolor: 'primary.dark',
                  },
                }}
                onClick={confirmPaymentMethod}
              >
                支払いフォームへ移動する
              </Button>
            </>
          ) : null}
        </Stack>
      </Stack>
    </Box>
  );
};

export default TrialStatus;
