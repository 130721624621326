import { Box, Stack } from '@mui/material';
import React from 'react';

type ScorerInfoProps = {
  firstScorer?: string;
  finalScorer?: string;
};

const ScorerInfo: React.FC<ScorerInfoProps> = ({ firstScorer, finalScorer }) => {
  return (
    <Stack justifyContent="right" alignItems="center" direction="row" sx={{ mb: 2 }}>
      <Stack direction="row" gap={2}>
        <Box>{firstScorer ? `採点者: ${firstScorer}` : null}</Box>
        <Box>{firstScorer !== finalScorer ? `最終更新者: ${finalScorer}` : null}</Box>
      </Stack>
    </Stack>
  );
};

export default ScorerInfo;
