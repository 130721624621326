import 'swiper/css';
import 'swiper/css/navigation';
import '@/style/slide.scss';

import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SwiperCore from 'swiper';
import { Controller, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Handbook } from '@/generated/types/typescript-axios';
import useGrade from '@/hooks/useGrade';

interface HandbookModalProps {
  handbook: Handbook;
  demoCurrentSlide: number;
  demoTotalSlides: number | undefined;
  demoHandleSlideChange: (swiper: { realIndex: number }) => void;
  openModal: () => void;
  setFirstSwiper: (swiper: SwiperCore) => void;
  secondSwiper: SwiperCore | undefined;
}

// 画像のアスペクト比を非同期に取得する関数
const imageAspectRatio = async (image_url: string): Promise<number> => {
  return new Promise((resolve) => {
    const image = new Image();
    image.onload = () => {
      resolve(image.width / image.height);
    };
    image.src = image_url;
  });
};

const HandbookImage: React.FC<HandbookModalProps> = ({
  handbook,
  demoHandleSlideChange,
  demoCurrentSlide,
  demoTotalSlides,
  openModal,
  setFirstSwiper,
  secondSwiper,
}) => {
  const [aspectRatios, setAspectRatios] = useState<number[]>([]);
  const { getGradeNameById } = useGrade();

  const isMdScreen = useMediaQuery('(min-width: 960px)');

  useEffect(() => {
    // Handbookの各画像のアスペクト比を取得し、ステートに設定
    const fetchAspectRatios = async () => {
      const ratios = await Promise.all(
        handbook.demonstration.image_urls.map(
          async (image_url) => await imageAspectRatio(image_url)
        )
      );
      setAspectRatios(ratios);
    };

    fetchAspectRatios();
  }, [handbook.demonstration.image_urls]);

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: { md: '100%' },
          position: 'relative',
        }}
        className="image-slide"
      >
        <Swiper
          modules={[Navigation, Controller]}
          navigation
          onSlideChange={demoHandleSlideChange}
          onSwiper={setFirstSwiper}
          controller={{ control: secondSwiper }}
          className="tw-relative"
        >
          <Typography
            sx={{
              position: 'absolute',
              top: 16,
              right: 8,
              borderRadius: '20px',
              bgcolor: '#f5f5f5',
              p: '4px 8px',
              zIndex: 1,
              fontSize: 12,
            }}
          >
            {handbook.year}年{handbook.month}月 {getGradeNameById(handbook.grade_id)}/
            {handbook.font?.name}
          </Typography>
          {handbook.demonstration.image_urls.map((image_url, index) => (
            <SwiperSlide key={index}>
              <Box sx={{ width: '100%', height: { xs: '443px', md: '487px' } }}>
                <img
                  src={image_url}
                  alt=""
                  className="tw-w-full tw-h-full tw-object-cover tw-blur-2xl tw-brightness-130"
                />
                {isMdScreen ? (
                  <img
                    src={image_url}
                    alt=""
                    className="tw-absolute tw-top-1/2 tw-left-1/2 tw--translate-x-1/2 tw--translate-y-1/2 tw-object-contain tw-h-full"
                  />
                ) : (
                  <img
                    src={image_url}
                    alt=""
                    className={`tw-absolute tw-top-1/2 tw-left-1/2 tw--translate-x-1/2 tw--translate-y-1/2 ${
                      aspectRatios[index] > 1 ? 'tw-max-w-xs' : 'tw-h-full'
                    } tw-object-contain`}
                  />
                )}
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
        <Typography
          variant="body1"
          sx={{
            position: 'absolute',
            bottom: { xs: '16px', md: '20px' },
            left: '50%',
            transform: 'translateX(-50%)',
            padding: '2px 8px',
            borderRadius: '4px',
            bgcolor: '#00000066',
            color: 'white',
            display: 'inline-block',
            zIndex: 1,
            fontSize: 13,
          }}
        >
          {demoCurrentSlide} / {demoTotalSlides}
        </Typography>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: { xs: '-28px', md: -30 },
          right: { xs: '16px' },
          mr: { xs: 0, md: '20%' },
          width: '64px',
          height: '64px',
          borderRadius: '9999px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: '#333',
          zIndex: 1,
        }}
      >
        <Button onClick={() => openModal()}>
          <ZoomInIcon
            sx={{
              width: 24,
              height: 24,
              color: 'white',
            }}
          />
        </Button>
      </Box>
    </>
  );
};

export default HandbookImage;
