import DownloadIcon from '@mui/icons-material/Download';
import { Box, Button, Tab, TableBody, TableHead, TableRow, Tabs } from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import React, { useEffect } from 'react';

// import CsvDownload from '@/components/CsvDownload';
// import Loader from '@/components/Loader';
import TableCase from '@/components/parts/TableCase';
import { fetchUsers, userSelector } from '@/services/userSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
// import { AsyncStatus } from '@/types';

const studentTableHeaderLabels = [
  '項番',
  'ユーザー名',
  '氏名',
  '入会コース',
  'メールアドレス',
  '性別',
  '生年月日',
  '都道府県',
  '書道経験',
  '保護者氏名',
];

const adminTableHeaderLabels = ['項番', 'ユーザー名', '氏名', 'メールアドレス'];

const TableData = ({ label, width = 50 }: { label: string; width?: number }) => (
  <Box
    sx={{
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width,
    }}
  >
    {label}
  </Box>
);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: grey[900],
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function UserManagement() {
  const dispatch = useAppDispatch();
  const { users } = useAppSelector(userSelector);

  const [tabValue, setTabValue] = React.useState(0);

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const adminUsers = users.filter((user) => user.roles?.some((role) => role.role_name === 'admin'));
  const studentUsers = users.filter((user) =>
    user.roles?.some((role) => role.role_name === 'student')
  );

  const handleChange = (event: React.SyntheticEvent, newTabValue: number) => {
    setTabValue(newTabValue);
  };

  const handleDownloadCSV = async () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0];

    const header = 'ID,氏名,ユーザー名,入会コース,メールアドレス\r\n';
    const csvData = studentUsers
      .map((user) =>
        [
          user.id,
          `${user.last_name} ${user.first_name}`,
          user.nickname,
          user.course_type === 1 ? '一般' : '学生',
          user.email,
        ].join(',')
      )
      .join('\n');
    const data = header + csvData;

    const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `users_${formattedDate}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Tabs value={tabValue} onChange={handleChange}>
            <Tab label="競書生" />
            <Tab label="管理者" />
          </Tabs>
          <Box
            sx={{
              fontSize: 14,
              color: 'text.secondary',
              pr: 3,
            }}
          >
            ユーザー数: {tabValue === 1 ? adminUsers.length : studentUsers.length} /{users.length}名
          </Box>
        </Box>
      </Box>
      {tabValue === 0 ? (
        <Box sx={{ pt: 3, pr: 3, textAlign: 'right' }}>
          <Button
            variant="outlined"
            startIcon={<DownloadIcon />}
            onClick={handleDownloadCSV}
            sx={{
              border: 1,
              borderColor: 'grey.400',
              color: 'initial',
              fontWeight: 'bold',
            }}
          >
            csvダウンロード
          </Button>
        </Box>
      ) : (
        ''
      )}
      <TableCase index={0} value={tabValue}>
        <TableHead>
          <TableRow>
            {studentTableHeaderLabels.map((label) => (
              <StyledTableCell key={label}>{label}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {studentUsers.map((row, index) => (
            <StyledTableRow key={row.email}>
              <StyledTableCell component="th" scope="row">
                <TableData label={`${index + 1}`} />
              </StyledTableCell>
              <StyledTableCell>
                <TableData label={row.nickname} width={200} />
              </StyledTableCell>
              <StyledTableCell>
                <TableData label={`${row.last_name} ${row.first_name}`} width={200} />
              </StyledTableCell>
              <StyledTableCell>
                <TableData label={row.course_type === 1 ? '一般' : '学生'} width={100} />
              </StyledTableCell>
              <StyledTableCell>
                <TableData label={row.email} width={200} />
              </StyledTableCell>
              <StyledTableCell>
                <TableData
                  label={row.gender_id === 1 ? '女性' : row.gender_id === 2 ? '男性' : 'その他'}
                />
              </StyledTableCell>
              <StyledTableCell>
                <TableData label={row.birthday} width={100} />
              </StyledTableCell>
              <StyledTableCell>
                <TableData label={row.prefecture} width={100} />
              </StyledTableCell>
              <StyledTableCell>
                <TableData label={row.is_experienced ? '有' : '無'} width={100} />
              </StyledTableCell>
              <StyledTableCell>
                <TableData
                  label={
                    row.parent_first_name ? `${row.parent_last_name} ${row.parent_first_name}` : '-'
                  }
                  width={200}
                />
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </TableCase>
      <TableCase index={1} value={tabValue}>
        <TableHead>
          <TableRow>
            {adminTableHeaderLabels.map((label) => (
              <StyledTableCell key={label}>{label}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {adminUsers.map((row, index) => (
            <StyledTableRow key={row.email}>
              <StyledTableCell component="th" scope="row">
                <TableData label={`${index + 1}`} />
              </StyledTableCell>
              <StyledTableCell>
                <TableData label={row.nickname} width={200} />
              </StyledTableCell>
              <StyledTableCell>
                <TableData label={`${row.last_name} ${row.first_name}`} width={200} />
              </StyledTableCell>
              <StyledTableCell>
                <TableData label={row.email} width={200} />
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </TableCase>
    </Box>
  );
}
