import { Box, Button, SelectChangeEvent, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';

import { CommentTemplate } from '@/generated/types/typescript-axios';
import {
  assignmentSelector,
  clearStatus,
  createAssignmentReview,
  fetchAssignmentReview,
} from '@/services/assignmentSlice';
import { commentTemplateSelector, fetchCommentTemplates } from '@/services/commentTemplateSlice';
import { viewpointSelector } from '@/services/viewpointSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { AsyncStatus } from '@/types';

import Loader from '../Loader';
import { useToast } from '../ToastProvider';
import CompletionDialog from './CompletionDialog';
import RankDisplay from './RankDisplay';
import ScoreInput from './ScoreInput';
import ScorerInfo from './ScorerInfo';
import TemplateComments from './TemplateComments';

type Props = {
  courseType: number;
  onclickNextUser: () => void;
  hasNextUser: boolean;
};

const Review = ({ courseType, onclickNextUser, hasNextUser }: Props) => {
  const dispatch = useAppDispatch();
  const { assignment, review, status } = useAppSelector(assignmentSelector);
  const { viewpoints, status: viewpointStatus } = useAppSelector(viewpointSelector);
  const {
    commentTemplateCategories,
    commentTemplateComments,
    status: commentTemplateStatus,
  } = useAppSelector(commentTemplateSelector);
  const scoreFields = viewpoints.map((_, index) => `score${index + 1}`);
  const { control, getValues, handleSubmit, setValue, reset } = useForm<FieldValues>({
    defaultValues: {
      score1: '0',
      score2: '0',
      score3: '0',
      score4: '0',
      score5: '0',
      category1: '',
      template_comment1: '',
      category2: '',
      template_comment2: '',
      free_comment: '',
    },
  });
  const { toast } = useToast();
  const [total, setTotal] = useState<number>(0);
  const [selectedComments1, setSelectedComments1] = useState<CommentTemplate[]>([]);
  const [selectedComments2, setSelectedComments2] = useState<CommentTemplate[]>([]);
  const [open, setOpen] = React.useState(false);

  const categoryCommentList = [
    { key: '1', commentTemplates: selectedComments1, setCommentTemplates: setSelectedComments1 },
    { key: '2', commentTemplates: selectedComments2, setCommentTemplates: setSelectedComments2 },
  ];

  useEffect(() => {
    dispatch(fetchCommentTemplates(courseType));
  }, [dispatch]);

  // 採点情報と評価項目を取得したら、取得した値をフォームにセット&合計を計算
  useEffect(() => {
    if (
      status.fetchReview === AsyncStatus.SUCCESS &&
      viewpointStatus.bulkFetch === AsyncStatus.SUCCESS &&
      commentTemplateStatus.bulkFetch === AsyncStatus.SUCCESS
    ) {
      setFetchedValues();
      setTotal(addUpCurrentPoints());
    }
  }, [status.fetchReview, viewpointStatus.bulkFetch, commentTemplateStatus.bulkFetch]);

  // TODO: review 500エラー対応完了後に削除
  useEffect(() => {
    if (status.fetchReview === AsyncStatus.FAILED) {
      reset();
    }
  }, [status.fetchReview]);

  // 登録時の動作
  useEffect(() => {
    if (status.createReview === AsyncStatus.SUCCESS) {
      toast({ message: '採点情報の登録が完了しました' });
      dispatch(clearStatus());
      dispatch(fetchAssignmentReview(assignment?.id as number));
      setOpen(true);
    } else if (status.createReview === AsyncStatus.FAILED) {
      toast({ message: '採点情報の登録に失敗しました', type: 'error' });
      dispatch(clearStatus());
    }
  }, [status.createReview]);

  // 取得した採点情報をフォームにセットする
  const setFetchedValues = () => {
    if (!review?.scores || !viewpoints) return;
    // 点数をセットする
    for (let i = 0; i <= scoreFields.length; i++) {
      const score = review.scores.find(({ viewpoint_id }) => {
        return viewpoint_id === viewpoints[i]?.id;
      });
      setValue(scoreFields[i], String(score?.point ?? '0'));
    }

    categoryCommentList.forEach(({ key, setCommentTemplates }, index) => {
      if (review.template_comments?.[index]) {
        // 審査所感カテゴリをセットする
        setValue(`category${key}`, review.template_comments[index].category_id);
        // 審査所感(テンプレート)の選択肢をセットする
        setCommentTemplates(
          commentTemplateComments.find(
            ({ category }) => category.id === review.template_comments?.[index]?.category_id
          )?.items ?? []
        );
        // 取得した審査所感(テンプレート)をセットする
        setValue(`template_comment${index + 1}`, review.template_comments?.[index].id);
      } else {
        setValue(`category${key}`, '');
        setValue(`template_comment${index + 1}`, '');
      }
    });
    setValue('free_comment', review.free_comment ?? '');
  };

  // フォームにセットされている点数の合計を返す
  const addUpCurrentPoints = () => {
    let sum = 0;
    scoreFields.forEach((scoreField) => {
      sum += Number(getValues(scoreField) ?? 0);
    });
    return sum;
  };

  // 点数が変わったら合計点数を計算し直す
  const handleChangePoint = () => {
    setTotal(addUpCurrentPoints());
  };

  // 定型文のカテゴリが変わったら定型文のセレクトボックスをリセットして対応する選択肢をセットする
  const handleChangeCategory = (event: SelectChangeEvent) => {
    const selectedComments =
      commentTemplateComments.find(({ category }) => category.id === Number(event.target.value))
        ?.items ?? [];

    switch (event.target.name) {
      case 'category1':
        setValue('template_comment1', '');
        setSelectedComments1(selectedComments);
        break;
      case 'category2':
        setValue('template_comment2', '');
        setSelectedComments2(selectedComments);
        break;
    }
  };

  const handleSubmitForm = ({
    template_comment1,
    template_comment2,
    free_comment,
  }: FieldValues) => {
    if (!assignment?.id) return;

    // 点数バリデーション
    const scores = scoreFields.map((scoreField, i) => {
      return { viewpoint_id: viewpoints[i].id, point: Number(getValues(scoreField)) };
    });
    if (scores.some(({ point }) => point === 0)) {
      toast({ message: '未入力の点数があります', type: 'error' });
      return;
    }

    // 審査所感バリデーション
    const template_comment_ids = [template_comment1, template_comment2].filter((item) => !!item);
    if (template_comment_ids.length === 0) {
      toast({ message: '未入力の審査所感があります', type: 'error' });
      return;
    }

    dispatch(
      createAssignmentReview({
        id: assignment.id,
        createReview: {
          scores: scoreFields.map((scoreField, i) => {
            return { viewpoint_id: viewpoints[i].id, point: Number(getValues(scoreField)) };
          }),
          template_comment_ids: [template_comment1, template_comment2].filter((item) => !!item),
          free_comment: free_comment ?? '',
        },
      })
    );
  };

  const handleCloseCompleteDialog = () => {
    setOpen(false);
  };

  return status.fetch === AsyncStatus.LOADING ||
    status.fetchReview === AsyncStatus.LOADING ||
    status.createReview === AsyncStatus.LOADING ? (
    <Loader />
  ) : (
    <Box component="form" onSubmit={handleSubmit(handleSubmitForm)}>
      {/* 採点者情報 */}
      {review?.scorers ? (
        <ScorerInfo
          firstScorer={review?.scorers.first_scorer}
          finalScorer={review?.scorers.final_scorer}
        />
      ) : undefined}
      {/* 点数入力欄 */}
      <ScoreInput
        viewpoints={viewpoints}
        scoreFields={scoreFields}
        control={control}
        handleChangePoint={handleChangePoint}
      />
      {viewpoints.length ? <Box sx={{ my: 2, fontSize: 24 }}>合計 {total}/50</Box> : null}
      {/* 順位・段級位表示 */}
      <RankDisplay
        wholeOrder={review?.rank_orders?.whole_order}
        sameRankOrder={review?.rank_orders?.same_rank_order}
        currentRank={review?.ranks?.current_rank}
        nextRank={review?.ranks?.next_rank}
      />

      {/* テンプレートコメント */}
      {categoryCommentList.map(({ key, commentTemplates }) => {
        const categoryFormName = `category${key}`;
        const commentFormName = `template_comment${key}`;

        return (
          <TemplateComments
            key={key}
            control={control}
            categoryFormName={categoryFormName}
            commentFormName={commentFormName}
            categories={commentTemplateCategories}
            categoryComments={commentTemplates}
            handleChangeCategory={handleChangeCategory}
          />
        );
      })}
      {/* フリーコメント */}
      <Controller
        name="free_comment"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            id="free_comment"
            label="審査所感(自由記入)"
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            sx={{ mb: 2 }}
          />
        )}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button type="submit" variant="contained">
          登録
        </Button>
      </Box>
      {/* 次へ進めるdialog */}
      <CompletionDialog
        open={open}
        onClose={handleCloseCompleteDialog}
        hasNextUser={hasNextUser}
        onclickNextUser={onclickNextUser}
      />
    </Box>
  );
};

export default Review;
