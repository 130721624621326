import dayjs from 'dayjs';

export const determinePeriod = (): { year: number; month: number } => {
  const now = dayjs();
  const day = now.date();
  let year = now.year();
  let month = now.month() + 1; // monthは0から始まるので+1する

  if (day < 11) {
    month--;
    if (month < 1) {
      month = 12;
      year--;
    }
  }
  return { year, month };
};
