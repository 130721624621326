import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Userinfo, UserInfoApi } from '@/generated/types/typescript-axios';
import { RootState } from '@/store';
import { AsyncStatus } from '@/types';
import { ApiConfig } from '@/utils/apiConfig';

type AuthState = {
  account: Userinfo;
  status: { fetch: AsyncStatus };
};

const initialState: AuthState = {
  account: {
    id: '',
    last_name: '',
    first_name: '',
    nickname: '',
    course_type: 0,
    email: '',
    gender_id: 0,
    birthday: '',
    prefecture: '',
    is_experienced: false,
    completed_sign_up: false,
  },
  status: {
    fetch: AsyncStatus.IDLE,
  },
};

export const fetchUserInfo = createAsyncThunk('fetchUserInfo', async (_, thunkAPI) => {
  try {
    const apiConfig = ApiConfig();
    const userInfoApi = new UserInfoApi(apiConfig);
    const { data } = await userInfoApi.getLoginUser();
    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearStatus: (state: AuthState) => {
      state.status.fetch = AsyncStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserInfo.pending, (state) => {
        state.status.fetch = AsyncStatus.LOADING;
      })
      .addCase(fetchUserInfo.fulfilled, (state, { payload }: { payload: Userinfo }) => {
        state.account = payload;
        state.status.fetch = AsyncStatus.SUCCESS;
      })
      .addCase(fetchUserInfo.rejected, (state) => {
        state.status.fetch = AsyncStatus.FAILED;
      });
  },
});

export const { clearStatus } = authSlice.actions;

export const authSelector = (state: RootState) => state.auth;

export default authSlice.reducer;
