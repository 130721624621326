import { Alert, AlertColor, Snackbar } from '@mui/material';
import React, { createContext, ReactNode, useContext, useState } from 'react';

type ToastProp = {
  message: string;
  type?: AlertColor;
  duration?: number;
};

type ToastContextType = {
  toast: (props: ToastProp) => void;
};

const ToastContext = createContext<ToastContextType>({
  toast: () => undefined,
});

// useToastを使って深い階層のコンポーネントでもトーストを使えるようにする
export const useToast = () => {
  return useContext(ToastContext);
};

export const ToastContextProvider = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState<AlertColor>('success');
  const [duration, setDuration] = useState<number>(0);

  const handleClose = () => {
    setOpen(false);
  };

  const toast = ({ message, type = 'success', duration = 5000 }: ToastProp) => {
    setMessage(message);
    setType(type);
    setDuration(duration);
    setOpen(true);
  };

  const contextValue = { toast };

  return (
    <ToastContext.Provider value={contextValue}>
      <>
        {children}
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={open}
          autoHideDuration={duration}
          onClose={handleClose}
        >
          <Alert severity={type} sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>
      </>
    </ToastContext.Provider>
  );
};

export default ToastContext;
