import { Box, Divider, Grid, List, ListItem, Typography } from '@mui/material';
import React from 'react';

import { ResultAssignmentsInner } from '@/generated/types/typescript-axios';
import { FontTypeIDs } from '@/types';

type Props = {
  targetFontResult: ResultAssignmentsInner;
};

export default function CommentContent({ targetFontResult }: Props) {
  return (
    <Grid item xs={12} md={targetFontResult?.font?.id === FontTypeIDs.RINSHO ? 8 : 12}>
      <Box sx={{ border: 1, borderColor: 'grey.400', borderRadius: 2, p: 2, pb: 1 }}>
        <Typography sx={{ color: 'grey.400', fontSize: 10 }}>コメント</Typography>
        <List sx={{ p: 0 }}>
          {targetFontResult.comments?.map((comment, i) => (
            <ListItem
              key={i}
              sx={{ px: 0, borderTop: i !== 0 ? 1 : 0, borderTopColor: i !== 0 ? 'grey.200' : '' }}
            >
              {i !== 0 && <Divider component="div" />}
              <Box>{comment}</Box>
            </ListItem>
          ))}
        </List>
      </Box>
    </Grid>
  );
}
