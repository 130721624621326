import CircleIcon from '@mui/icons-material/Circle';
import { Box, Chip, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';

import Loader from '@/components/Loader';

type Props = {
  fontCases: {
    id: number;
    name: string;
    is_registered: boolean;
  }[];
  loading: boolean;
  onClickNavigation: (fontId: number) => void;
};

export default function AdultHandbookList({ fontCases, loading, onClickNavigation }: Props) {
  return loading ? (
    <Loader />
  ) : (
    <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <List>
        {fontCases.map((font) => (
          <ListItem disablePadding key={font.name}>
            <ListItemButton onClick={() => onClickNavigation(font.id)}>
              <CircleIcon fontSize="small" sx={{ color: grey[600] }} />
              <ListItemText primary={'「' + font.name + '」 手本管理'} />
              <Chip
                label={font.is_registered ? '登録済' : '未登録'}
                variant={font.is_registered ? 'filled' : 'outlined'}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
