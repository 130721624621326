import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Box, Chip, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';

import Loader from '@/components/Loader';
import { AssignmentUser } from '@/generated/types/typescript-axios';

type Props = {
  userList: AssignmentUser[];
  loading: boolean;
  onClickItem: (user: AssignmentUser) => void;
  selectedUser: AssignmentUser | undefined;
};

export default function UerList({ userList, loading, onClickItem, selectedUser }: Props) {
  return loading ? (
    <Loader />
  ) : (
    <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <List component="nav" aria-labelledby="nested-list-subheader">
        {userList.length ? (
          userList.map((user, index) => (
            <ListItem disablePadding key={user.assignment_id}>
              <ListItemButton
                onClick={() => onClickItem(user)}
                selected={selectedUser === user}
                sx={{ width: 300 }}
              >
                <Typography variant="body2" sx={{ mr: 3 }}>
                  {index + 1}
                </Typography>
                <AccountCircleIcon fontSize="small" sx={{ color: grey[600] }} />
                <ListItemText primary={user.nickname ?? ''} />
                <Chip
                  label={user.is_reviewed ? '採点済' : '未採点'}
                  variant={user.is_reviewed ? 'filled' : 'outlined'}
                />
              </ListItemButton>
            </ListItem>
          ))
        ) : (
          <ListItem disablePadding>
            <ListItemText primary={'提出課題がありません'} sx={{ color: 'grey', py: 1 }} />
          </ListItem>
        )}
      </List>
    </Box>
  );
}
