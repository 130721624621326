import '@/style/slide.scss';
import 'swiper/css';
import 'swiper/css/navigation';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, CircularProgress, Container, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import axios from 'axios';
import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SwiperCore from 'swiper';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Handbook } from '@/generated/types/typescript-axios';
import { clearStatus, fetchHandbooks, handbookSelector } from '@/services/handbookSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { AsyncStatus } from '@/types';

import HandbookImage from './HandbookImage';
import HandbookModal from './HandbookModal';

const HandbookDetail = () => {
  const navigation = useNavigate();
  const { year, month, fontId, gradeId } = useParams();
  const { handbooks, status } = useAppSelector(handbookSelector);
  const dispatch = useAppDispatch();
  const [handbook, setHandbook] = useState<Handbook>();
  const demoTotalSlides = handbook?.demonstration.image_urls.length;
  const explainTotalSlides = handbook?.explanation.image_urls.length;
  const [demoCurrentSlide, setDemoCurrentSlide] = useState(1);
  const [explainCurrentSlide, setExplainCurrentSlide] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [firstSwiper, setFirstSwiper] = useState<SwiperCore>();
  const [secondSwiper, setSecondSwiper] = useState<SwiperCore>();

  const demoHandleSlideChange = (swiper: { realIndex: number }) => {
    setDemoCurrentSlide(swiper.realIndex + 1);
  };
  const explainHandleSlideChange = (swiper: { realIndex: number }) => {
    setExplainCurrentSlide(swiper.realIndex + 1);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleBack = () => {
    navigation(-1);
  };

  useEffect(() => {
    if (gradeId) {
      dispatch(
        fetchHandbooks({
          year: Number(year),
          month: Number(month),
          font_id: Number(fontId),
          grade_id: Number(gradeId),
        })
      );
    } else {
      dispatch(
        fetchHandbooks({
          year: Number(year),
          month: Number(month),
          font_id: Number(fontId),
        })
      );
    }
    return () => {
      dispatch(clearStatus());
    };
  }, [dispatch]);

  const [videoAspectRatios, setVideoAspectRatios] = useState<Record<string, string>>({});

  useEffect(() => {
    if (status.bulkFetch === AsyncStatus.SUCCESS) {
      setHandbook(handbooks.length ? handbooks[0] : undefined);
      const fetchVideoRatios = async () => {
        const allVideoUrls = [
          ...(handbook?.demonstration.video_urls || []),
          ...(handbook?.explanation.video_urls || []),
        ];

        const allPromises = allVideoUrls.map((video_url) =>
          axios
            .get(`https://vimeo.com/api/oembed.json?url=${video_url}`)
            .catch((err) => console.log(err))
        );

        try {
          const allResults = await Promise.all(allPromises);
          const newRatios: Record<string, string> = {};

          allResults.forEach((res, index) => {
            if (res) {
              const aspectRatio = res.data.width / res.data.height;
              newRatios[allVideoUrls[index]] =
                aspectRatio >= 1 ? 'tw-aspect-video' : 'tw-aspect-[9/16]';
            }
          });

          setVideoAspectRatios(newRatios);
        } catch (err) {
          console.log(err);
        }
      };
      if (handbook) {
        fetchVideoRatios();
      }
    }
  }, [handbooks]);

  if (status.bulkFetch === AsyncStatus.LOADING) {
    return (
      <Box
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}
      >
        <CircularProgress />
      </Box>
    );
  }
  if (status.bulkFetch === AsyncStatus.SUCCESS) {
    return !handbook ? (
      <Box sx={{ p: 2 }}>申し訳ありません。お手本が見つかりませんでした。</Box>
    ) : (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        {!isModalOpen && (
          <Button
            variant="contained"
            startIcon={<ArrowBackIosIcon />}
            onClick={handleBack}
            sx={{
              position: 'fixed',
              top: 80,
              left: 15,
              zIndex: 2,
              color: '#00000099',
              bgcolor: 'transparent',
              boxShadow: 'none',
              textShadow: '0 0 4px #00000040',
              '&:hover': {
                bgcolor: 'transparent',
                boxShadow: 'none',
              },
            }}
          >
            戻る
          </Button>
        )}
        <Container
          disableGutters
          sx={{
            mb: { xs: 5, md: 7 },
            position: 'relative',
          }}
          maxWidth={false}
        >
          {handbook.demonstration.image_urls.length > 0 && (
            <HandbookImage
              handbook={handbook}
              demoHandleSlideChange={demoHandleSlideChange}
              demoCurrentSlide={demoCurrentSlide}
              demoTotalSlides={demoTotalSlides}
              openModal={openModal}
              setFirstSwiper={setFirstSwiper}
              secondSwiper={secondSwiper}
            />
          )}
        </Container>

        <Container maxWidth="xs">
          {handbook.demonstration.video_urls?.map((video_url) => {
            console.log({ video_url: video_url, ratio: videoAspectRatios }, 'demonstration');
            return (
              <Box sx={{ width: '100%', my: 2 }} key={video_url}>
                <iframe
                  src={video_url}
                  className={`tw-w-full tw-border-none tw-my-2 ${
                    videoAspectRatios[video_url] ? videoAspectRatios[video_url] : 'tw-aspect-[9/16]'
                  }`}
                  allowFullScreen
                ></iframe>
              </Box>
            );
          })}

          <Typography variant="h4" sx={{ fontFamily: 'Shippori Mincho B1' }}>
            {handbook.theme || ''}
          </Typography>
          {handbook.explanation?.comment ? (
            <Box sx={{ backgroundColor: '#f2f5f9', borderRadius: 2, mt: 1, px: 2, py: 4 }}>
              <Typography variant="body1">{handbook.explanation?.comment}</Typography>
            </Box>
          ) : null}
          {handbook.explanation.video_urls?.map((video_url) => {
            console.log({ video_url: video_url, ratio: videoAspectRatios }, 'explanation');
            return (
              <Box sx={{ width: '100%', my: 2 }} key={video_url}>
                <iframe
                  src={video_url}
                  className={`tw-w-full tw-border-none tw-my-2 ${
                    videoAspectRatios[video_url] ? videoAspectRatios[video_url] : 'tw-aspect-[9/16]'
                  }`}
                  allowFullScreen
                ></iframe>
              </Box>
            );
          })}
          <Box
            sx={{ width: '100%', mt: 3, mb: 12, position: 'relative', minHeight: { md: '100px' } }}
          >
            {handbook.explanation.image_urls.length > 0 && (
              <>
                <Swiper modules={[Navigation]} navigation onSlideChange={explainHandleSlideChange}>
                  {handbook.explanation.image_urls.map((image_url, index) => (
                    <SwiperSlide key={index}>
                      <Box sx={{ width: '100%', height: '509px' }}>
                        <img
                          src={image_url}
                          alt=""
                          className="tw-object-contain tw-w-full tw-h-full tw-rounded-lg"
                        />
                      </Box>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <Typography
                  variant="body1"
                  sx={{
                    display: 'inline-block',
                    margin: '8px auto 0px',
                    padding: '2px 8px',
                    borderRadius: '4px',
                    bgcolor: '#00000099',
                    color: 'white',
                    fontSize: 13,
                  }}
                >
                  {explainCurrentSlide} / {explainTotalSlides}
                </Typography>
              </>
            )}
          </Box>

          <HandbookModal
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            setSecondSwiper={setSecondSwiper}
            firstSwiper={firstSwiper}
            demoHandleSlideChange={demoHandleSlideChange}
            handbook={handbook}
            demoCurrentSlide={demoCurrentSlide}
            demoTotalSlides={demoTotalSlides}
          />
        </Container>
      </Box>
    );
  }
  return null;
};

export default HandbookDetail;
