import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RankArchivesInner, ResultApi } from '@/generated/types/typescript-axios';
import { RootState } from '@/store';
import { AsyncStatus } from '@/types';
import { ApiConfig } from '@/utils/apiConfig';

type RankChangesState = {
  rankChanges: RankArchivesInner[] | undefined;
  status: { fetch: AsyncStatus };
};

const initialState: RankChangesState = {
  rankChanges: undefined,
  status: {
    fetch: AsyncStatus.IDLE,
  },
};

export const fetchRankChanges = createAsyncThunk(
  'fetchRankChanges',
  async (year: number, thunkAPI) => {
    try {
      const apiConfig = ApiConfig();
      const resultApi = new ResultApi(apiConfig);
      const { data } = await resultApi.getRankArchives(year);
      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const rankChangesSlice = createSlice({
  name: 'rankChanges',
  initialState,
  reducers: {
    clearStatus: (state: RankChangesState) => {
      state.status.fetch = AsyncStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRankChanges.pending, (state) => {
        state.status.fetch = AsyncStatus.LOADING;
      })
      .addCase(
        fetchRankChanges.fulfilled,
        (state, { payload }: { payload: RankArchivesInner[] }) => {
          state.rankChanges = payload;
          state.status.fetch = AsyncStatus.SUCCESS;
        }
      )
      .addCase(fetchRankChanges.rejected, (state) => {
        state.status.fetch = AsyncStatus.FAILED;
      });
  },
});

export const { clearStatus } = rankChangesSlice.actions;

export const rankChangesSelector = (state: RootState) => state.rankChanges;

export default rankChangesSlice.reducer;
