import { SxProps } from '@mui/system';

export * from './account';
export * from './assignment';
export * from './handbook';
export * from './term';

export type Nullable<T> = T | null | undefined;

export enum AsyncStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCESS = 'success',
  FAILED = 'failed',
}

export enum URL {
  CALLBACK = '/callback',
  SIGN_UP_REGISTRATION = '/account/registration',
  SIGN_UP_CONFIRMATION = '/account/confirmation',
  PLAN = '/plans',
  MY_PAGE = '/my-page',
  HANDBOOKS = '/handbooks',
  ASSIGNMENT = '/assignment',
  RESULT = '/result',
  WHOLE_RESULT = '/whole-result',
  OVERALL_RESULT = '/overall-result',
  USER_MANAGEMENT = '/management/user',
  HANDBOOK_MANAGEMENT = '/management/handbook',
  ASSIGNMENT_MANAGEMENT = '/management/assignment',
  USER_RANKS_MANAGEMENT = '/management/user-ranks',
  CLASSROOM_MANAGEMENT = '/management/classroom',
  CLASSROOM_REGISTRATION = '/management/classroom/registration',
  HELP_TOP = 'https://ribbon-jury-464.notion.site/c9d9f64aedc8450685cee96ec72bc571',
  HELP_ASSIGNMENT = 'https://ribbon-jury-464.notion.site/a2a6f0c569334690a1db6f4adee1a005',
  HELP_PRIVATE_ACCOUNT = 'https://ribbon-jury-464.notion.site/3e386b36e0154383abead11e5cd4ec74',
}

export type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
  sx?: SxProps;
};
