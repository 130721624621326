import { Box, Paper, Typography } from '@mui/material';
import React from 'react';

import {
  ReviewRankOrdersSameRankOrder,
  ReviewRankOrdersWholeOrder,
} from '@/generated/types/typescript-axios';

type RankDisplayProps = {
  wholeOrder?: ReviewRankOrdersWholeOrder;
  sameRankOrder?: ReviewRankOrdersSameRankOrder;
  currentRank?: string;
  nextRank?: string;
};

const RankDisplay: React.FC<RankDisplayProps> = ({
  wholeOrder,
  sameRankOrder,
  currentRank,
  nextRank,
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 2 }}>
      {/* 全体順位と同位順位の表示 */}
      {wholeOrder && sameRankOrder && (
        <>
          <Paper variant="outlined" sx={{ py: 1, px: 2 }}>
            <Typography variant="body2">全体順位</Typography>
            <Typography variant="h4">
              {wholeOrder.order === 0 ? '-' : wholeOrder.order}/
              {wholeOrder.total === 0 ? '-' : wholeOrder.total}
            </Typography>
          </Paper>
          <Paper variant="outlined" sx={{ py: 1, px: 2 }}>
            <Typography variant="body2">同位順位</Typography>
            <Typography variant="h4">
              {sameRankOrder.order === 0 ? '-' : sameRankOrder.order}/
              {sameRankOrder.total === 0 ? '-' : sameRankOrder.total}
            </Typography>
          </Paper>
        </>
      )}
      {/* 採点前後の段級位 */}
      <Paper variant="outlined" sx={{ py: 1, px: 2 }}>
        <Typography variant="body2">採点前</Typography>
        <Typography variant="h4">{currentRank || '未登録'}</Typography>
      </Paper>
      <Paper variant="outlined" sx={{ py: 1, px: 2 }}>
        <Typography variant="body2">採点後</Typography>
        <Typography variant="h4">{nextRank || '集計中'}</Typography>
      </Paper>
    </Box>
  );
};

export default RankDisplay;
