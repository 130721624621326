import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { authSelector } from '@/services/authSlice';
import { useAppSelector } from '@/store/hooks';

type Props = {
  allowedRoles: string[];
};

const RequireAllowedRoles = ({ allowedRoles }: Props) => {
  const { account } = useAppSelector(authSelector);
  const isAccessAllowed = account.roles
    ? account.roles.some(({ role_name }) => allowedRoles.includes(role_name ?? ''))
    : null;

  return isAccessAllowed ? <Outlet /> : <Navigate to="/" />;
};

export default RequireAllowedRoles;
