import { Box } from '@mui/material';
import React from 'react';

import { TabPanelProps } from '@/types';

const TabPanel = ({ children, value, index, sx = { p: 3 } }: TabPanelProps) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box sx={sx}>{children}</Box>}
    </div>
  );
};

export default TabPanel;
