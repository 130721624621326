import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  CreateHandbook,
  Handbook,
  HandBookApi,
  UpdateHandbook,
} from '@/generated/types/typescript-axios';
import { RootState } from '@/store';
import { AsyncStatus } from '@/types';
import { ApiConfig } from '@/utils/apiConfig';

type HandbookState = {
  handbook: Handbook | undefined;
  handbooks: Handbook[];
  status: {
    bulkFetch: AsyncStatus;
    post: AsyncStatus;
    put: AsyncStatus;
    delete: AsyncStatus;
  };
};

const initialState: HandbookState = {
  handbook: undefined,
  handbooks: [],
  status: {
    bulkFetch: AsyncStatus.IDLE,
    post: AsyncStatus.IDLE,
    put: AsyncStatus.IDLE,
    delete: AsyncStatus.IDLE,
  },
};

export const fetchHandbooks = createAsyncThunk(
  'fetchHandbooks',
  async (
    params: { year: number; month: number; font_id?: number; grade_id?: number },
    thunkAPI
  ) => {
    try {
      const apiConfig = ApiConfig();
      const handBookApi = new HandBookApi(apiConfig);
      const { data } = await handBookApi.getHandbooks(
        params.year,
        params.month,
        params.font_id,
        params.grade_id
      );
      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const postHandbook = createAsyncThunk(
  'postHandbook',
  async (
    { year, month, font_id, grade_id, demonstration, explanation, theme }: CreateHandbook,
    thunkAPI
  ) => {
    try {
      const apiConfig = ApiConfig();
      const handBookApi = new HandBookApi(apiConfig);
      const { data } = await handBookApi.createHandbook(
        {
          year,
          month,
          font_id,
          grade_id,
          demonstration,
          explanation,
          theme,
        },
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const putHandbook = createAsyncThunk(
  'putHandbook',
  async ({ id, demonstration, explanation, theme }: { id: number } & UpdateHandbook, thunkAPI) => {
    try {
      const apiConfig = ApiConfig();
      const handBookApi = new HandBookApi(apiConfig);
      const { data } = await handBookApi.updateHandbook(
        id,
        {
          demonstration,
          explanation,
          theme,
        },
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const deleteHandbook = createAsyncThunk('deleteHandbook', async (id: number, thunkAPI) => {
  try {
    const apiConfig = ApiConfig();
    const handBookApi = new HandBookApi(apiConfig);
    await handBookApi.deleteHandbook(id);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const handbookSlice = createSlice({
  name: 'handbook',
  initialState,
  reducers: {
    clearHandbooks: (state: HandbookState) => {
      state.handbooks = [];
    },
    clearStatus: (state: HandbookState) => {
      state.status.bulkFetch = AsyncStatus.IDLE;
      state.status.post = AsyncStatus.IDLE;
      state.status.put = AsyncStatus.IDLE;
      state.status.delete = AsyncStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHandbooks.pending, (state) => {
        state.status.bulkFetch = AsyncStatus.LOADING;
      })
      .addCase(fetchHandbooks.fulfilled, (state, { payload }: { payload: Handbook[] }) => {
        state.handbooks = payload;
        state.status.bulkFetch = AsyncStatus.SUCCESS;
      })
      .addCase(fetchHandbooks.rejected, (state) => {
        state.status.bulkFetch = AsyncStatus.FAILED;
      })
      .addCase(postHandbook.pending, (state) => {
        state.status.post = AsyncStatus.LOADING;
      })
      .addCase(postHandbook.fulfilled, (state, { payload }: { payload: Handbook }) => {
        state.handbook = payload;
        state.status.post = AsyncStatus.SUCCESS;
      })
      .addCase(postHandbook.rejected, (state) => {
        state.status.post = AsyncStatus.FAILED;
      })
      .addCase(putHandbook.pending, (state) => {
        state.status.put = AsyncStatus.LOADING;
      })
      .addCase(putHandbook.fulfilled, (state, { payload }: { payload: Handbook }) => {
        state.handbook = payload;
        state.status.put = AsyncStatus.SUCCESS;
      })
      .addCase(putHandbook.rejected, (state) => {
        state.status.put = AsyncStatus.FAILED;
      })
      .addCase(deleteHandbook.pending, (state) => {
        state.status.delete = AsyncStatus.LOADING;
      })
      .addCase(deleteHandbook.fulfilled, (state, { meta }) => {
        state.handbooks = state.handbooks.filter(({ id }) => id !== meta.arg);
        state.status.delete = AsyncStatus.SUCCESS;
      })
      .addCase(deleteHandbook.rejected, (state) => {
        state.status.delete = AsyncStatus.FAILED;
      });
  },
});

export const { clearHandbooks, clearStatus } = handbookSlice.actions;
export const handbookSelector = (state: RootState) => state.handbook;
export default handbookSlice.reducer;
