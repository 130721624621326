import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { authSelector } from '@/services/authSlice';
import { useAppSelector } from '@/store/hooks';

export default function SignUpCheckProvider() {
  const { account } = useAppSelector(authSelector);

  return account?.completed_sign_up ? <Navigate to="/" /> : <Outlet />;
}
