import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { authSelector } from '@/services/authSlice';
import { useAppSelector } from '@/store/hooks';

export default function PlanSubscribeCheckProvider() {
  const { account } = useAppSelector(authSelector);
  return account?.subscription_status || account.is_subscribed ? <Navigate to="/" /> : <Outlet />;
}
