import 'swiper/css';
import 'swiper/css/navigation';
import '@/style/slide.scss';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import SwiperCore from 'swiper';
import { Controller, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Handbook } from '@/generated/types/typescript-axios';

interface HandbookModalProps {
  isModalOpen: boolean;
  closeModal: () => void;
  setSecondSwiper: (swiper: SwiperCore) => void;
  firstSwiper: SwiperCore | undefined;
  demoHandleSlideChange: (swiper: { realIndex: number }) => void;
  handbook: Handbook | undefined;
  demoCurrentSlide: number;
  demoTotalSlides: number | undefined;
}

const HandbookModal: React.FC<HandbookModalProps> = ({
  isModalOpen,
  closeModal,
  setSecondSwiper,
  firstSwiper,
  demoHandleSlideChange,
  handbook,
  demoCurrentSlide,
  demoTotalSlides,
}) => {
  return (
    <>
      {isModalOpen && (
        <Container sx={{ width: '100%' }}>
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              bgcolor: 'black',
              zIndex: 1100,
            }}
            className="modal"
          >
            <Box
              sx={{
                position: 'absolute',
                width: '24px',
                height: '24px',
                top: '24px',
                left: '24px',
                color: 'white',
              }}
            >
              <CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
            </Box>
            <Box
              sx={{
                position: 'relative',
                top: '50%',
                width: '100%',
                transform: 'translateY(-50%)',
              }}
            >
              <Swiper
                modules={[Navigation, Controller]}
                navigation
                onSlideChange={demoHandleSlideChange}
                onSwiper={setSecondSwiper}
                controller={{ control: firstSwiper }}
              >
                {handbook?.demonstration.image_urls?.map((image_url, index) => (
                  <SwiperSlide key={index} className="tw-m-auto">
                    <img
                      src={image_url}
                      alt=""
                      className="tw-object-contain tw-max-w-full tw-max-h-[70vh] lg:tw-max-w-xl"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
            <Box>
              <Typography
                variant="body1"
                sx={{
                  display: 'inline-block',
                  width: '43px',
                  height: '23px',
                  borderRadius: '4px',
                  padding: '4px 8px',
                  bgcolor: '#ffffff66',
                  color: 'white',
                  position: 'absolute',
                  bottom: '40px',
                  transform: 'translateX(-50%)',
                }}
              >
                {demoCurrentSlide} / {demoTotalSlides}
              </Typography>
            </Box>
          </Box>
        </Container>
      )}
    </>
  );
};

export default HandbookModal;
