import { Container } from '@mui/material';
import { Box } from '@mui/system';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Dayjs } from 'dayjs';
import React, { useEffect } from 'react';
import { Line } from 'react-chartjs-2';

import OverallResultHeading from '@/components/overallResult/Heading';
import Timeline from '@/components/overallResult/Timeline';
import useOverallResult from '@/hooks/useOverallResult';
import { authSelector } from '@/services/authSlice';
import { fetchFonts, fontSelector } from '@/services/fontSlice';
import { fetchRankChanges, rankChangesSelector } from '@/services/rankChangesSlice';
import { fetchTimeline, timelineSelector } from '@/services/timelineSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { CourseId, Nullable } from '@/types';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default function RankChanges() {
  const { account } = useAppSelector(authSelector);
  const { rankChanges } = useAppSelector(rankChangesSelector);
  const { fonts } = useAppSelector(fontSelector);
  const { timeline } = useAppSelector(timelineSelector);
  const dispatch = useAppDispatch();

  const {
    targetYearDay,
    targetFontId,
    setTargetYear,
    setTargetFontId,
    setShowChart,
    isStudent,
    chartData,
    chartOptions,
  } = useOverallResult({ account, rankChanges });

  useEffect(() => {
    if (isStudent) {
      dispatch(fetchFonts(CourseId.STUDENT));
    } else {
      dispatch(fetchFonts(CourseId.ADULT));
    }
    dispatch(fetchRankChanges(Number(targetYearDay.year())));
    dispatch(fetchTimeline());
  }, [targetYearDay]);

  const handleChangeYear = (newYear: Nullable<Dayjs>) => {
    if (newYear?.year()) {
      setTargetYear(newYear);
    }
  };

  const handleClickFont = (fontId: number) => {
    setTargetFontId(fontId);
    handleShowImage();
  };

  const handleShowImage = () => {
    setShowChart(false);
  };

  return (
    <Box sx={{ px: [0, 4], py: [4], bgcolor: 'background.paper', maxWidth: 1200, m: 'auto' }}>
      {/* 年月選択 */}
      <Box sx={{ mb: 5, px: [1, 0] }}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
          <DatePicker
            views={['year']}
            value={targetYearDay}
            label="年を選択"
            openTo="year"
            format="YYYY"
            onChange={handleChangeYear}
          />
        </LocalizationProvider>
      </Box>

      <OverallResultHeading
        isStudent={isStudent}
        fontCases={fonts}
        nickname={account.nickname}
        rankChanges={rankChanges}
        targetFontId={targetFontId}
        handleClickFont={handleClickFont}
      />
      <Container
        maxWidth="md"
        sx={{
          px: 0,
          py: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Box sx={{ mb: 2, width: ['95vw', '100%'], height: ['95vw', '500px'] }}>
          <Line data={chartData} options={chartOptions} />
        </Box>
        <Timeline timeline={timeline} targetFontId={targetFontId} />
      </Container>
    </Box>
  );
}
