import { ChartData } from 'chart.js';
import dayjs, { Dayjs } from 'dayjs';
import { useMemo, useState } from 'react';

import { opacityRatio } from '@/const/result';
import { fontList } from '@/const/result';
import {
  RankArchivesInner,
  RankArchivesInnerResultsInner,
} from '@/generated/types/typescript-axios';
import useRanks from '@/hooks/useRanks';
import useTermStatus from '@/hooks/useTermStatus';

import { Userinfo } from '../generated/types/typescript-axios/api';
import { Nullable } from '../types/index';
import { FontTypeIDs } from './../types/assignment';

type Props = {
  account: Userinfo;
  rankChanges: Nullable<RankArchivesInner[]>;
};

export default function useOverallResult({ account, rankChanges }: Props) {
  const { lastReleaseRankDate } = useTermStatus();
  const { adultRankNames, studentRankNames } = useRanks();

  // 学生から一般部に上がる時に過去の成績見ると書体が見れないので直す必要あり
  const birthDate = dayjs(account.birthday);
  const displayRangeBirthday = dayjs().subtract(16, 'year').month(3).date(1).format('YYYY/MM/DD');
  const isStudent = birthDate.isAfter(displayRangeBirthday);

  const rankNameList = isStudent ? studentRankNames.reverse() : adultRankNames.reverse();

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        min: 1,
        max: rankNameList.length,
        ticks: {
          autoSkip: false,
          stepSize: 1,
          callback: (index: number) => rankNameList[index - 1],
        },
        grid: {
          color: (context: any) => {
            return context.index % 3 === 0 ? '#000' : '#fff';
          },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context: any) => rankNameList[context.raw - 1],
        },
      },
    },
  };

  const [targetYearDay, setTargetYear] = useState<Dayjs>(lastReleaseRankDate);

  // 選択書体(デフォルト「すべて」)
  const [targetFontId, setTargetFontId] = useState<number>(0);
  const [displayType, setDisplayType] = useState<number>(1);

  const [showChart, setShowChart] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const isHaveAccountMonth = (i: number) => {
    // TODO 今後、入会日時をapiから取得
    const startCourseMonth = 1;
    const startCourseYear = 2023;
    return targetYearDay.month(i - 1).isSameOrAfter(
      dayjs()
        .year(startCourseYear)
        .month(startCourseMonth - 1)
        .startOf('month')
    );
  };

  const isReleasedRankMonth = (month: number) => {
    return targetYearDay.month(month - 1).isSameOrBefore(lastReleaseRankDate);
  };

  const getEstimateStartRankOrder = (
    i: number,
    targetFontOverallResult: RankArchivesInnerResultsInner[] | undefined
  ) => {
    let startRankOrder = null;
    for (let l = i + 1; l <= 12; l++) {
      // 初月の段級位を後月（2月or3月…）の段級位のpre_rank_orderを参照
      startRankOrder =
        targetFontOverallResult?.find(({ month }) => month === l)?.rank?.pre_rank_order ?? null;
      if (startRankOrder !== null) {
        break;
      }
    }
    return startRankOrder;
  };

  const formatResponseChartData = (
    targetFontOverallResult: RankArchivesInnerResultsInner[] | undefined
  ) => {
    const dataSet: any[] = [];
    for (let i = 1; i <= 12; i++) {
      dataSet.push(
        isHaveAccountMonth(i) && isReleasedRankMonth(i)
          ? targetFontOverallResult?.find(({ month }) => month === i)?.rank?.order ??
              dataSet[dataSet.length - 1] ??
              getEstimateStartRankOrder(i, targetFontOverallResult)
          : null
      );
    }
    return dataSet;
  };

  // targetFontIdが更新された時だけ更新する
  const targetFontOverallResult = useMemo(
    () =>
      rankChanges?.find(({ font }) => font?.id === targetFontId) ??
      rankChanges?.find(({ font }) => font?.id === null),
    [targetFontId]
  );

  const targetColor = useMemo(() => {
    const color = fontList.find(({ fontId }) => fontId === targetFontId)?.color;

    return {
      background: `${color}${opacityRatio}`,
      border: `${color}`,
    };
  }, [targetFontId]);

  const chartLabels = () => {
    if (rankChanges?.length === undefined || rankChanges?.length === 0) {
      return [];
    }

    return ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'];
  };

  // Chartデータの成形
  // https://react-chartjs-2.js.org/
  // https://www.chartjs.org/docs/latest/charts/line.html
  const chartData: ChartData<'line', (number | undefined)[]> = useMemo(() => {
    return targetFontId
      ? {
          // 書体選択時
          labels: chartLabels(),
          datasets: [
            {
              label: targetFontOverallResult?.font?.name,
              data: formatResponseChartData(targetFontOverallResult?.results) ?? [],
              backgroundColor: targetColor.background,
              borderColor: targetColor.border,
            },
          ],
        }
      : {
          // すべて
          labels: chartLabels(),
          datasets:
            rankChanges
              ?.filter(
                (resultItem) =>
                  ![FontTypeIDs.RINSHO].some((fontId) => resultItem?.font?.id == fontId) &&
                  !(resultItem?.font?.id == null && resultItem?.results?.length == 0)
              )
              .map(({ font, results }) => ({
                label: font?.name,
                data: formatResponseChartData(results) ?? [],
                backgroundColor: `${
                  fontList.find(({ fontId }) => fontId === font?.id)?.color
                }${opacityRatio}`,
                borderColor: `${fontList.find(({ fontId }) => fontId === font?.id)?.color}`,
              })) ?? [],
        };
  }, [rankChanges, targetFontId]);

  return {
    targetYearDay,
    targetFontId,
    displayType,
    showChart,
    showModal,
    setTargetYear,
    setTargetFontId,
    setDisplayType,
    setShowChart,
    setShowModal,
    targetFontOverallResult,
    isStudent,
    chartData,
    chartOptions,
  };
}
