import axios from 'axios';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const LOGIN_URL = process.env.REACT_APP_SAASUS_URL ?? '';
const API_ENDPOINT_URL = process.env.REACT_APP_API_ENDPOINT ?? '';

const Callback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const urlParam = new URLSearchParams(location.search);
  const code = urlParam.get('code') as string;

  const fetchAuthCredentials = async () => {
    const res = await axios.get(`${API_ENDPOINT_URL}/callback?code=${code}`, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
      withCredentials: true,
    });
    // 渡ってきたJWTをLocal Storageに保存する
    const idToken = res.data.id_token as string;
    localStorage.setItem('SaaSusIdToken', idToken);
    navigate('/');
  };

  useEffect(() => {
    if (code) {
      fetchAuthCredentials();
    } else {
      window.location.href = LOGIN_URL;
    }
  }, [navigate]);

  return <></>;
};

export default Callback;
