import { ArrowForwardIos } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { Box, Stack } from '@mui/system';
import React from 'react';

import {
  Result,
  ResultAssignmentsInner,
  ResultAssignmentsInnerScoresInner,
} from '@/generated/types/typescript-axios';
import { Font, FontTypeIDs } from '@/types';

import OrderContent from './contents/OrderContent';
import RankContent from './contents/RankContent';
import ScoreContent from './contents/ScoreContent';
import ResultItem from './ResultItem';

type Props = {
  displayType: number;
  result: Result | undefined;
  fonts: Font[] | undefined;
  targetFontResult: ResultAssignmentsInner | undefined;
  isStudent: boolean;
  handleClickItem: (fontId: number) => void;
};

export default function ResultList({
  displayType,
  result,
  fonts,
  targetFontResult,
  isStudent,
  handleClickItem,
}: Props) {
  let ResultScoreRinsho: JSX.Element | null = null;
  return (
    <>
      {/* 点数一覧 */}
      <Grid container spacing={2}>
        {targetFontResult && targetFontResult.scores ? (
          // 書体選択時
          <>
            {targetFontResult.scores.map(
              ({ viewpoint, point }: ResultAssignmentsInnerScoresInner) => (
                <ResultItem key={viewpoint?.id} title={viewpoint?.name} disabled={!point}>
                  {/* <Box sx={{ color: point ? 'initial' : 'grey.400' }}>{viewpoint?.name}</Box> */}
                  <Stack direction="row" sx={{ color: 'grey.400', fontSize: 24 }}>
                    {/* TODO: カラー定義確認後反映させる（green, yellow, red） */}
                    {/* https://mui.com/material-ui/customization/default-theme/ (palette内のカラーを使用することで後でthemeカスタマイズできる) */}
                    <Box sx={{ color: 'success.light' }}>{point}</Box>/ 10
                  </Stack>
                </ResultItem>
              )
            )}
            {targetFontResult.amount && (
              <ResultItem title="合計">
                <Stack direction="row" sx={{ color: 'grey.400', fontSize: [16, 24] }}>
                  {/* TODO: カラー定義確認後反映させる（green, yellow, red） */}
                  {/* https://mui.com/material-ui/customization/default-theme/ (palette内のカラーを使用することで後でthemeカスタマイズできる) */}
                  <Box sx={{ color: 'success.light' }}>{targetFontResult.amount}</Box>/
                  {targetFontResult.scores.length * 10}
                </Stack>
              </ResultItem>
            )}
            {targetFontResult?.rank?.current?.id && (
              <ResultItem title={'段級位'}>
                <RankContent rank={targetFontResult?.rank} />
              </ResultItem>
            )}
            {targetFontResult?.rank_order?.same_rank_order?.order ? (
              <ResultItem title={'順位'}>
                <OrderContent rank_order={targetFontResult?.rank_order} />
              </ResultItem>
            ) : null}
          </>
        ) : (
          // すべての書体
          <>
            {fonts?.map((font: Font, index) => {
              const resultAssignment = result?.assignments?.find(
                (assignment) => assignment?.font?.id === font.id
              );
              // 成績表示でかつ未採点の場合はグレーアウト
              const disabled =
                resultAssignment === undefined || (!resultAssignment.amount && displayType === 1);
              // 臨書は最後に表示したいので変数に入れておく
              if (font?.id === FontTypeIDs.RINSHO && displayType === 1) {
                ResultScoreRinsho = (
                  <ResultItem
                    title={font.name}
                    clickable
                    disabled={!resultAssignment?.comments?.length}
                    handleClickItem={() =>
                      resultAssignment?.comments?.length
                        ? handleClickItem(font?.id as number)
                        : undefined
                    }
                  >
                    {resultAssignment?.comments?.length ? (
                      <ArrowForwardIos sx={{ fontSize: 16 }} />
                    ) : (
                      <Box sx={{ color: 'grey.400', fontWeight: 'normal' }}>未提出</Box>
                    )}
                  </ResultItem>
                );
                return;
              }
              return (
                <React.Fragment key={font?.id}>
                  <ResultItem title={font?.name} disabled={disabled}>
                    {/* 成績 */}
                    {displayType === 1 && (
                      <ScoreContent
                        scores={resultAssignment?.scores}
                        totalScore={resultAssignment?.amount}
                      />
                    )}
                    {/* 段級位 */}
                    {displayType === 2 && <RankContent rank={resultAssignment?.rank} />}
                    {/* 全体順位 */}
                    {displayType === 3 && (
                      <OrderContent rank_order={resultAssignment?.rank_order} />
                    )}
                  </ResultItem>
                  {/* 成績 (臨書) */}
                  {displayType === 1 && index === fonts.length - 1 && ResultScoreRinsho}
                </React.Fragment>
              );
            })}
            {displayType === 2 && !isStudent ? (
              <ResultItem title={'総合'} disabled={!result?.overall_rank?.current?.id}>
                {result?.overall_rank?.current?.id && <RankContent rank={result?.overall_rank} />}
              </ResultItem>
            ) : null}
          </>
        )}
      </Grid>
    </>
  );
}
