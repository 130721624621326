export const radarChartOptions: any = {
  scales: {
    r: {
      display: 'auto',
      max: 10,
      min: 0,
      ticks: {
        stepSize: 2,
      },
    },
  },
  plugins: {
    legend: {
      position: 'bottom',
      labels: {
        boxWidth: 8,
        boxHeight: 8,
        padding: 6,
        textAlign: 'left',
      },
    },
  },
};

// カラー情報をフロントで持つ場合はAPIで取得するfontsとマージする処理をslice内で行う(fulfilledの中でnormalize)
// 書体のカラー情報はデザイナーと要確認
export const opacityRatio = '7A';
export const fontList = [
  {
    fontId: 1,
    color: '#1e88e5',
  },
  {
    fontId: 2,
    color: '#d81b60',
  },
  {
    fontId: 3,
    color: '#fbc02d',
  },
  {
    fontId: 4,
    color: '#388e3c',
  },
  {
    fontId: 5,
    color: '#00a76b',
  },
  {
    fontId: 6,
    color: '#ef6c00',
  },
  {
    fontId: 7,
    color: '#0097a7',
  },
  {
    fontId: 8,
    color: '#8e24aa',
  },
  {
    fontId: 9,
    color: '#6d4c41',
  },
  {
    fontId: 10,
    color: '#1e88e5',
  },
  {
    fontId: 11,
    color: '#d81b60',
  },
  {
    fontId: 12,
    color: '#ef6c00',
  },
];
