import { Dayjs } from 'dayjs';

export enum CourseId {
  ADULT = 1,
  STUDENT = 2,
}

export enum GradeId {
  ADULT = 0, // 一般
  PRE_STUDENT = 1, //幼年
  ES_FIRST = 2, //小学生
  ES_SECOND = 3,
  ES_THIRD = 4,
  ES_FOURTH = 5,
  ES_FIFTH = 6,
  ES_SIXTH = 7,
  JHS_FIRST = 8, //中学生
  JHS_SECOND = 9,
  JHS_THIRD = 10,
}

export type Font = {
  id: number;
  name: string;
  course_type: number;
  grade_id?: number;
};

export type FormFields = {
  month: Dayjs;
};

export type GradeObject = {
  open_key: keyof OpenObject;
  grade_id?: number;
  value: string;
  is_all_registered: boolean;
  fonts_array: {
    name: string;
    is_registered: boolean;
  }[];
};

export type Handbook = {
  id: number;
  month: string;
  font_id: number;
  grade_id: number;
};

// 学生Nested List用
export type OpenObject = {
  pre_student: boolean;
  es_first: boolean; //小学一年生 elementary school
  es_second: boolean;
  es_third: boolean;
  es_fourth: boolean;
  es_fifth: boolean;
  es_sixth: boolean;
  jhs_first: boolean; //中学一年生 junior hight school
  jhs_second: boolean;
  jhs_third: boolean;
  first: boolean;
  second: boolean;
  third: boolean;
};

export type HandbookStudentCaseWithStatus = {
  fonts_array: {
    font_id: number;
    name: string;
    is_registered: boolean;
  }[];
  is_all_registered: boolean;
  open_key: keyof OpenObject;
  value: string;
  grade_id?: number;
};
