import {
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import RegisterContent from '@/components/handbookManagement/RegisterContent';
import Loader from '@/components/Loader';
import TabPanel from '@/components/parts/TabPanel';
import { useToast } from '@/components/ToastProvider';
import { CreateHandbook, Handbook } from '@/generated/types/typescript-axios';
import useFonts from '@/hooks/useFonts';
import useGrade from '@/hooks/useGrade';
import useIsAvailTerm from '@/hooks/useTermStatus';
import { assignmentSelector, clearStatusLabel, getStatusLabel } from '@/services/assignmentSlice';
import { clearHandbooks, clearStatus, deleteHandbook, putHandbook } from '@/services/handbookSlice';
import { fetchHandbooks, handbookSelector, postHandbook } from '@/services/handbookSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { AsyncStatus, URL } from '@/types';

export default function HandbookRegistration() {
  const { getGradeNameById } = useGrade();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();
  const { termList } = useIsAvailTerm();
  const { toast } = useToast();
  const year = Number(params.year);
  const month = Number(params.month);
  const font_id = Number(params.fontId);
  const grade_id = params.gradeId ? Number(params.gradeId) : null;
  const { handbooks, status } = useAppSelector(handbookSelector);
  const { statusLabel, status: assignmentStatus } = useAppSelector(assignmentSelector);
  const [handbook, setHandbook] = useState<Handbook>();

  const [tabValue, setTabValue] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  // 画像
  const [explanationImages, setExplanationImages] = useState<File[]>([]);
  const [demonstrationImages, setDemonstrationImages] = useState<File[]>([]);
  // 動画
  const [explanationVideos, setExplanationVideos] = useState<File[]>([]);
  const [demonstrationVideos, setDemonstrationVideos] = useState<File[]>([]);
  const { control, handleSubmit, setValue, reset } = useForm<CreateHandbook>({
    mode: 'onChange',
    defaultValues: {
      year,
      month,
      font_id,
      grade_id,
      theme: '',
      demonstration: { comment: '', images: [], videos: [] },
      explanation: { comment: '', images: [], videos: [] },
    },
  });
  const fontName = useFonts().fontList.find((font) => font.id === font_id)?.name;

  const loading = useMemo(
    () => [status.bulkFetch, status.delete, status.post, status.put].includes(AsyncStatus.LOADING),
    [status]
  );

  useEffect(() => {
    resetHandbooks();
  }, [params]);

  useEffect(() => {
    if (status.bulkFetch === AsyncStatus.SUCCESS) {
      if (handbooks.length === 1) {
        const handbook = handbooks[0];
        setValue('year', handbook.year);
        setValue('month', handbook.month);
        setValue('font_id', handbook.font.id);
        setValue('grade_id', handbook.grade_id);
        setValue('theme', handbook.theme);
        setValue('demonstration.comment', handbook.demonstration.comment);
        setValue('explanation.comment', handbook.explanation.comment);
        setHandbook(handbook);
      }
    }
  }, [status.bulkFetch]);

  useEffect(() => {
    if (status.post === AsyncStatus.SUCCESS) {
      toast({ message: '手本を登録しました' });
      setHandbook(undefined);
      setExplanationImages([]);
      setDemonstrationImages([]);
      setExplanationVideos([]);
      setDemonstrationVideos([]);
      dispatch(fetchHandbooks({ year, month, font_id, grade_id: grade_id ?? undefined }));
      dispatch(clearStatus());
    }
    if (status.post === AsyncStatus.FAILED) {
      toast({ message: '登録に失敗しました', type: 'error' });
      dispatch(clearStatus());
    }
  }, [status.post]);

  useEffect(() => {
    if (status.put === AsyncStatus.SUCCESS) {
      toast({ message: '手本を更新しました' });
      setHandbook(undefined);
      setExplanationImages([]);
      setDemonstrationImages([]);
      setExplanationVideos([]);
      setDemonstrationVideos([]);
      dispatch(fetchHandbooks({ year, month, font_id, grade_id: grade_id ?? undefined }));
      dispatch(clearStatus());
    }
    if (status.put === AsyncStatus.FAILED) {
      toast({ message: '更新に失敗しました', type: 'error' });
      dispatch(clearStatus());
    }
  }, [status.put]);

  // 手本解説削除
  useEffect(() => {
    if (status.delete === AsyncStatus.SUCCESS) {
      toast({ message: '手本解説を削除しました。' });
      resetState();
      resetHandbooks();
    } else if (status.delete === AsyncStatus.FAILED) {
      toast({ message: '手本解説の削除に失敗しました。', type: 'error' });
    }
  }, [status.delete]);

  const resetHandbooks = () => {
    setHandbook(undefined);
    dispatch(clearHandbooks());
    dispatch(clearStatusLabel());
    dispatch(fetchHandbooks({ year, month, font_id, grade_id: grade_id ?? undefined }));
    dispatch(getStatusLabel({ year, month }));
  };

  const resetState = () => {
    reset();
    setDemonstrationImages([]);
    setDemonstrationVideos([]);
    setExplanationImages([]);
    setExplanationVideos([]);
    dispatch(clearStatus());
  };

  // 画像更新（手本）
  useEffect(() => {
    demonstrationImages.forEach((image, index) => {
      setValue(`demonstration.images.${index}`, image);
    });
  }, [demonstrationImages]);

  // 画像更新（解説）
  useEffect(() => {
    explanationImages.forEach((image, index) => {
      setValue(`explanation.images.${index}`, image);
    });
  }, [explanationImages]);

  // 動画更新（手本）
  useEffect(() => {
    demonstrationVideos.forEach((video, index) => {
      setValue(`demonstration.videos.${index}`, video);
    });
  }, [demonstrationVideos]);

  // 動画更新（解説）
  useEffect(() => {
    explanationVideos.forEach((video, index) => {
      setValue(`explanation.videos.${index}`, video);
    });
  }, [explanationVideos]);

  const handleMenuItemClick = (path: string) => {
    navigate(path);
  };

  const handleChangeTab = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleSubmitForm = ({
    year,
    month,
    font_id,
    grade_id,
    demonstration,
    explanation,
    theme,
  }: CreateHandbook) => {
    if (handbook) {
      // 手本を更新
      dispatch(putHandbook({ id: handbook.id, demonstration, explanation, theme }));
    } else {
      // 手本を新規作成
      dispatch(postHandbook({ year, month, font_id, grade_id, demonstration, explanation, theme }));
    }
  };

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleClickDeleteButton = () => {
    handleDialogClose();
    if (!handbook) return;
    dispatch(deleteHandbook(handbook.id));
  };

  const statusLabelName: string = (termList.filter((term) => term.id === statusLabel)[0] ?? '')
    .name;

  return status.bulkFetch !== AsyncStatus.LOADING &&
    assignmentStatus.fetchStatusLabel !== AsyncStatus.LOADING ? (
    <Box component="form" onSubmit={handleSubmit(handleSubmitForm)} sx={{ p: 2 }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 3, mb: 2 }}>
        <Link
          sx={{ cursor: 'pointer' }}
          underline="hover"
          color="inherit"
          onClick={() => handleMenuItemClick(URL.HANDBOOK_MANAGEMENT)}
        >
          手本解説の一覧
        </Link>
        <Typography color="text.primary">手本解説の登録・更新</Typography>
      </Breadcrumbs>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex' }}>
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 'bold',
              color: '#333',
              mr: 3,
            }}
          >
            {fontName}揮毫 / {fontName} / {getGradeNameById(grade_id)}
          </Typography>
          <Chip label={statusLabelName} variant="outlined" />
          <Tooltip title={!handbook ? '手本が未登録です' : ''} placement="top">
            <Link
              sx={{
                fontSize: '14px',
                color: handbook ? '#1976d2' : '#00000066',
                textDecoration: handbook ? 'underline' : 'none',
                cursor: 'pointer',
                ml: 3,
                mt: 1,
              }}
              color="inherit"
              onClick={() =>
                handbook
                  ? grade_id
                    ? handleMenuItemClick(
                        `/handbook-detail/${year}/${month}/grade/${grade_id}/font/${font_id}`
                      )
                    : handleMenuItemClick(`/handbook-detail/${year}/${month}/adult/font/${font_id}`)
                  : null
              }
            >
              プレビュー
            </Link>
          </Tooltip>
        </Box>
        {!!handbook && (
          <Button variant="contained" color="error" size="small" onClick={handleDialogOpen}>
            削除
          </Button>
        )}
      </Box>
      <Tabs value={tabValue} onChange={handleChangeTab}>
        <Tab label="手本" />
        <Tab label="解説" />
      </Tabs>
      {!loading ? (
        <>
          {/* 手本 */}
          <TabPanel index={0} value={tabValue}>
            <RegisterContent
              type="demonstration"
              control={control}
              imageFiles={demonstrationImages}
              imageUrls={handbook?.demonstration.image_urls ?? []}
              videoFiles={demonstrationVideos}
              videoUrls={handbook?.demonstration.video_urls ?? []}
              setImages={setDemonstrationImages}
              setVideos={setDemonstrationVideos}
            />
          </TabPanel>
          {/* 解説 */}
          <TabPanel index={1} value={tabValue}>
            <RegisterContent
              type="explanation"
              control={control}
              imageFiles={explanationImages}
              imageUrls={handbook?.explanation.image_urls ?? []}
              videoFiles={explanationVideos}
              videoUrls={handbook?.explanation.video_urls ?? []}
              setImages={setExplanationImages}
              setVideos={setExplanationVideos}
            />
          </TabPanel>
          <Grid item xs={12} sx={{ textAlign: 'center', mb: 3 }}>
            <Button variant="contained" type="submit" sx={{ mx: 'auto' }}>
              登録・更新
            </Button>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Button onClick={() => handleMenuItemClick(URL.HANDBOOK_MANAGEMENT)}>
              手本一覧ページにもどる
            </Button>
          </Grid>
        </>
      ) : (
        <Loader />
      )}

      <Dialog open={isDialogOpen}>
        <DialogTitle id="alert-dialog-title">本当に削除しますか？</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`${handbook?.year}年${handbook?.month}月 ${
              handbook?.grade_id ? getGradeNameById(grade_id) : '一般'
            } ${handbook?.font.name}の手本・解説を削除します。`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>キャンセル</Button>
          <Button onClick={handleClickDeleteButton} color="error" autoFocus>
            削除する
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  ) : (
    <Loader />
  );
}
