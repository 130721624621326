// Supported locales: https://github.com/iamkun/dayjs/tree/dev/src/locale
import 'dayjs/locale/ja';

import { Box, Chip, Stack, Tab, Tabs } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AdultHandbookList from '@/components/handbookManagement/AdultHandbookList';
import StudentHandbookList from '@/components/handbookManagement/StudentHandbookList';
import Loader from '@/components/Loader';
import TabPanel from '@/components/parts/TabPanel';
import useHandbookManagement from '@/hooks/useHandbookManagement';
import useIsAvailTerm from '@/hooks/useTermStatus';
import { clearHandbooks } from '@/services/handbookSlice';
import { useAppDispatch } from '@/store/hooks';
import { Nullable, URL } from '@/types';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function HandbookManagement() {
  const now = dayjs();
  const day = dayjs().date();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { year, month } = useParams();
  const { termList } = useIsAvailTerm();

  // 毎月20日になると、次の月がデフォルトの値になる
  const targetYear =
    day >= 20 ? year ?? now.add(1, 'month').format('YYYY') : year ?? now.format('YYYY');
  const targetMonth =
    day >= 20 ? month ?? now.add(1, 'month').format('M') : month ?? now.format('M');
  const {
    activeTab,
    setActiveTab,
    adultCasesWithStatus,
    preStudentCasesWithStatus,
    esStudentCasesWithStatus,
    jhsStudentCasesWithStatus,
    loading,
    statusLabel,
    getStatusLabelLoading,
  } = useHandbookManagement({
    year: Number(targetYear),
    month: Number(targetMonth),
  });

  const statusLabelName: string = (termList.filter((term) => term.id === statusLabel)[0] ?? '')
    .name;

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleChangeYearMonth = (date: Nullable<Dayjs>) => {
    const year = Number(date?.format('YYYY'));
    const month = Number(date?.format('M'));

    if (!!year && !!month) {
      navigate(`${URL.HANDBOOK_MANAGEMENT}/${year}/${month}`);
    }
  };

  const onClickNavigation = (fontId: number, gradeId?: number) => {
    dispatch(clearHandbooks());
    if (gradeId) {
      navigate(
        `${URL.HANDBOOK_MANAGEMENT}/${targetYear}/${targetMonth}/grade/${gradeId}/font/${fontId}`
      );
    } else {
      navigate(`${URL.HANDBOOK_MANAGEMENT}/${targetYear}/${targetMonth}/adult/font/${fontId}`);
    }
  };

  return getStatusLabelLoading ? (
    <Loader />
  ) : (
    <Box sx={{ p: 4 }}>
      <Box sx={{ minWidth: 120 }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
            <DatePicker
              views={['year', 'month']}
              value={dayjs(`${targetYear}-${targetMonth}`)}
              label="年月を選択"
              format="YYYY/MM"
              onChange={handleChangeYearMonth}
            />
          </LocalizationProvider>
          <Chip label={statusLabelName} variant="outlined" />
        </Stack>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', pt: 2 }}>
        <Tabs value={activeTab} onChange={handleChangeTab} aria-label="basic tabs example">
          <Tab label="一般" {...a11yProps(0)} />
          <Tab label="学生" {...a11yProps(1)} />
        </Tabs>
      </Box>
      {/* Adult handbooks */}
      <TabPanel value={activeTab} index={0}>
        <AdultHandbookList
          fontCases={adultCasesWithStatus}
          loading={loading}
          onClickNavigation={onClickNavigation}
        />
      </TabPanel>
      {/* Student handbooks */}
      <TabPanel value={activeTab} index={1}>
        <StudentHandbookList
          preStudentCases={preStudentCasesWithStatus}
          esStudentCases={esStudentCasesWithStatus}
          jhsStudentCases={jhsStudentCasesWithStatus}
          loading={loading}
          onClickNavigation={onClickNavigation}
        />
      </TabPanel>
    </Box>
  );
}
