import { Paper, Table, TableContainer } from '@mui/material';
import React from 'react';

import Loader from '@/components/Loader';
import { userSelector } from '@/services/userSlice';
import { useAppSelector } from '@/store/hooks';
import { AsyncStatus } from '@/types';
import { TabPanelProps } from '@/types';

import TabPanel from './TabPanel';

const TableCase = ({ children, value, index }: TabPanelProps) => {
  const { status } = useAppSelector(userSelector);
  return (
    <TabPanel value={value} index={index}>
      {status.fetch === AsyncStatus.LOADING ? (
        <Loader />
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 1000 }}>{children}</Table>
        </TableContainer>
      )}
    </TabPanel>
  );
};

export default TableCase;
