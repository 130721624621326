import { Box } from '@mui/material';
import React, { ReactNode } from 'react';

const DataItemRow = ({
  title,
  children,
  sub,
}: {
  title: string;
  children: ReactNode;
  sub?: ReactNode;
}) => {
  return (
    <Box key={title} sx={{ display: 'flex', alignItems: sub ? 'start' : 'center', gap: 1 }}>
      <Box sx={{ width: '40%', color: '#666' }}>{title}</Box>
      <Box>
        <Box>{children}</Box>
        {sub ?? <Box> {sub}</Box>}
      </Box>
    </Box>
  );
};

export default DataItemRow;
