import { ChevronLeft } from '@mui/icons-material';
import { Link } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const BackLink = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClickBack = () => {
    navigate(-1);
  };
  return (
    <Link
      underline="none"
      sx={{ display: 'flex', cursor: 'pointer', mb: 2 }}
      onClick={handleClickBack}
    >
      <ChevronLeft />
      {t('pages:whole_result.back')}
    </Link>
  );
};
