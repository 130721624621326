import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  Assignment,
  AssignmentApi,
  AssignmentCount,
  AssignmentUser,
  CreateReview,
  Review,
} from '@/generated/types/typescript-axios';
import { RootState } from '@/store';
import { AsyncStatus } from '@/types';
import { ApiConfig } from '@/utils/apiConfig';

export type AssignmentState = {
  assignment: Assignment | undefined;
  assignments: Assignment[];
  status: {
    bulkFetch: AsyncStatus;
    fetch: AsyncStatus;
    fetchStatusLabel: AsyncStatus;
    create: AsyncStatus;
    update: AsyncStatus;
    delete: AsyncStatus;
    fetchReview: AsyncStatus;
    createReview: AsyncStatus;
  };
  assignmentUsers: AssignmentUser[];
  assignmentCount: AssignmentCount | undefined;
  statusLabel: number | undefined;
  review: Review | undefined;
};

export type CreateAssignment = {
  user_id: string;
  handbook_id: number;
  year: number;
  month: number;
  file: File;
};
export type UpdateAssignment = { id: number; year: number; month: number; file: File };

const initialState: AssignmentState = {
  assignment: undefined,
  assignments: [],
  assignmentUsers: [],
  assignmentCount: undefined,
  statusLabel: undefined,
  review: undefined,
  status: {
    bulkFetch: AsyncStatus.IDLE,
    fetch: AsyncStatus.IDLE,
    fetchStatusLabel: AsyncStatus.IDLE,
    create: AsyncStatus.IDLE,
    update: AsyncStatus.IDLE,
    delete: AsyncStatus.IDLE,
    fetchReview: AsyncStatus.IDLE,
    createReview: AsyncStatus.IDLE,
  },
};

export const fetchAssignment = createAsyncThunk('fetchAssignment', async (id: number, thunkAPI) => {
  try {
    const apiConfig = ApiConfig();
    const assignmentApi = new AssignmentApi(apiConfig);
    const { data } = await assignmentApi.getAssignment(id);
    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const fetchAssignments = createAsyncThunk(
  'fetchAssignments',
  async ({ year, month, userId }: { year: number; month: number; userId: string }, thunkAPI) => {
    try {
      const apiConfig = ApiConfig();
      const assignmentApi = new AssignmentApi(apiConfig);
      const { data } = await assignmentApi.getAssignments(year, month, userId);
      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchAssignmentUsers = createAsyncThunk(
  'fetchAssignmentUsers',
  async (handbookId: number, thunkAPI) => {
    try {
      const apiConfig = ApiConfig();
      const assignmentApi = new AssignmentApi(apiConfig);
      const { data } = await assignmentApi.getAssignmentsUsername(handbookId);
      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const createAssignment = createAsyncThunk(
  'createAssignment',
  async ({ user_id, handbook_id, year, month, file }: CreateAssignment, thunkAPI) => {
    try {
      const apiConfig = ApiConfig();
      const assignmentApi = new AssignmentApi(apiConfig);
      const { data } = await assignmentApi.postAssignment(
        { user_id, handbook_id, year, month, file },
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );
      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const updateAssignment = createAsyncThunk(
  'updateAssignment',
  async ({ id, year, month, file }: UpdateAssignment, thunkAPI) => {
    try {
      const apiConfig = ApiConfig();
      const assignmentApi = new AssignmentApi(apiConfig);
      const { data } = await assignmentApi.updateAssignment(
        id,
        { year, month, file },
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getStatusLabel = createAsyncThunk(
  'getStatusLabel',
  async ({ year, month }: { year: number; month: number }, thunkAPI) => {
    try {
      const apiConfig = ApiConfig();
      const assignmentApi = new AssignmentApi(apiConfig);
      const { data } = await assignmentApi.getAssignmentTermStatus(year, month);
      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const deleteAssignment = createAsyncThunk(
  'deleteAssignment',
  async (id: number, thunkAPI) => {
    try {
      const apiConfig = ApiConfig();
      const assignmentApi = new AssignmentApi(apiConfig);
      await assignmentApi.deleteAssignment(id);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchAssignmentReview = createAsyncThunk(
  'fetchAssignmentReview',
  async (id: number, thunkAPI) => {
    try {
      const apiConfig = ApiConfig();
      const assignmentApi = new AssignmentApi(apiConfig);
      const { data } = await assignmentApi.getAssignmentReview(id);
      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const createAssignmentReview = createAsyncThunk(
  'createAssignmentReview',
  async ({ id, createReview }: { id: number; createReview: CreateReview }, thunkAPI) => {
    try {
      const apiConfig = ApiConfig();
      const assignmentApi = new AssignmentApi(apiConfig);
      const { data } = await assignmentApi.createAssignmentReview(id, createReview);
      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchAssignmentCount = createAsyncThunk(
  'fetchAssignmentCount',
  async ({ year, month }: { year: number; month: number }, thunkAPI) => {
    try {
      const apiConfig = ApiConfig();
      const assignmentApi = new AssignmentApi(apiConfig);
      const { data } = await assignmentApi.getAssignmentCount(year, month);
      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const assignmentSlice = createSlice({
  name: 'Assignment',
  initialState,
  reducers: {
    clearAssignment: (state: AssignmentState) => {
      state.assignment = undefined;
    },
    clearAssignmentUsers: (state: AssignmentState) => {
      state.assignmentUsers = [];
    },
    clearAssignmentCount: (state: AssignmentState) => {
      state.assignmentCount = undefined;
    },
    clearStatus: (state: AssignmentState) => {
      state.status.fetch = AsyncStatus.IDLE;
      state.status.bulkFetch = AsyncStatus.IDLE;
      state.status.create = AsyncStatus.IDLE;
      state.status.update = AsyncStatus.IDLE;
      state.status.delete = AsyncStatus.IDLE;
      state.status.fetchReview = AsyncStatus.IDLE;
      state.status.createReview = AsyncStatus.IDLE;
    },
    clearStatusLabel: (state: AssignmentState) => {
      state.assignment = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAssignment.pending, (state) => {
        state.status.fetch = AsyncStatus.LOADING;
      })
      .addCase(fetchAssignment.fulfilled, (state, { payload }: { payload: Assignment }) => {
        state.assignment = payload;
        state.status.fetch = AsyncStatus.SUCCESS;
      })
      .addCase(fetchAssignment.rejected, (state) => {
        state.status.fetch = AsyncStatus.FAILED;
      })
      .addCase(fetchAssignments.pending, (state) => {
        state.status.bulkFetch = AsyncStatus.LOADING;
      })
      .addCase(fetchAssignments.fulfilled, (state, { payload }: { payload: Assignment[] }) => {
        state.assignments = payload;
        state.status.bulkFetch = AsyncStatus.SUCCESS;
      })
      .addCase(fetchAssignments.rejected, (state) => {
        state.status.bulkFetch = AsyncStatus.FAILED;
      })
      .addCase(fetchAssignmentCount.pending, (state) => {
        state.status.bulkFetch = AsyncStatus.LOADING;
      })
      .addCase(
        fetchAssignmentCount.fulfilled,
        (state, { payload }: { payload: AssignmentCount }) => {
          state.assignmentCount = payload;
          state.status.bulkFetch = AsyncStatus.SUCCESS;
        }
      )
      .addCase(fetchAssignmentCount.rejected, (state) => {
        state.status.bulkFetch = AsyncStatus.FAILED;
      })
      .addCase(fetchAssignmentUsers.pending, (state) => {
        state.status.bulkFetch = AsyncStatus.LOADING;
      })
      .addCase(
        fetchAssignmentUsers.fulfilled,
        (state, { payload }: { payload: AssignmentUser[] }) => {
          state.assignmentUsers = payload;
          state.status.bulkFetch = AsyncStatus.SUCCESS;
        }
      )
      .addCase(fetchAssignmentUsers.rejected, (state) => {
        state.status.bulkFetch = AsyncStatus.FAILED;
      })
      .addCase(createAssignment.pending, (state) => {
        state.status.create = AsyncStatus.LOADING;
      })
      .addCase(createAssignment.fulfilled, (state, { payload }: { payload: Assignment }) => {
        state.assignment = payload;
        state.status.create = AsyncStatus.SUCCESS;
      })
      .addCase(createAssignment.rejected, (state) => {
        state.status.create = AsyncStatus.FAILED;
      })
      .addCase(updateAssignment.pending, (state) => {
        state.status.update = AsyncStatus.LOADING;
      })
      .addCase(updateAssignment.fulfilled, (state, { payload }: { payload: Assignment }) => {
        state.assignment = payload;
        state.status.update = AsyncStatus.SUCCESS;
      })
      .addCase(updateAssignment.rejected, (state) => {
        state.status.update = AsyncStatus.FAILED;
      })
      .addCase(getStatusLabel.pending, (state) => {
        state.status.fetchStatusLabel = AsyncStatus.LOADING;
      })
      .addCase(getStatusLabel.fulfilled, (state, { payload }: { payload: number | undefined }) => {
        state.statusLabel = payload;
        state.status.fetchStatusLabel = AsyncStatus.SUCCESS;
      })
      .addCase(getStatusLabel.rejected, (state) => {
        state.status.fetchStatusLabel = AsyncStatus.FAILED;
      })
      .addCase(deleteAssignment.pending, (state) => {
        state.status.delete = AsyncStatus.LOADING;
      })
      .addCase(deleteAssignment.fulfilled, (state) => {
        state.status.delete = AsyncStatus.SUCCESS;
      })
      .addCase(deleteAssignment.rejected, (state) => {
        state.status.delete = AsyncStatus.FAILED;
      })
      .addCase(fetchAssignmentReview.pending, (state) => {
        state.review = undefined;
        state.status.fetchReview = AsyncStatus.LOADING;
      })
      .addCase(fetchAssignmentReview.fulfilled, (state, { payload }) => {
        state.review = payload;
        state.status.fetchReview = AsyncStatus.SUCCESS;
      })
      .addCase(fetchAssignmentReview.rejected, (state) => {
        state.status.fetchReview = AsyncStatus.FAILED;
      })
      .addCase(createAssignmentReview.pending, (state) => {
        state.status.createReview = AsyncStatus.LOADING;
      })
      .addCase(createAssignmentReview.fulfilled, (state, { payload }) => {
        state.review = payload;
        state.status.createReview = AsyncStatus.SUCCESS;
      })
      .addCase(createAssignmentReview.rejected, (state) => {
        state.status.createReview = AsyncStatus.FAILED;
      });
  },
});

export const {
  clearAssignment,
  clearAssignmentUsers,
  clearAssignmentCount,
  clearStatus,
  clearStatusLabel,
} = assignmentSlice.actions;
export const assignmentSelector = (state: RootState) => state.assignment;
export default assignmentSlice.reducer;
