import { Button, Modal } from '@mui/material';
import { Box } from '@mui/system';
import {
  Chart as ChartJS,
  ChartData,
  Filler,
  Legend,
  LineElement,
  PointElement,
  RadialLinearScale,
  Tooltip,
} from 'chart.js';
import React, { useMemo, useState } from 'react';
import { Radar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import { fontList, opacityRatio, radarChartOptions } from '@/const/result';
import { OverallResult, ResultsItemsInner } from '@/generated/types/typescript-axios/api';
import { FontTypeIDs } from '@/types';

type Props = {
  item: ResultsItemsInner & OverallResult;
};

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 800,
  bgcolor: 'background.paper',
  border: '1px solid #aaa',
  boxShadow: 24,
  p: 1,
  textAlign: 'center',
  width: '95vw',
};

export default function Chart({ item }: Props) {
  const { t } = useTranslation();
  const [showChart, setShowChart] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const targetColor = useMemo(() => {
    const color = fontList.find((font) => font.fontId === item?.font?.id)?.color;

    return {
      background: `${color}${opacityRatio}`,
      border: `${color}`,
    };
  }, [item]);

  // Chartデータの成形
  // https://react-chartjs-2.js.org/
  // https://www.chartjs.org/docs/latest/charts/radar.html
  const chartData: ChartData<'radar', (number | undefined)[]> = useMemo(() => {
    return item.scores
      ? {
          // 書体選択時
          labels: item.scores?.map(({ viewpoint }) => viewpoint?.name ?? '') ?? [],
          datasets: [
            {
              label: item?.font?.name,
              data: item.scores?.map(({ point }) => point) ?? [],
              backgroundColor: targetColor.background,
              borderColor: targetColor.border,
            },
          ],
        }
      : {
          // 総合選択時
          labels: [
            t('common:viewpoints:jikei'),
            t('common:viewpoints:haichi'),
            t('common:viewpoints:senshitsu'),
            t('common:viewpoints:sumiryo'),
            t('common:viewpoints:hyogen'),
          ],
          datasets:
            item?.results
              ?.filter((result) => result?.font?.id)
              .map(({ font, scores }) => ({
                label: font?.name,
                data: scores?.map(({ point }) => point) ?? [],
                backgroundColor: 'transparent',
                borderWidth: 1,
                pointStyle: false,
                borderColor: `${fontList.find(({ fontId }) => fontId === font?.id)?.color}`,
              })) ?? [],
        };
  }, [item]);

  const handleClickShowChartSwitcher = () => {
    setShowChart(!showChart);
  };

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        height: ['90vw', '50vw'],
        maxHeight: 350,
        width: '100%',
      }}
    >
      {item?.font && item?.font?.id != FontTypeIDs.RINSHO ? (
        <Button
          variant="outlined"
          size="small"
          sx={{
            position: 'absolute',
            top: 8,
            left: 8,
            bgcolor: '#f7fafa',
            borderColor: '#0000003d',
            borderRadius: 8,
            color: '#000000de',
            zIndex: 1,
            '&:hover': {
              borderColor: '#0000003d',
              color: '#000000de',
              bgcolor: '#f7fafa',
            },
          }}
          onClick={handleClickShowChartSwitcher}
        >
          {showChart ? t('pages:whole_result.showImage') : t('pages:whole_result.showChart')}
        </Button>
      ) : null}
      {item.results || (item.font?.id != FontTypeIDs.RINSHO && showChart) ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          <Radar
            data={chartData}
            options={{
              ...radarChartOptions,
              plugins: {
                legend: {
                  ...radarChartOptions.plugins.legend,
                  display: !item.results ? false : true,
                },
              },
            }}
            width="100%"
          />
        </Box>
      ) : (
        <Box sx={{ flex: 1, textAlign: 'center' }}>
          <img
            src={item.image_url}
            style={{ margin: 'auto', maxHeight: '320px', maxWidth: '100%' }}
          />
          <Button
            variant="outlined"
            size="small"
            sx={{
              position: 'absolute',
              bottom: 8,
              right: 8,
              bgcolor: '#f7fafa ',
              borderColor: '#0000003d',
              borderRadius: 8,
              color: '#000000de',
              '&:hover': {
                borderColor: '#0000003d',
                color: '#000000de',
                bgcolor: '#f7fafa',
              },
            }}
            onClick={handleOpenModal}
          >
            {t('pages:whole_result.expand')}
          </Button>
        </Box>
      )}

      <Modal open={showModal} onClose={handleCloseModal}>
        <Box sx={modalStyle}>
          <img
            src={item.image_url}
            style={{
              maxWidth: '100%',
              maxHeight: '640px',
            }}
          />
        </Box>
      </Modal>
    </Box>
  );
}
