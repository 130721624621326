import { Box, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React from 'react';
import { Control, Controller } from 'react-hook-form';

import { Viewpoint } from '@/generated/types/typescript-axios';

type ScoreInputProps = {
  viewpoints: Viewpoint[];
  scoreFields: string[];
  control: Control;
  handleChangePoint: () => void;
};

const ScoreInput: React.FC<ScoreInputProps> = ({
  viewpoints,
  scoreFields,
  control,
  handleChangePoint,
}) => {
  return (
    <>
      {scoreFields.map((scoreField, i) => (
        <Box key={i} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6" sx={{ minWidth: 160 }}>
            {viewpoints[i]?.name}
          </Typography>
          <Controller
            name={scoreField}
            control={control}
            render={({ field }) => (
              <ToggleButtonGroup
                {...field}
                exclusive
                fullWidth
                sx={{ maxWidth: 400 }}
                onChange={(event) => {
                  field.onChange(event);
                  handleChangePoint();
                }}
              >
                {[...Array(10)].map((_, j) => (
                  <ToggleButton key={j} value={String(j + 1)}>
                    {j + 1}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            )}
          />
        </Box>
      ))}
    </>
  );
};

export default ScoreInput;
