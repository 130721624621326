import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Container } from '@mui/material';
import { ListItem, ListItemText } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';

import Loader from '@/components/Loader';
import { Assignment, AssignmentUser } from '@/generated/types/typescript-axios';

type Props = {
  loading: boolean;
  item: Assignment | undefined;
  user: AssignmentUser | undefined;
};

export default function ItemDetails({ item, user, loading }: Props) {
  return loading ? (
    <Loader />
  ) : (
    <Container maxWidth="lg">
      <ListItem disablePadding>
        <AccountCircleIcon fontSize="small" sx={{ color: grey[600] }} />
        <ListItemText primary={`${user?.nickname ?? ''}`} sx={{ pl: 1 }} />
      </ListItem>
      <img
        src={item?.image_url}
        alt=""
        className="tw-object-contain tw-w-full tw-my-2 tw-max-h-[640px]"
      />
    </Container>
  );
}
