import { useTranslation } from 'react-i18next';

export default function useFonts() {
  const { t } = useTranslation();

  const fontList = [
    { id: 1, name: t('common:fonts:kaisho'), course_type: 1 },
    { id: 2, name: t('common:fonts:gyosho'), course_type: 1 },
    { id: 3, name: t('common:fonts:sosho'), course_type: 1 },
    { id: 4, name: t('common:fonts:reisho'), course_type: 1 },
    { id: 5, name: t('common:fonts:rinsho'), course_type: 1 },
    { id: 6, name: t('common:fonts:jofuku'), course_type: 1 },
    { id: 7, name: t('common:fonts:kana'), course_type: 1 },
    { id: 8, name: t('common:fonts:penji'), course_type: 1 },
    { id: 9, name: t('common:fonts:jitsuyosyo'), course_type: 1 },
    { id: 10, name: t('common:fonts:kohitsu'), course_type: 2 },
    { id: 11, name: t('common:fonts:hanshi'), course_type: 2 },
    { id: 12, name: t('common:fonts:jofuku'), course_type: 2 },
  ];

  return { fontList };
}
