import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { User, UsersApi, UserWithRanks } from '@/generated/types/typescript-axios';
import { RootState } from '@/store';
import { AsyncStatus } from '@/types';
import { ApiConfig } from '@/utils/apiConfig';

type UserState = {
  users: User[];
  userWithRanks: UserWithRanks[];
  status: {
    fetch: AsyncStatus;
    create: AsyncStatus;
    update: AsyncStatus;
    trial: AsyncStatus;
    ranks: AsyncStatus;
  };
};

const initialState: UserState = {
  users: [],
  userWithRanks: [],
  status: {
    fetch: AsyncStatus.IDLE,
    create: AsyncStatus.IDLE,
    update: AsyncStatus.IDLE,
    trial: AsyncStatus.IDLE,
    ranks: AsyncStatus.IDLE,
  },
};

export const fetchUsers = createAsyncThunk('fetchUsers', async (_, thunkAPI) => {
  try {
    const apiConfig = ApiConfig();
    const usersApi = new UsersApi(apiConfig);
    const { data } = await usersApi.getUsers();
    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const createUser = createAsyncThunk('createUser', async (user: User, thunkAPI) => {
  try {
    const apiConfig = ApiConfig();
    const usersApi = new UsersApi(apiConfig);
    await usersApi.createUser(user);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updateUser = createAsyncThunk('updateUser', async (user: User, thunkAPI) => {
  try {
    const apiConfig = ApiConfig();
    const usersApi = new UsersApi(apiConfig);
    await usersApi.updateUser(user.id, user);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const startTrial = createAsyncThunk('startTrial', async (_, thunkAPI) => {
  try {
    const apiConfig = ApiConfig();
    const usersApi = new UsersApi(apiConfig);
    await usersApi.startTrial();
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const userRanks = createAsyncThunk('userWithRanks', async (courseType: number, thunkAPI) => {
  try {
    const apiConfig = ApiConfig();
    const usersApi = new UsersApi(apiConfig);
    const { data } = await usersApi.getUsersWithRanks(courseType);
    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearStatus: (state: UserState) => {
      state.status.fetch = AsyncStatus.IDLE;
      state.status.update = AsyncStatus.IDLE;
      state.status.create = AsyncStatus.IDLE;
      state.status.trial = AsyncStatus.IDLE;
      state.status.ranks = AsyncStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status.fetch = AsyncStatus.LOADING;
      })
      .addCase(fetchUsers.fulfilled, (state, { payload }: { payload: User[] }) => {
        state.users = payload;
        state.status.fetch = AsyncStatus.SUCCESS;
      })
      .addCase(fetchUsers.rejected, (state) => {
        state.status.fetch = AsyncStatus.FAILED;
      })
      .addCase(createUser.pending, (state) => {
        state.status.create = AsyncStatus.LOADING;
      })
      .addCase(createUser.fulfilled, (state) => {
        state.status.create = AsyncStatus.SUCCESS;
      })
      .addCase(createUser.rejected, (state) => {
        state.status.create = AsyncStatus.FAILED;
      })
      .addCase(updateUser.pending, (state) => {
        state.status.update = AsyncStatus.LOADING;
      })
      .addCase(updateUser.fulfilled, (state) => {
        state.status.update = AsyncStatus.SUCCESS;
      })
      .addCase(updateUser.rejected, (state) => {
        state.status.update = AsyncStatus.FAILED;
      })
      .addCase(startTrial.pending, (state) => {
        state.status.trial = AsyncStatus.LOADING;
      })
      .addCase(startTrial.fulfilled, (state) => {
        state.status.trial = AsyncStatus.SUCCESS;
      })
      .addCase(startTrial.rejected, (state) => {
        state.status.trial = AsyncStatus.FAILED;
      })
      .addCase(userRanks.pending, (state) => {
        state.status.ranks = AsyncStatus.LOADING;
      })
      .addCase(userRanks.fulfilled, (state, { payload }: { payload: UserWithRanks[] }) => {
        state.userWithRanks = payload;
        state.status.fetch = AsyncStatus.SUCCESS;
      })
      .addCase(userRanks.rejected, (state) => {
        state.status.ranks = AsyncStatus.FAILED;
      });
  },
});

export const { clearStatus } = userSlice.actions;

export const userSelector = (state: RootState) => state.user;

export default userSlice.reducer;
