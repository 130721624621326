import { GradeId } from '@/types';
import { GradeObject } from '@/types';

export const preStudentCases: GradeObject[] = [
  {
    open_key: 'pre_student',
    grade_id: GradeId.PRE_STUDENT,
    value: '幼年',
    is_all_registered: false,
    fonts_array: [],
  },
];

export const esStudentCases: GradeObject[] = [
  {
    open_key: 'es_first',
    grade_id: GradeId.ES_FIRST,
    value: '1年生',
    is_all_registered: false,
    fonts_array: [],
  },
  {
    open_key: 'es_second',
    grade_id: GradeId.ES_SECOND,
    value: '2年生',
    is_all_registered: false,
    fonts_array: [],
  },
  {
    open_key: 'es_third',
    grade_id: GradeId.ES_THIRD,
    value: '3年生',
    is_all_registered: false,
    fonts_array: [],
  },
  {
    open_key: 'es_fourth',
    grade_id: GradeId.ES_FOURTH,
    value: '4年生',
    is_all_registered: false,
    fonts_array: [],
  },
  {
    open_key: 'es_fifth',
    grade_id: GradeId.ES_FIFTH,
    value: '5年生',
    is_all_registered: false,
    fonts_array: [],
  },
  {
    open_key: 'es_sixth',
    grade_id: GradeId.ES_SIXTH,
    value: '6年生',
    is_all_registered: false,
    fonts_array: [],
  },
];

export const jhsStudentCases: GradeObject[] = [
  {
    open_key: 'jhs_first',
    grade_id: GradeId.JHS_FIRST,
    value: '1年生',
    is_all_registered: true,
    fonts_array: [],
  },
  {
    open_key: 'jhs_second',
    grade_id: GradeId.JHS_SECOND,
    value: '2年生',
    is_all_registered: false,
    fonts_array: [],
  },
  {
    open_key: 'jhs_third',
    grade_id: GradeId.JHS_THIRD,
    value: '3年生',
    is_all_registered: false,
    fonts_array: [],
  },
];
