// 学生Nested List用
type OpenObject = {
  pre_student: boolean;
  es_first: boolean; //小学一年生 elementary school
  es_second: boolean;
  es_third: boolean;
  es_fourth: boolean;
  es_fifth: boolean;
  es_sixth: boolean;
  jhs_first: boolean; //中学一年生 junior hight school
  jhs_second: boolean;
  jhs_third: boolean;
  first: boolean;
  second: boolean;
  third: boolean;
};

export type AssignmentStudentCaseWithStatus = {
  fonts_array: FontCase[];
  //   all_ungraded_num: boolean; 今後未採点者の数実装
  open_key: keyof OpenObject;
  value: string;
  grade_id?: number;
};

export type AssignmentFont = {
  id: number;
  name: string;
};

export type AssignmentStudentFont = {
  font_id: number;
  name: string;
};

export type FontCase = {
  id: number;
  name: string;
  handbook_id: number[];
  font_submitter_count: number;
  // ungraded_num: number; 今後未採点者の数実装
};

export enum FontTypeIDs {
  KAISHO = 1,
  GYOSHO = 2,
  SOSHO = 3,
  REISHO = 4,
  RINSHO = 5,
  JOFUKU_ADULT = 6,
  KANA = 7,
  PENJI = 8,
  JITSUYOSYO = 9,
  KOHITSU = 10,
  HANSHI = 11,
  JOFUKU_CHILD = 12,
}
