import dayjs from 'dayjs';

const isStudentAtDate = (birthday: string): boolean => {
  const today = dayjs();
  const birthDate = dayjs(birthday);
  const displayRangeBirthday = today.subtract(16, 'year').month(3).date(1).format('YYYY/MM/DD');

  return birthDate.isAfter(displayRangeBirthday);
};

export default isStudentAtDate;
