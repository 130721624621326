import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { classroomSelector, fetchClassrooms } from '@/services/classroomSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

const classroomTableHeaderLabels = [
  '項番',
  '教室名',
  '代表先生氏名 (雅号)',
  '代表先生メールアドレス',
  '生徒属性',
];

const TableData = ({ label, width = 50 }: { label: string; width?: number }) => (
  <Box
    sx={{
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width,
    }}
  >
    {label}
  </Box>
);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: grey[900],
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function ClassroomManagement() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchClassrooms());
  }, [dispatch]);

  const { classrooms } = useAppSelector(classroomSelector);

  const onClickNavigation = () => navigate('/management/classroom/registration');

  return (
    <Box sx={{ p: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pb: 3 }}>
        <Typography variant="h5">支部管理</Typography>
        <Box>
          <Button
            variant="outlined"
            onClick={onClickNavigation}
            sx={{
              border: 1,
              borderColor: 'grey.400',
              color: 'initial',
              fontWeight: 'bold',
            }}
          >
            教室新規登録
          </Button>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 1000 }}>
          <TableHead>
            <TableRow>
              {classroomTableHeaderLabels.map((label) => (
                <StyledTableCell key={label}>{label}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {classrooms.map((row, index) => (
              <StyledTableRow key={row.teacher_email}>
                <StyledTableCell component="th" scope="row">
                  <TableData label={`${index + 1}`} />
                </StyledTableCell>
                <StyledTableCell>
                  <TableData label={row.name} width={200} />
                </StyledTableCell>
                <StyledTableCell>
                  <TableData label={row.teacher_name} width={200} />
                </StyledTableCell>
                <StyledTableCell>
                  <TableData label={row.teacher_email} width={200} />
                </StyledTableCell>
                <StyledTableCell>
                  <TableData label={row.student_type} width={140} />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
