import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { authSelector } from '@/services/authSlice';
import { useAppSelector } from '@/store/hooks';
import { URL } from '@/types';

export default function RequireSignUp() {
  const { account } = useAppSelector(authSelector);
  const { state } = useLocation();

  if (!account?.completed_sign_up) {
    return <Navigate to={URL.SIGN_UP_REGISTRATION} />;
  }

  // 未契約であればプラン選択画面へ (手渡しの場合のフラグがあるので2つで判定)
  if (!account.is_subscribed && !account.subscription_status) {
    return <Navigate to={URL.PLAN} state={state} />;
  }

  return <Outlet />;
}
