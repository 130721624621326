import CelebrationIcon from '@mui/icons-material/Celebration';
import { Container, Link } from '@mui/material';
import { Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';

import { LatestResults } from '@/generated/types/typescript-axios';
import { URL } from '@/types';

type Props = {
  timeline: LatestResults | undefined;
  targetFontId: number;
};

export default function Timeline({ timeline, targetFontId }: Props) {
  const targetFontAssignment = timeline?.assignments?.find(
    ({ font_id }) => font_id == targetFontId
  );
  return targetFontId === 0 ? (
    <>
      {/* すべて */}
      {timeline?.assignments?.map(({ image_url }, index) => (
        <Container
          key={index}
          sx={{
            p: 0,
            textAlign: 'left',
            borderTop: index !== 0 ? `2px solid ${grey[200]}` : 'none',
          }}
        >
          {index === 0 ? (
            <Box fontWeight="bold" sx={{ my: 4, ml: [2, 0] }}>
              成績確定済みの課題
            </Box>
          ) : null}
          <Box sx={{ display: 'flex', alignItems: 'center', my: 2, ml: [2, 0] }}>
            <Box
              sx={{
                borderRadius: '50%',
                p: 1,
                borderWidth: 2,
                borderColor: grey[200],
                borderStyle: 'solid',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CelebrationIcon />
            </Box>
            <Box component="span" sx={{ ml: 1, fontSize: 13 }}>
              {timeline.year}年{timeline.month}月
            </Box>
          </Box>
          <img width={'100%'} src={image_url} />
          <Link
            underline="none"
            sx={{ textAlign: 'center', display: 'block', mt: 2, mb: 3 }}
            href={URL.RESULT}
          >
            詳細を見る
          </Link>
        </Container>
      ))}
    </>
  ) : (
    <>
      {/* 各書体 */}
      {targetFontAssignment ? (
        <Container sx={{ p: 0, textAlign: 'left' }}>
          <Box fontWeight="bold" sx={{ my: 4, ml: [2, 0] }}>
            成績確定済みの課題
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', my: 2, ml: [2, 0] }}>
            <Box
              sx={{
                borderRadius: '50%',
                p: 1,
                borderWidth: 2,
                borderColor: grey[200],
                borderStyle: 'solid',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CelebrationIcon />
            </Box>{' '}
            <Box component="span" sx={{ ml: 1, fontSize: 13 }}>
              {timeline?.year}年{timeline?.month}月
            </Box>
          </Box>
          <img width={'100%'} src={targetFontAssignment.image_url} />
          <Link
            underline="none"
            sx={{ textAlign: 'center', display: 'block', mt: 2 }}
            href={URL.RESULT}
          >
            詳細を見る
          </Link>
        </Container>
      ) : null}
    </>
  );
}
