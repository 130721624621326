import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ClientSecret, PaymentMethodApi } from '@/generated/types/typescript-axios';
import { RootState } from '@/store';
import { AsyncStatus } from '@/types';
import { ApiConfig } from '@/utils/apiConfig';

type PaymentMethodState = {
  clientSecret: ClientSecret | null;
  status: {
    update: AsyncStatus;
    fetch: AsyncStatus;
  };
};

const initialState: PaymentMethodState = {
  clientSecret: null,
  status: {
    update: AsyncStatus.IDLE,
    fetch: AsyncStatus.IDLE,
  },
};

export const fetchClientSecret = createAsyncThunk('fetchClientSecret', async (_, thunkAPI) => {
  try {
    const apiConfig = ApiConfig();
    const paymentMethodApi = new PaymentMethodApi(apiConfig);
    const { data } = await paymentMethodApi.getClientSecret();
    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updatePaymentMethod = createAsyncThunk(
  'updatePaymentMethod',
  async (payment_method_id: string, thunkAPI) => {
    try {
      const apiConfig = ApiConfig();
      const paymentMethodApi = new PaymentMethodApi(apiConfig);
      await paymentMethodApi.updatePaymentMethod({ payment_method_id });
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const paymentMethodSlice = createSlice({
  name: 'setupIntent',
  initialState,
  reducers: {
    clearStatus: (state: PaymentMethodState) => {
      state.status.fetch = AsyncStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientSecret.pending, (state) => {
        state.status.fetch = AsyncStatus.LOADING;
      })
      .addCase(fetchClientSecret.fulfilled, (state, { payload }: { payload: ClientSecret }) => {
        state.clientSecret = payload;
        state.status.fetch = AsyncStatus.SUCCESS;
      })
      .addCase(fetchClientSecret.rejected, (state) => {
        state.status.fetch = AsyncStatus.FAILED;
      })
      .addCase(updatePaymentMethod.pending, (state) => {
        state.status.update = AsyncStatus.LOADING;
      })
      .addCase(updatePaymentMethod.fulfilled, (state) => {
        state.status.update = AsyncStatus.SUCCESS;
      })
      .addCase(updatePaymentMethod.rejected, (state) => {
        state.status.update = AsyncStatus.FAILED;
      });
  },
});

export const { clearStatus } = paymentMethodSlice.actions;

export const paymentMethodSelector = (state: RootState) => state.paymentMethod;

export default paymentMethodSlice.reducer;
