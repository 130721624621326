import { Circle, DeleteOutline, RestartAlt } from '@mui/icons-material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Box, Button, Chip, InputLabel, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { ChangeEvent, DragEvent, useEffect, useRef, useState } from 'react';

import { useToast } from '@/components/ToastProvider';
import { AssignmentImage } from '@/pages/Assignment';
import { Nullable } from '@/types';
import { Font } from '@/types';

type Props = {
  font: Font;
  imageUrl: Nullable<string>;
  handleChangeImage: (image: AssignmentImage) => void;
  handleDialogOpen: (font: Font) => void;
};

export const AssignmentUpload = ({
  font,
  imageUrl,
  handleChangeImage,
  handleDialogOpen,
}: Props) => {
  const { id: fontId, name: fontName } = font;
  const inputRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<File>();
  const [imageSrc, setImageSrc] = useState<string>();
  const { toast } = useToast();
  const [isDroppable, setIsDroppable] = useState<boolean>(false);

  useEffect(() => {
    setImageSrc(imageUrl ?? '');
    setImage(undefined);
  }, [imageUrl]);

  const updateImage = (file: File) => {
    setImage(file);
    handleChangeImage({ fontId, file });
  };

  const handleSelectImage = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.item(0);
    if (!file) return;
    updateImage(file);
  };

  const cancelDragEvent = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragOver = (e: DragEvent) => {
    cancelDragEvent(e);
  };

  const handleDrop = (e: DragEvent) => {
    cancelDragEvent(e);
    const inputFiles = e.dataTransfer.files;

    if (!['image/png', 'image/jpg', 'image/jpeg'].includes(inputFiles[0].type)) {
      toast({
        message: 'pngまたはjpg形式の画像を指定してください',
        type: 'error',
      });
      return;
    }

    const file = inputFiles.item(0);
    if (!file) return;
    updateImage(file);
    setIsDroppable(false);
  };

  const handleDragEnter = () => {
    setIsDroppable(true);
  };

  const handleDragLeave = () => {
    setIsDroppable(false);
  };

  const handleClickReSelectImage = () => {
    inputRef.current?.click();
  };

  const PreviewContainer = ({ url }: { url: string | undefined }) => {
    return url ? (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: 180,
        }}
      >
        <img
          src={url}
          style={{
            maxWidth: '100%',
            maxHeight: '180px',
          }}
        />
      </Box>
    ) : null;
  };

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Circle fontSize="small" />
          <Typography variant="h6">{fontName}</Typography>
          {imageSrc && <Chip label="提出済み" color="success" sx={{ ml: 1, mb: 0.1 }} />}
        </Box>
        {(image || imageSrc) && (
          <Button sx={{ p: 0, fontSize: 16, ml: 1 }} onClick={() => handleClickReSelectImage()}>
            <RestartAlt />
            選び直す
          </Button>
        )}
      </Stack>
      <Box
        sx={{
          borderStyle: 'solid',
          borderColor: isDroppable ? grey[500] : grey[300],
          backgroundColor: isDroppable ? grey[400] : grey[200],
          borderRadius: 1,
          marginBottom: 1,
          marginTop: 1,
        }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            position: 'relative',
            textAlign: 'center',
            fontSize: [12, 14],
            height: 180,
            px: 1,
          }}
        >
          <input
            id={`imageInput${fontId}`}
            type="file"
            accept=".png,.jpg"
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleSelectImage(e)}
            style={{ display: 'none' }}
            ref={inputRef}
          />
          <InputLabel
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              background: 'transparent',
            }}
            htmlFor={`imageInput${fontId}`}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
          />
          <Box>
            {image ? (
              <PreviewContainer url={URL.createObjectURL(image)} />
            ) : imageSrc ? (
              <PreviewContainer url={imageSrc} />
            ) : (
              <Box>
                <FileUploadIcon fontSize="large" sx={{ zIndex: 1 }} />
                <br />
                ここにファイルをドラッグアンドドロップするか
                <br />
                クリックしてファイルを選択してください
              </Box>
            )}
          </Box>
        </Stack>
      </Box>
      {imageSrc && (
        <Box sx={{ display: 'flex', justifyContent: 'right' }}>
          <Button
            size="small"
            color="error"
            sx={{ p: 0, fontSize: 14 }}
            onClick={() => {
              handleDialogOpen(font);
            }}
          >
            <DeleteOutline />
            削除する
          </Button>
        </Box>
      )}
    </>
  );
};
