import { determinePeriod } from '@/utils/assignment';

const { year, month } = determinePeriod();

interface Pages {
  [key: string]: {
    deadlineAlert?: boolean;
    title: string;
    isDisplayTrial: boolean;
  };
}

export const Pages: Pages = {
  '/callback': {
    title: '',
    isDisplayTrial: false,
  },
  '/account/registration': {
    title: 'ユーザー登録',
    isDisplayTrial: false,
  },
  '/account/confirmation': {
    title: '',
    isDisplayTrial: false,
  },
  '/my-page': {
    title: '',
    isDisplayTrial: false,
  },
  '/handbooks': {
    deadlineAlert: true,
    title: '月別 競書課題',
    isDisplayTrial: true,
  },
  '/assignment': {
    title: `${year}年${month}月 競書 課題提出`,
    isDisplayTrial: true,
  },
  '/result': {
    title: '競書課題評価',
    isDisplayTrial: false,
  },
  '/whole-result': {
    title: '全体成績',
    isDisplayTrial: false,
  },
  '/overall-result': {
    title: '総合評価',
    isDisplayTrial: false,
  },
  '/management/user': {
    title: '',
    isDisplayTrial: false,
  },
  '/management/handbook': {
    title: '',
    isDisplayTrial: false,
  },
  '/management/assignment': {
    title: '',
    isDisplayTrial: false,
  },
  'https://ribbon-jury-464.notion.site/c9d9f64aedc8450685cee96ec72bc571': {
    title: '',
    isDisplayTrial: false,
  },
  'https://ribbon-jury-464.notion.site/a2a6f0c569334690a1db6f4adee1a005': {
    title: '',
    isDisplayTrial: false,
  },
  'https://ribbon-jury-464.notion.site/3e386b36e0154383abead11e5cd4ec74': {
    title: '',
    isDisplayTrial: false,
  },
};
