type Jwt = {
  [name: string]: string | number | boolean;
};

export const decodeJwt = (token: string): Jwt => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  return JSON.parse(decodeURIComponent(escape(window.atob(base64)))) as Jwt;
};

export const isTokenExpired = (token: string): boolean => {
  const decoded = decodeJwt(token);
  const expireDate = decoded['exp'] as number;
  const timestamp = parseInt(Date.now().toString().slice(0, 10));
  return expireDate <= timestamp;
};
