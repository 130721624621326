import { ChartData } from 'chart.js';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { fontList, opacityRatio } from '@/const/result';
import useTermStatus from '@/hooks/useTermStatus';
import isStudentAtDate from '@/utils/isStudentAtDate';

import { Result } from './../generated/types/typescript-axios/api';
import { FontTypeIDs } from './../types/assignment';
import { Nullable } from './../types/index';

type Props = {
  result: Nullable<Result>;
};

export default function useResult({ result }: Props) {
  const { lastReleaseRankDate } = useTermStatus();

  const [targetYear, setTargetYear] = useState<number>(lastReleaseRankDate.year());
  const [targetMonth, setTargetMonth] = useState<number>(lastReleaseRankDate.month() + 1);

  // 選択書体(デフォルト「すべて」)
  const [targetFontId, setTargetFontId] = useState<number>(0);
  const [displayType, setDisplayType] = useState<number>(1);

  const [showChart, setShowChart] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const [searchParams] = useSearchParams();

  // 学生から一般部に上がる時に過去の成績見ると書体が見れないので直す必要あり
  const isStudent = isStudentAtDate(result?.user?.birthday ?? '');

  // targetFontIdが更新された時だけ更新する
  const targetFontResult = useMemo(
    () => result?.assignments?.find(({ font }) => font?.id === targetFontId),
    [targetFontId]
  );

  const targetColor = useMemo(() => {
    const color = fontList.find(({ fontId }) => fontId === targetFontId)?.color;

    return {
      background: `${color}${opacityRatio}`,
      border: `${color}`,
    };
  }, [targetFontId]);

  const chartLabels = () => {
    if (result?.assignments?.length === undefined || result?.assignments?.length === 0) {
      return [];
    }

    if (isStudent) {
      return ['字形', '中心/配置', '基本点画', '名前', '大きさ/力強さ'];
    }

    return result.assignments[0].scores?.map(({ viewpoint }) => viewpoint?.name);
  };

  // Chartデータの成形
  // https://react-chartjs-2.js.org/
  // https://www.chartjs.org/docs/latest/charts/radar.html
  const chartData: ChartData<'radar', (number | undefined)[]> = useMemo(() => {
    return targetFontId
      ? {
          // 書体選択時
          labels: targetFontResult?.scores?.map(({ viewpoint }) => viewpoint?.name ?? '') ?? [],
          datasets: [
            {
              label: targetFontResult?.font?.name,
              data: targetFontResult?.scores?.map(({ point }) => point) ?? [],
              backgroundColor: targetColor.background,
              borderColor: targetColor.border,
            },
          ],
        }
      : {
          // すべて
          labels: chartLabels(),
          datasets:
            result?.assignments
              ?.filter(
                (assignment) =>
                  ![FontTypeIDs.RINSHO, FontTypeIDs.PENJI, FontTypeIDs.JITSUYOSYO].some(
                    (fontId) => assignment?.font?.id == fontId
                  )
              )
              .map(({ font, scores }) => ({
                label: font?.name,
                data: scores?.map(({ point }) => point) ?? [],
                backgroundColor: 'transparent',
                borderWidth: 1,
                pointStyle: false,
                borderColor: `${fontList.find(({ fontId }) => fontId === font?.id)?.color}`,
              })) ?? [],
        };
  }, [result, targetFontResult]);

  return {
    targetMonth,
    targetYear,
    targetFontId,
    displayType,
    showChart,
    showModal,
    searchParams,
    setTargetMonth,
    setTargetYear,
    setTargetFontId,
    setDisplayType,
    setShowChart,
    setShowModal,
    targetFontResult,
    isStudent,
    chartData,
  };
}
