import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';

import { CommentTemplate, CommentTemplateCategory } from '@/generated/types/typescript-axios';

type Props = {
  control: Control<FieldValues, any>;
  categoryFormName: string;
  commentFormName: string;
  categories: CommentTemplateCategory[];
  categoryComments: CommentTemplate[];
  handleChangeCategory: (e: SelectChangeEvent) => void;
};

export default function TemplateComments({
  control,
  categoryFormName,
  categories,
  commentFormName,
  categoryComments,
  handleChangeCategory,
}: Props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
      <Controller
        name={categoryFormName}
        defaultValue=""
        control={control}
        render={({ field }) => (
          <FormControl sx={{ width: '25%' }}>
            <InputLabel>項目</InputLabel>
            <Select
              {...field}
              id="category"
              label="項目"
              onChange={(event: SelectChangeEvent) => {
                field.onChange(event);
                handleChangeCategory(event);
              }}
            >
              {categories.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      <Controller
        name={commentFormName}
        control={control}
        defaultValue=""
        render={({ field }) => (
          <FormControl sx={{ width: '75%' }}>
            <InputLabel>審査所感</InputLabel>
            <Select {...field} id="template_comment" label="審査所感">
              {categoryComments?.map((selectedComment, index) => (
                <MenuItem key={index} value={selectedComment.id}>
                  {selectedComment.comment}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
    </Box>
  );
}
