import { Box, Grid, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ResultsItemsInner } from '@/generated/types/typescript-axios/api';

import OrderContent from './OrderContent';
import RankContent from './RankContent';
import ResultItem from './ResultItem';

type Props = {
  item: ResultsItemsInner;
};

const scoreThresholds = {
  min: 5,
  max: 8,
};

export default function Score({ item }: Props) {
  const { t } = useTranslation();

  return (
    <Stack
      direction={['column', 'column-reverse']}
      gap={[4, 1]}
      sx={{
        pl: [0, 1],
      }}
    >
      {/* 段級位/順位 */}
      <Stack gap={1}>
        {item?.rank?.current?.id && (
          <ResultItem title={`${t('pages:whole_result.rank')}`}>
            <RankContent currentRank={item?.rank.current} isRankUp={item?.rank.is_rank_up} />
          </ResultItem>
        )}
        {item?.rank_orders?.same_rank_order?.order ? (
          <ResultItem title={`${t('pages:whole_result.order')}`}>
            <OrderContent rank_orders={item?.rank_orders} />
          </ResultItem>
        ) : null}
      </Stack>

      {/* 点数 */}
      <Grid container spacing={1}>
        {/* 各viewpointの点数 */}
        {item?.scores?.map(({ viewpoint, point }) => (
          <Grid key={viewpoint?.id} item xs={6}>
            <ResultItem title={viewpoint?.name} disabled={!point}>
              <Stack direction="row" sx={{ color: 'grey.400', fontSize: 24 }}>
                {/* https://mui.com/material-ui/customization/default-theme/ (palette内のカラーを使用することで後でthemeカスタマイズできる) */}
                <Box
                  sx={{
                    color: point
                      ? point >= scoreThresholds.max
                        ? 'success.light'
                        : point < scoreThresholds.min
                        ? 'error.light'
                        : 'initial'
                      : 'initial',
                  }}
                >
                  {point}
                </Box>
                /10
              </Stack>
            </ResultItem>
          </Grid>
        ))}
        {/* 総合点数 */}
        <Grid item xs={6}>
          <ResultItem title={`${t('pages:whole_result.total')}`}>
            <Stack direction="row" sx={{ color: 'grey.400', fontSize: 24 }}>
              {/* https://mui.com/material-ui/customization/default-theme/ (palette内のカラーを使用することで後でthemeカスタマイズできる) */}
              <Box
                sx={{
                  color:
                    (item?.score_amount ?? 0) >= scoreThresholds.max * (item?.scores?.length ?? 0)
                      ? 'success.light'
                      : (item?.score_amount ?? 0) <
                        scoreThresholds.min * (item?.scores?.length ?? 0)
                      ? 'error.light'
                      : 'initial',
                }}
              >
                {item.score_amount}
              </Box>
              /{(item?.scores?.length ?? 0) * 10}
            </Stack>
          </ResultItem>
        </Grid>
      </Grid>
    </Stack>
  );
}
