import CircleIcon from '@mui/icons-material/Circle';
import { Box, Chip, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';

import Loader from '@/components/Loader';
import { FontCase } from '@/types';

type Props = {
  fontCases: FontCase[];
  loading: boolean;
  onClickItem: (font: FontCase) => void;
  selectedFont: FontCase | undefined;
};

export default function AdultFontList({ fontCases, loading, onClickItem, selectedFont }: Props) {
  return loading ? (
    <Loader />
  ) : (
    <Box sx={{ width: '100%', maxWidth: 300, bgcolor: 'background.paper' }}>
      <List>
        {fontCases.map((font) => (
          <ListItem disablePadding key={font.name}>
            <ListItemButton
              disabled={!font.handbook_id.length}
              onClick={() => onClickItem(font)}
              selected={selectedFont?.id == font.id}
            >
              <CircleIcon fontSize="small" sx={{ color: grey[600] }} />
              <ListItemText primary={font.name} />
              <Chip label={`提出数 ${font.font_submitter_count}名`} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
