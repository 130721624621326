import { useTranslation } from 'react-i18next';

export default function useRanks() {
  const { t } = useTranslation();

  const rankList = [
    { id: 1, rank: t('common:ranks:10th_grade') },
    { id: 2, rank: t('common:ranks:9th_grade') },
    { id: 3, rank: t('common:ranks:8th_grade') },
    { id: 4, rank: t('common:ranks:7th_grade') },
    { id: 5, rank: t('common:ranks:6th_grade') },
    { id: 6, rank: t('common:ranks:5th_grade') },
    { id: 7, rank: t('common:ranks:4th_grade') },
    { id: 8, rank: t('common:ranks:3rd_grade') },
    { id: 9, rank: t('common:ranks:2nd_grade') },
    { id: 10, rank: t('common:ranks:1st_grade') },
    { id: 11, rank: t('common:ranks:1st_dan') },
    { id: 12, rank: t('common:ranks:2nd_dan') },
    { id: 13, rank: t('common:ranks:3rd_dan') },
    { id: 14, rank: t('common:ranks:4th_dan') },
    { id: 15, rank: t('common:ranks:5th_dan') },
    { id: 16, rank: t('common:ranks:6th_dan') },
    { id: 17, rank: t('common:ranks:7th_dan') },
    { id: 18, rank: t('common:ranks:8th_dan') },
    { id: 19, rank: t('common:ranks:9th_dan') },
    { id: 20, rank: t('common:ranks:jun_shihan') },
    { id: 21, rank: t('common:ranks:shihan') },
    { id: 22, rank: t('common:ranks:10th_grade') },
    { id: 23, rank: t('common:ranks:9th_grade') },
    { id: 24, rank: t('common:ranks:8th_grade') },
    { id: 25, rank: t('common:ranks:7th_grade') },
    { id: 26, rank: t('common:ranks:6th_grade') },
    { id: 27, rank: t('common:ranks:5th_grade') },
    { id: 28, rank: t('common:ranks:4th_grade') },
    { id: 29, rank: t('common:ranks:3rd_grade') },
    { id: 30, rank: t('common:ranks:2nd_grade') },
    { id: 31, rank: t('common:ranks:1st_grade') },
    { id: 32, rank: t('common:ranks:1st_dan') },
    { id: 33, rank: t('common:ranks:2nd_dan') },
    { id: 34, rank: t('common:ranks:3rd_dan') },
    { id: 35, rank: t('common:ranks:4th_dan') },
    { id: 36, rank: t('common:ranks:5th_dan') },
    { id: 37, rank: t('common:ranks:6th_dan') },
    { id: 38, rank: t('common:ranks:7th_dan') },
    { id: 39, rank: t('common:ranks:8th_dan') },
    { id: 40, rank: t('common:ranks:9th_dan') },
    { id: 41, rank: t('common:ranks:jun_shihan') },
    { id: 42, rank: t('common:ranks:shihan') },
    { id: 43, rank: t('common:ranks:10th_grade') },
    { id: 44, rank: t('common:ranks:9th_grade') },
    { id: 45, rank: t('common:ranks:8th_grade') },
    { id: 46, rank: t('common:ranks:7th_grade') },
    { id: 47, rank: t('common:ranks:6th_grade') },
    { id: 48, rank: t('common:ranks:5th_grade') },
    { id: 49, rank: t('common:ranks:4th_grade') },
    { id: 50, rank: t('common:ranks:3rd_grade') },
    { id: 51, rank: t('common:ranks:2nd_grade') },
    { id: 52, rank: t('common:ranks:1st_grade') },
    { id: 53, rank: t('common:ranks:1st_dan') },
    { id: 54, rank: t('common:ranks:2nd_dan') },
    { id: 55, rank: t('common:ranks:3rd_dan') },
    { id: 56, rank: t('common:ranks:4th_dan') },
    { id: 57, rank: t('common:ranks:5th_dan') },
    { id: 58, rank: t('common:ranks:6th_dan') },
    { id: 59, rank: t('common:ranks:7th_dan') },
    { id: 60, rank: t('common:ranks:8th_dan') },
    { id: 61, rank: t('common:ranks:9th_dan') },
    { id: 62, rank: t('common:ranks:scholarship_student') },
  ];

  const adultRankNames = [
    t('common:ranks:shihan'),
    t('common:ranks:jun_shihan'),
    t('common:ranks:9th_dan'),
    t('common:ranks:8th_dan'),
    t('common:ranks:7th_dan'),
    t('common:ranks:6th_dan'),
    t('common:ranks:5th_dan'),
    t('common:ranks:4th_dan'),
    t('common:ranks:3rd_dan'),
    t('common:ranks:2nd_dan'),
    t('common:ranks:1st_dan'),
    t('common:ranks:1st_grade'),
    t('common:ranks:2nd_grade'),
    t('common:ranks:3rd_grade'),
    t('common:ranks:4th_grade'),
    t('common:ranks:5th_grade'),
    t('common:ranks:6th_grade'),
    t('common:ranks:7th_grade'),
    t('common:ranks:8th_grade'),
    t('common:ranks:9th_grade'),
    t('common:ranks:10th_grade'),
  ];

  const studentRankNames = [
    t('common:ranks:scholarship_student'),
    t('common:ranks:9th_dan'),
    t('common:ranks:8th_dan'),
    t('common:ranks:7th_dan'),
    t('common:ranks:6th_dan'),
    t('common:ranks:5th_dan'),
    t('common:ranks:4th_dan'),
    t('common:ranks:3rd_dan'),
    t('common:ranks:2nd_dan'),
    t('common:ranks:1st_dan'),
    t('common:ranks:1st_grade'),
    t('common:ranks:2nd_grade'),
    t('common:ranks:3rd_grade'),
    t('common:ranks:4th_grade'),
    t('common:ranks:5th_grade'),
    t('common:ranks:6th_grade'),
    t('common:ranks:7th_grade'),
    t('common:ranks:8th_grade'),
    t('common:ranks:9th_grade'),
    t('common:ranks:10th_grade'),
  ];

  return { rankList, adultRankNames, studentRankNames };
}
