import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

export default function useTermStatus() {
  const { t } = useTranslation();

  const termList = [
    { id: 0, name: t('common:termStatus:before_open') },
    { id: 1, name: t('common:termStatus:pre_open') },
    { id: 2, name: t('common:termStatus:now_open') },
    { id: 3, name: t('common:termStatus:after_open') },
  ];

  // 直近の成績公開をした課題の年月を取得
  // 今日が1日〜24日：アクセスした年月の先々月
  // 今日が25日〜31(30)日：アクセスした先月
  const now = dayjs();
  const lastReleaseRankDate = now.isBetween(now.startOf('date'), now.endOf('date').date(24))
    ? now.subtract(2, 'month').startOf('month')
    : now.subtract(1, 'month').startOf('month');

  return { termList, lastReleaseRankDate };
}
