import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { SubscriptionApi } from '@/generated/types/typescript-axios';
import { RootState } from '@/store';
import { AsyncStatus } from '@/types';
import { ApiConfig } from '@/utils/apiConfig';

type SubscriptionState = {
  status: { cancel: AsyncStatus };
};

const initialState: SubscriptionState = {
  status: {
    cancel: AsyncStatus.IDLE,
  },
};

export const cancelSubscription = createAsyncThunk('cancelSubscription', async (_, thunkAPI) => {
  try {
    const apiConfig = ApiConfig();
    const subscriptionApi = new SubscriptionApi(apiConfig);
    await subscriptionApi.cancelSubscription();
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    clearStatus: (state: SubscriptionState) => {
      state.status.cancel = AsyncStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(cancelSubscription.pending, (state) => {
        state.status.cancel = AsyncStatus.LOADING;
      })
      .addCase(cancelSubscription.fulfilled, (state) => {
        state.status.cancel = AsyncStatus.SUCCESS;
      })
      .addCase(cancelSubscription.rejected, (state) => {
        state.status.cancel = AsyncStatus.FAILED;
      });
  },
});

export const { clearStatus } = subscriptionSlice.actions;

export const subscriptionSelector = (state: RootState) => state.subscription;

export default subscriptionSlice.reducer;
