import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HandBookApi, HandbooksAvailTermsInner } from '@/generated/types/typescript-axios';
import { RootState } from '@/store';
import { AsyncStatus } from '@/types';
import { ApiConfig } from '@/utils/apiConfig';

type HandbookAvailTermState = {
  handbookAvailTerms: HandbooksAvailTermsInner[];
  status: { fetch: AsyncStatus };
};

const initialState: HandbookAvailTermState = {
  handbookAvailTerms: [],
  status: {
    fetch: AsyncStatus.IDLE,
  },
};

export const fetchHandbooksAvailTerms = createAsyncThunk(
  'fetchHandbooksAvailTerms',
  async (_, thunkAPI) => {
    try {
      const apiConfig = ApiConfig();
      const handBookApi = new HandBookApi(apiConfig);
      const { data } = await handBookApi.getHandbooksAvailTerms();
      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const handbookAvailTermSlice = createSlice({
  name: 'handbookAvailTerm',
  initialState,
  reducers: {
    clearStatus: (state: HandbookAvailTermState) => {
      state.status.fetch = AsyncStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHandbooksAvailTerms.pending, (state) => {
        state.status.fetch = AsyncStatus.LOADING;
      })
      .addCase(
        fetchHandbooksAvailTerms.fulfilled,
        (state, { payload }: { payload: HandbooksAvailTermsInner[] }) => {
          state.handbookAvailTerms = payload;
          state.status.fetch = AsyncStatus.SUCCESS;
        }
      )
      .addCase(fetchHandbooksAvailTerms.rejected, (state) => {
        state.status.fetch = AsyncStatus.FAILED;
      });
  },
});

export const { clearStatus } = handbookAvailTermSlice.actions;

export const handbookAvailTermsSelector = (state: RootState) => state.handbookAvailTerm;

export default handbookAvailTermSlice.reducer;
