import { useEffect, useState } from 'react';

import { esStudentCases, jhsStudentCases, preStudentCases } from '@/const';
import { AssignmentCountFontsInner, Handbook } from '@/generated/types/typescript-axios';
import {
  assignmentSelector,
  clearAssignmentCount,
  clearStatusLabel,
  fetchAssignmentCount,
  getStatusLabel,
} from '@/services/assignmentSlice';
import { clearStatus as clearFontStatus, fetchFonts, fontSelector } from '@/services/fontSlice';
import {
  clearStatus as clearHandbookStatus,
  fetchHandbooks,
  handbookSelector,
} from '@/services/handbookSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { AssignmentStudentCaseWithStatus, AsyncStatus, CourseId, GradeObject } from '@/types';

type Props = {
  year: number;
  month: number;
};

export default function useAssignmentManagement({ year, month }: Props) {
  const dispatch = useAppDispatch();
  const { handbooks, status } = useAppSelector(handbookSelector);
  const { fonts } = useAppSelector(fontSelector);
  const {
    assignmentCount,
    statusLabel,
    status: assignmentStatus,
  } = useAppSelector(assignmentSelector);

  const studentFonts = fonts.filter((font) => font.course_type === CourseId.STUDENT);

  // Local state
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    dispatch(fetchFonts(null));
    dispatch(
      getStatusLabel({
        year,
        month,
      })
    );
    dispatch(
      fetchHandbooks({
        year,
        month,
      })
    );
    dispatch(
      fetchAssignmentCount({
        year,
        month,
      })
    );

    // Unmount時にstatusをクリア
    return () => {
      dispatch(clearFontStatus());
      dispatch(clearHandbookStatus());
      dispatch(clearStatusLabel());
      dispatch(clearAssignmentCount());
    };
  }, [year, month]);

  const setStudentCases = (studentCase: GradeObject[]): AssignmentStudentCaseWithStatus[] => {
    return studentCase.map((studentCase) => {
      return {
        ...studentCase,
        fonts_array: studentFonts.map((item) => {
          return {
            id: item.id,
            name: item.name,
            // is_registered: !!handbooks.find( 今後未採点者の数実装
            //   ({ font, grade_id }) => font?.id === item.id && grade_id === studentCase.grade_id
            // ),
            handbook_id: handbooks
              .filter(
                ({ font, grade_id }) => font?.id === item.id && grade_id === studentCase.grade_id
              )
              .map((handbook: Handbook) => handbook.id),
            font_submitter_count:
              assignmentCount?.fonts?.find(
                (font: AssignmentCountFontsInner) =>
                  font?.font_id === item.id && font?.grade_id === studentCase.grade_id
              )?.count ?? 0,
          };
        }),
        //   is_all_registered: !studentFonts 今後未採点者の数実装
        //     .map((item) => {
        //       return !!handbooks.find(
        //         ({ font, grade_id }) => font?.id === item.id && grade_id === studentCase.grade_id
        //       );
        //     })
        //     .some((is_registered) => !is_registered),
      };
    });
  };

  // 一般/学生api切り分け
  // 一般
  const adultCasesWithStatus = fonts
    .filter((font) => font.course_type === CourseId.ADULT)
    .map(({ id, name }) => {
      return {
        id: id,
        name: name,
        handbook_id: handbooks
          .filter(({ font }) => font?.id === id)
          .map((handbook: Handbook) => handbook.id),
        // ungraded_num: !!handbooks.find(({ font }) => font?.id === id), 今後未採点者の数実装
        font_submitter_count:
          assignmentCount?.fonts?.find((font: AssignmentCountFontsInner) => font?.font_id === id)
            ?.count ?? 0,
      };
    });

  // 学生
  const preStudentCasesWithStatus = setStudentCases(preStudentCases);
  const esStudentCasesWithStatus = setStudentCases(esStudentCases);
  const jhsStudentCasesWithStatus = setStudentCases(jhsStudentCases);

  return {
    activeTab,
    setActiveTab,
    adultCasesWithStatus,
    preStudentCasesWithStatus,
    esStudentCasesWithStatus,
    jhsStudentCasesWithStatus,
    loading: status.bulkFetch === AsyncStatus.LOADING,
    statusLabel,
    getStatusLabelLoading: assignmentStatus.fetchStatusLabel === AsyncStatus.LOADING,
    participantCount: assignmentCount?.participant_count,
  };
}
