import { Divider } from '@mui/material';
import { Box, Stack } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ResultsItemsInnerRankOrders } from '@/generated/types/typescript-axios';

type Props = {
  rank_orders: ResultsItemsInnerRankOrders | undefined;
};

export default function OrderContent({ rank_orders }: Props) {
  const { t } = useTranslation();
  const sameRankOrder = rank_orders?.same_rank_order?.order ?? 0;
  const wholeRankOrder = rank_orders?.whole_order?.order ?? 0;
  const isNoOrder = !sameRankOrder && !wholeRankOrder;

  return !isNoOrder ? (
    <Stack direction="row" alignItems="center" gap={1} sx={{ fontSize: 24 }}>
      {/* 全体順位 */}
      <Stack direction="row" alignItems="baseline" sx={{ color: 'grey.400' }}>
        <Box component="span" sx={{ fontSize: 10 }}>
          {t('pages:whole_result.wholeOrder')}
        </Box>
        <Box component="span" sx={{ color: 'initial', marginLeft: 1 }}>
          {wholeRankOrder}
        </Box>
        <Box component="span" sx={{ fontWeight: 'normal' }}>
          {t('pages:whole_result.orderUnit')}
        </Box>
      </Stack>

      {/* 同位順位 */}
      <Divider orientation="vertical" flexItem />
      <Stack direction="row" alignItems="baseline" sx={{ color: 'grey.400' }}>
        <Box component="span" sx={{ fontSize: 10 }}>
          {t('pages:whole_result.sameRankOrder')}
        </Box>
        <Box component="span" sx={{ color: 'initial', marginLeft: 1 }}>
          {sameRankOrder}
        </Box>
        <Box component="span" sx={{ fontWeight: 'normal' }}>
          {t('pages:whole_result.orderUnit')}
        </Box>
      </Stack>
    </Stack>
  ) : (
    <Box sx={{ color: 'grey.400' }}>---</Box>
  );
}
