import { Circle, ExpandCircleDown, ExpandLess } from '@mui/icons-material';
import { Chip, Collapse, List, ListItemButton, ListItemText, ListSubheader } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useState } from 'react';

import Loader from '@/components/Loader';
import { HandbookStudentCaseWithStatus, OpenObject } from '@/types';

type Props = {
  preStudentCases: HandbookStudentCaseWithStatus[];
  esStudentCases: HandbookStudentCaseWithStatus[];
  jhsStudentCases: HandbookStudentCaseWithStatus[];
  loading: boolean;
  onClickNavigation: (fontId: number, gradeId?: number) => void;
};

export default function StudentHandbookList({
  preStudentCases,
  esStudentCases,
  jhsStudentCases,
  loading,
  onClickNavigation,
}: Props) {
  const studentInfoList = [
    { key: 'pre', label: '幼年の部', caseList: preStudentCases },
    { key: 'es', label: '小学生の部', caseList: esStudentCases },
    { key: 'jhs', label: '中学生の部', caseList: jhsStudentCases },
  ];

  const [listOpenStatus, setListOpenStatus] = useState<OpenObject>({
    pre_student: true,
    es_first: true,
    es_second: true,
    es_third: true,
    es_fourth: true,
    es_fifth: true,
    es_sixth: true,
    jhs_first: true,
    jhs_second: true,
    jhs_third: true,
    first: true,
    second: true,
    third: true,
  });

  const handleClick = (key: keyof OpenObject) => {
    setListOpenStatus({ ...listOpenStatus, [key]: !listOpenStatus[key] });
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      {studentInfoList.map(({ key, label, caseList }) => (
        <List
          key={key}
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              {label}
            </ListSubheader>
          }
        >
          {caseList.map((s_case) => (
            <React.Fragment key={s_case.open_key}>
              <ListItemButton onClick={() => handleClick(s_case.open_key)}>
                {listOpenStatus[s_case.open_key] ? (
                  <ExpandCircleDown fontSize="small" sx={{ color: grey[600] }} />
                ) : (
                  <ExpandLess />
                )}
                <ListItemText primary={s_case.value} sx={{ pl: 1 }} />
                <Chip
                  label={s_case.is_all_registered ? '全て登録済' : '未登録あり'}
                  variant={s_case.is_all_registered ? 'filled' : 'outlined'}
                />
              </ListItemButton>
              <Collapse in={!listOpenStatus[s_case.open_key]} timeout="auto" unmountOnExit>
                {s_case.fonts_array.map(({ name, font_id, is_registered }) => (
                  <ListItemButton
                    key={name}
                    sx={{ pl: 4 }}
                    onClick={() => onClickNavigation(font_id, s_case.grade_id)}
                  >
                    <Circle fontSize="small" sx={{ color: grey[600] }} />
                    <ListItemText primary={'「' + name + '」 手本管理'} />
                    <Chip
                      label={is_registered ? '登録済' : '未登録'}
                      variant={is_registered ? 'filled' : 'outlined'}
                    />
                  </ListItemButton>
                ))}
              </Collapse>
            </React.Fragment>
          ))}
        </List>
      ))}
    </>
  );
}
