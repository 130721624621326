import { Box, Stack } from '@mui/system';
import React from 'react';

type Props = {
  children: React.ReactNode;
  title: string | undefined;
  clickable?: boolean;
  disabled?: boolean;
  color?: string | undefined;
  handleClickItem?: () => void;
};

export default function ResultItem({
  children,
  title,
  clickable = false,
  disabled = false,
  color = undefined,
  handleClickItem = undefined,
}: Props) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        backgroundColor: disabled ? 'grey.100' : '',
        border: 1,
        borderColor: disabled ? 'grey.100' : 'grey.400',
        borderRadius: 2,
        fontWeight: 'bold',
        height: 28,
        p: 2,
        cursor: clickable ? 'pointer' : 'default',
      }}
      onClick={handleClickItem}
    >
      {/* 書体名 */}
      <Box
        sx={{
          color: disabled ? 'grey.400' : color,
        }}
      >
        {title}
      </Box>

      {children}
    </Stack>
  );
}
