// Supported locales: https://github.com/iamkun/dayjs/tree/dev/src/locale
import 'dayjs/locale/ja';

import { Box, Breadcrumbs, Chip, Grid, Link, Stack, Tab, Tabs, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import AdultFontList from '@/components/assignmentManagement/AdultFontList';
import ItemDetail from '@/components/assignmentManagement/ItemDetail';
import Review from '@/components/assignmentManagement/Review';
import StudentFontList from '@/components/assignmentManagement/StudentFontList';
import UserList from '@/components/assignmentManagement/UserList';
import Loader from '@/components/Loader';
import TabPanel from '@/components/parts/TabPanel';
import { AssignmentUser } from '@/generated/types/typescript-axios';
import useAssignmentManagement from '@/hooks/useAssignmentManagement';
import useGrade from '@/hooks/useGrade';
import useIsAvailTerm from '@/hooks/useTermStatus';
import {
  assignmentSelector,
  clearAssignment,
  clearAssignmentUsers,
  fetchAssignment,
  fetchAssignmentReview,
  fetchAssignmentUsers,
} from '@/services/assignmentSlice';
import { fetchViewpoints } from '@/services/viewpointSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { AsyncStatus, CourseId, FontCase, Nullable, URL } from '@/types';

const getInitialYearMonth = () => {
  // 初期設定で成績発表前の課題の月を表示させる
  // 今日が1日〜25日：アクセスした年月の前月
  // 今日が26日〜31(30)日：アクセスした年月
  const now = dayjs();
  const startDate = now.startOf('date');
  const endDate = now.endOf('date').date(25);

  const yearMonth: Dayjs = now.isBetween(startDate, endDate)
    ? now.subtract(1, 'month').startOf('month')
    : now.startOf('month');

  return {
    initialYear: yearMonth.year(),
    initialMonth: yearMonth.month() + 1,
  };
};

const replaceAddress = (year: number, month: number) => {
  window.history.replaceState(null, '', `${URL.ASSIGNMENT_MANAGEMENT}/${year}/${month}`);
};

export default function AssignmentManagement() {
  const dispatch = useAppDispatch();
  const { assignment, assignmentUsers, status } = useAppSelector(assignmentSelector);
  const { termList } = useIsAvailTerm();
  const { initialYear, initialMonth } = useMemo(() => {
    return getInitialYearMonth();
  }, []);
  const { year, month } = useParams();

  const [targetYear, setTargetYear] = useState<number>(Number(year) || initialYear);
  const [targetMonth, setTargetMonth] = useState<number>(Number(month) || initialMonth);
  const [selectedFont, setSelectedFont] = useState<FontCase>();
  const [selectedUser, setSelectedUser] = useState<AssignmentUser>();
  const [selectedGradeId, setSelectedGradeId] = useState<number>();
  const { getGradeNameById } = useGrade();

  useEffect(() => {
    if (!year || !month) {
      replaceAddress(initialYear, initialMonth);
    }
  }, []);

  const clearState = () => {
    setSelectedFont(undefined);
    setSelectedUser(undefined);
    setSelectedGradeId(undefined);
    dispatch(clearAssignment());
    dispatch(clearAssignmentUsers());
  };

  const onClickFontItem = (font: FontCase, gradeId?: number) => {
    clearState();
    setSelectedFont(font);
    setSelectedGradeId(gradeId);
    dispatch(fetchAssignmentUsers(font.handbook_id[0]));
  };

  const onClickUserItem = (user: AssignmentUser) => {
    dispatch(clearAssignment());
    if (user.assignment_id) {
      dispatch(fetchAssignment(user.assignment_id)).then((response: any) => {
        dispatch(fetchAssignmentReview(response.payload.id as number));
        dispatch(fetchViewpoints(response.payload.font_id as number));
      });
    }
    setSelectedUser(user);
  };

  const currentIndex = assignmentUsers.findIndex((u) => u.assignment_id === assignment?.id);
  const hasNextUser = currentIndex < assignmentUsers.length - 1;
  const onClickNextUser = () => {
    if (hasNextUser) {
      const nextUser = assignmentUsers[currentIndex + 1];
      if (nextUser && nextUser.assignment_id) {
        onClickUserItem(nextUser);
      }
    }
  };

  const {
    activeTab,
    setActiveTab,
    adultCasesWithStatus,
    preStudentCasesWithStatus,
    esStudentCasesWithStatus,
    jhsStudentCasesWithStatus,
    loading,
    statusLabel,
    getStatusLabelLoading,
    participantCount,
  } = useAssignmentManagement({
    year: targetYear,
    month: targetMonth,
  });

  const statusLabelName: string = (termList.filter((term) => term.id === statusLabel)[0] ?? '')
    .name;

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleChangeYearMonth = (date: Nullable<Dayjs>) => {
    const year = Number(date?.format('YYYY'));
    const month = Number(date?.format('M'));

    if (!!year && !!month) {
      setTargetYear(year);
      setTargetMonth(month);
      replaceAddress(year, month);
    }
  };

  const onClickBreadcrumbs = (role: string) => {
    switch (role) {
      case 'fonts':
        clearState();
        break;
      case 'users':
        if (!selectedFont) break;
        onClickFontItem(selectedFont, selectedGradeId);
        break;
    }
  };

  return (
    <Box sx={{ p: 4, pb: 0 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { sm: 'center' },
          gap: 4,
          mb: 2,
        }}
      >
        <Typography variant="h5">提出課題管理</Typography>
        <Box sx={{ minWidth: 120 }}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
              <DatePicker
                views={['year', 'month']}
                value={dayjs(`${targetYear}-${targetMonth}`)}
                label="年月を選択"
                format="YYYY/MM"
                onChange={handleChangeYearMonth}
              />
            </LocalizationProvider>
            <Chip label={statusLabelName} variant="outlined" />
          </Stack>
        </Box>
      </Box>
      <Breadcrumbs
        sx={{
          display: {
            xs: selectedFont ? 'block' : 'none',
            sm: selectedFont && selectedUser ? 'block' : 'none',
          },
          mt: 2,
        }}
      >
        <Link
          sx={{ cursor: 'pointer' }}
          underline="hover"
          color="inherit"
          onClick={() => {
            onClickBreadcrumbs('fonts');
          }}
        >
          書体一覧
        </Link>
        {selectedFont ? (
          selectedUser ? (
            <Link
              sx={{ cursor: 'pointer' }}
              underline="hover"
              color="inherit"
              onClick={() => {
                onClickBreadcrumbs('users');
              }}
            >
              {selectedGradeId ? `${getGradeNameById(selectedGradeId)} ` : ''}
              {selectedFont.name} 課題提出者一覧
            </Link>
          ) : (
            <Typography>
              {selectedGradeId ? `${getGradeNameById(selectedGradeId)} ` : ''}
              {selectedFont?.name} 課題提出者一覧
            </Typography>
          )
        ) : undefined}
        {selectedFont && selectedUser ? <Typography>課題詳細</Typography> : undefined}
      </Breadcrumbs>

      {getStatusLabelLoading ? (
        <Loader />
      ) : (
        <>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Tabs
                value={activeTab}
                onChange={handleChangeTab}
                sx={{ borderBottom: 1, borderColor: 'divider', pt: 1 }}
              >
                <Tab label="一般" onClick={clearState} />
                <Tab label="学生" onClick={clearState} />
              </Tabs>
              <Box
                sx={{
                  fontSize: 14,
                  color: 'text.secondary',
                  pr: 3,
                }}
              >
                参加数：{participantCount}名
              </Box>
            </Box>
          </Box>
          {[CourseId.ADULT, CourseId.STUDENT].map((courseType, index) => (
            <TabPanel key={courseType} value={activeTab} index={index} sx={{ p: [0, 3], pb: 0 }}>
              <Grid container columnSpacing={1} sx={{ w: 'full' }}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  sx={{
                    display: {
                      xs: selectedFont ? 'none' : 'flex',
                      sm: selectedFont && selectedUser ? 'none' : 'flex',
                    },
                    flexDirection: 'column',
                    height: 'calc(100vh - 303px)',
                    overflow: 'hidden',
                    overflowY: 'scroll',
                  }}
                >
                  {courseType === CourseId.ADULT ? (
                    <AdultFontList
                      fontCases={adultCasesWithStatus}
                      loading={loading}
                      onClickItem={onClickFontItem}
                      selectedFont={selectedFont}
                    />
                  ) : (
                    <StudentFontList
                      preStudentCases={preStudentCasesWithStatus}
                      esStudentCases={esStudentCasesWithStatus}
                      jhsStudentCases={jhsStudentCasesWithStatus}
                      loading={loading}
                      onClickItem={onClickFontItem}
                      clearState={clearState}
                      selectedFont={selectedFont}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  sx={{
                    display: {
                      xs: selectedFont ? (selectedUser ? 'none' : 'block') : 'none',
                    },
                    flexDirection: 'column',
                    height: 'calc(100vh - 303px)',
                    overflow: 'hidden',
                    overflowY: 'scroll',
                  }}
                >
                  <UserList
                    userList={assignmentUsers}
                    loading={status.bulkFetch === AsyncStatus.LOADING}
                    onClickItem={onClickUserItem}
                    selectedUser={selectedUser}
                  />
                </Grid>
                {!!selectedFont && !!selectedUser ? (
                  <>
                    <Grid item xs={12} md={6}>
                      <ItemDetail
                        item={assignment}
                        user={selectedUser}
                        loading={status.fetch === AsyncStatus.LOADING}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Review
                        courseType={courseType}
                        onclickNextUser={onClickNextUser}
                        hasNextUser={hasNextUser}
                      />
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </TabPanel>
          ))}
        </>
      )}
    </Box>
  );
}
