import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

import { isTokenExpired } from './jwt';

const API_ENDPOINT_URL = process.env.REACT_APP_API_ENDPOINT ?? '';
const sleep = (second: number) => new Promise((resolve) => setTimeout(resolve, second * 1000));

const getNewIdToken = async (): Promise<string> => {
  try {
    const { data } = await axios.get(`${API_ENDPOINT_URL}/token/refresh`, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
      withCredentials: true,
    });
    if (!data?.id_token || !data?.access_token) {
      throw new Error('failed to get new credentials');
    }
    return data.id_token as string;
  } catch (err) {
    throw new Error('failed to get new credentials');
  }
};

export const onReqFulfilled = async (config: InternalAxiosRequestConfig) => {
  if (!config?.headers) {
    return config;
  }
  const authorizationHeader = config.headers.Authorization as string;
  const idToken = authorizationHeader?.split(' ')[1];
  if (!idToken) {
    return config;
  }

  // トークンの有効期限が切れている場合は新しいトークンを取得する
  if (isTokenExpired(idToken)) {
    try {
      const newIdToken = await getNewIdToken();
      config.headers.Authorization = `Bearer ${newIdToken}`;
      window.localStorage.setItem('SaaSusIdToken', newIdToken);
      // JWTを更新してすぐ使用すると、Token used before used エラーになるため。
      // ref: https://github.com/dgrijalva/jwt-go/issues/383
      await sleep(1);
      return config;
    } catch (err) {
      throw new Error('failed to get new credentials');
    }
  }
  return config;
};

export const onResFulfilled = (res: AxiosResponse) => res;
export const onResRejected = async (error: AxiosError) => {
  if (!error.response || error.response.status !== 401 || !error.config?.headers) {
    return Promise.reject(error);
  }
  try {
    const newIdToken = await getNewIdToken();
    error.config.headers.Authorization = `Bearer ${newIdToken}`;
    window.localStorage.setItem('SaaSusIdToken', newIdToken);
    // JWTを更新してすぐ使用すると、Token used before used エラーになるため。
    // ref: https://github.com/dgrijalva/jwt-go/issues/383
    await sleep(1);
    return error.config;
  } catch (err) {
    Promise.reject(err);
  }
  return;
};
