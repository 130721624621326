import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { AssignmentUpload } from '@/components/AssignmentUpload';
import Loader from '@/components/Loader';
import { useToast } from '@/components/ToastProvider';
import { Assignment as AssignmentType, Handbook } from '@/generated/types/typescript-axios';
import useFonts from '@/hooks/useFonts';
import useGrade from '@/hooks/useGrade';
import {
  assignmentSelector,
  clearStatus,
  CreateAssignment,
  createAssignment,
  deleteAssignment,
  fetchAssignments,
  getStatusLabel,
  UpdateAssignment,
  updateAssignment,
} from '@/services/assignmentSlice';
import { authSelector } from '@/services/authSlice';
import { fetchHandbooks, handbookSelector } from '@/services/handbookSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { AsyncStatus, Font, Nullable, TermStatus, URL } from '@/types';

dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export type AssignmentImage = { fontId: number; file: File };

const now = dayjs();

const getTargetYearMonth = (statusLabel: number) => {
  // 課題受付期間を計算
  // 課題受付期間: 当月11日0:00~翌月10日23:59

  const yearMonth: Dayjs = [0, TermStatus.PRE_OPEN].includes(statusLabel ?? 0) // 前月の受付期間内
    ? now.subtract(1, 'month').startOf('month')
    : statusLabel === TermStatus.NOW_OPEN // 当月の受付期間内
    ? now.startOf('month')
    : now.add(1, 'month').startOf('month'); // 翌月の受付期間内

  return {
    year: yearMonth.year(),
    month: yearMonth.month() + 1,
  };
};

const Assignment = () => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { handleSubmit } = useForm();
  const { assignments, statusLabel, status: assignmentStatus } = useAppSelector(assignmentSelector);
  const { account, status: authStatus } = useAppSelector(authSelector);
  const { handbooks, status: handbookStatus } = useAppSelector(handbookSelector);
  const { fontList } = useFonts();
  const { getGradeObjectByBirthday } = useGrade();
  const [images, setImages] = useState<AssignmentImage[]>([]);
  const [isReadyHandbook, setIsReadyHandbook] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [dialogFont, setDialogFont] = useState<Font>();
  const myCourseFontList = fontList.filter((font) => font.course_type === account.course_type);

  const { year, month } = useMemo(() => {
    return getTargetYearMonth(statusLabel ?? 0);
  }, [statusLabel]);

  useEffect(() => {
    dispatch(
      getStatusLabel({
        year: Number(now.format('YYYY')),
        month: Number(now.format('M')),
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (statusLabel) {
      dispatch(fetchAssignments({ year, month, userId: account.id }));
      dispatch(
        fetchHandbooks({
          year,
          month,
          grade_id: getGradeObjectByBirthday(dayjs(account.birthday)).id || undefined,
        })
      );
    }
  }, [statusLabel]);

  // handbookがすべての書体で登録済みかどうかをチェック
  useEffect(() => {
    const handbookFontIds = handbooks.reduce((ac: number[], item) => {
      return [...ac, item.font.id];
    }, []);

    setIsReadyHandbook(
      !myCourseFontList.filter(({ id }) => {
        return !handbookFontIds.includes(id);
      }).length
    );
  }, [handbooks]);

  useEffect(() => {
    if (assignmentStatus.delete === AsyncStatus.SUCCESS) {
      toast({ message: '課題を削除しました。' });
      dispatch(fetchAssignments({ year, month, userId: account.id }));
    } else if (assignmentStatus.delete === AsyncStatus.FAILED) {
      toast({ message: '課題の削除に失敗しました。', type: 'error' });
    }

    dispatch(clearStatus());
  }, [assignmentStatus.delete]);

  const findAssignmentByFontId = (fontId: number): Nullable<AssignmentType> => {
    return assignments.find((assignment) => assignment.font_id === fontId);
  };

  const findHandbookByFontId = (fontId: number): Nullable<Handbook> => {
    return handbooks.find((handbook) => handbook.font.id === fontId);
  };

  const handleChangeImage = ({ fontId, file }: AssignmentImage) => {
    setImages([...images.filter((image) => image.fontId !== fontId), { fontId, file }]);
  };

  const handleSubmitForm = () => {
    if (!images.length) {
      toast({ message: '画像を選んでください。', type: 'warning' });
      return;
    }
    // 提出・再提出する課題のリスト
    const formValues: (CreateAssignment | UpdateAssignment)[] = [];
    for (const image of images) {
      const assignmentId = findAssignmentByFontId(image.fontId)?.id;
      const handbookId = findHandbookByFontId(image.fontId)?.id as number;
      if (assignmentId) {
        // 再提出の場合
        formValues.push({ id: assignmentId, year: year, month: month, file: image.file });
      } else {
        // 1回目の提出の場合
        formValues.push({
          user_id: account.id,
          handbook_id: handbookId,
          file: image.file,
          year: year,
          month: month,
        });
      }
    }

    Promise.all(
      // 課題提出・再提出処理
      formValues.map(async (formValue) => {
        // 提出・再提出判定
        if ('id' in formValue) {
          await dispatch(updateAssignment(formValue)).unwrap();
        } else {
          await dispatch(createAssignment(formValue)).unwrap();
        }
        return;
      })
    )
      .then(() => {
        toast({ message: '提出が完了しました。' });
      })
      .catch((error) => {
        const message =
          error.response.status === 403
            ? '提出制限がかかっています'
            : '提出に失敗しました。再度お試しください。';
        toast({ message: message, type: 'error' });
      })
      .finally(() => {
        dispatch(fetchAssignments({ year, month, userId: account.id }));
        dispatch(clearStatus());
        setImages([]);
      });
  };

  const handleDialogOpen = (font: Font) => {
    setIsDialogOpen(true);
    setDialogFont(font);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setDialogFont(undefined);
  };

  const handleChooseDeleteButton = () => {
    const assignmentId = dialogFont ? findAssignmentByFontId(dialogFont.id)?.id : undefined;
    handleDialogClose();
    if (!assignmentId) {
      toast({ message: '課題の削除に失敗しました。', type: 'error' });
      return;
    }
    dispatch(deleteAssignment(assignmentId));
  };

  return assignmentStatus.fetchStatusLabel === AsyncStatus.LOADING ? (
    <Loader />
  ) : (
    <Box
      sx={{
        px: [2, 0],
        py: 6,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mb: 4,
      }}
    >
      <Container
        maxWidth="lg"
        component="form"
        onSubmit={handleSubmit(handleSubmitForm)}
        sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}
      >
        {handbookStatus.bulkFetch === AsyncStatus.LOADING ? (
          <Loader />
        ) : isReadyHandbook ? (
          <Grid container spacing={{ xs: 3, md: 4 }}>
            {myCourseFontList.map(
              (font) =>
                findHandbookByFontId(font.id) && (
                  <Grid item xs={12} sm={6} md={4} key={font.id}>
                    <AssignmentUpload
                      font={font}
                      imageUrl={findAssignmentByFontId(font.id)?.image_url}
                      handleChangeImage={handleChangeImage}
                      handleDialogOpen={handleDialogOpen}
                    />
                  </Grid>
                )
            )}
          </Grid>
        ) : (
          <Typography sx={{ p: 10, textAlign: 'center', color: 'grey' }}>
            手本の登録がありません
          </Typography>
        )}
        <Box sx={{ my: [2, 3] }}>
          <Typography variant="h6" align="center" sx={{ fontWeight: 600, mb: 2, color: 'red' }}>
            【提出する前にご確認ください】
          </Typography>
          <Typography variant="body1" align="center" sx={{ mb: 1 }}>
            提出する課題の書体は一致していますか？
          </Typography>
          <Typography variant="body1" align="center">
            課題の登録し忘れはございませんか？
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <LoadingButton
            type="submit"
            variant="contained"
            disabled={
              !isReadyHandbook ||
              authStatus.fetch !== AsyncStatus.SUCCESS ||
              handbookStatus.bulkFetch !== AsyncStatus.SUCCESS ||
              assignmentStatus.bulkFetch !== AsyncStatus.SUCCESS
            }
            loading={
              assignmentStatus.create === AsyncStatus.LOADING ||
              assignmentStatus.update === AsyncStatus.LOADING
            }
          >
            提出
          </LoadingButton>
          <Box
            sx={{
              mt: 3,
              fontSize: 14,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <HelpOutlineIcon sx={{ mr: 1 }} />
            提出方法については
            <Link href={URL.HELP_ASSIGNMENT} target="_blank">
              こちら
            </Link>
          </Box>
        </Box>
      </Container>
      <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title">本当に削除しますか？</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            「{dialogFont?.name}」の課題を削除します。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>戻る</Button>
          <Button onClick={handleChooseDeleteButton} color="error" autoFocus>
            削除する
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Assignment;
