import { ChevronRight } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  Grid,
  Pagination,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Stack } from '@mui/system';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import Loader from '@/components/Loader';
import { BackLink } from '@/components/ui/BackLink';
import Chart from '@/components/wholeResult/details/Chart';
import OverallRank from '@/components/wholeResult/details/OverallRank';
import Score from '@/components/wholeResult/details/Score';
import useFonts from '@/hooks/useFonts';
import useRanks from '@/hooks/useRanks';
import { fetchOverallResults, fetchResults, resultSelector } from '@/services/resultSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { AsyncStatus, Nullable, URL } from '@/types';

type ViewType = { key: 'chart' | 'score'; label: string };

const resultsNumPerPage = 5;

const WholeResultDetails = () => {
  const { t } = useTranslation();
  const { rankList } = useRanks();

  const { results, overallResults, status } = useAppSelector(resultSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // Responsive themes
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  // URL params
  const { year, month, rankId, fontId } = useParams();
  // Target font
  const { fontList } = useFonts();
  const font = fontList.find((font) => font.id === Number(fontId));
  const targetResults = font ? results : overallResults;

  const viewTypes: ViewType[] = [
    {
      key: 'chart',
      label: font ? t('pages:whole_result.viewChart') : t('pages:whole_result.viewOverallResult'),
    },
    {
      key: 'score',
      label: font ? t('pages:whole_result.viewScore') : t('pages:whole_result.viewOverallRank'),
    },
  ];

  const [viewType, setViewType] = useState<ViewType>(viewTypes[0]);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    if (font) {
      dispatch(
        fetchResults({
          year: Number(year),
          month: Number(month),
          rankId: Number(rankId),
          fontId: Number(fontId),
          pageId: page,
        })
      );
    } else {
      dispatch(
        fetchOverallResults({
          year: Number(year),
          month: Number(month),
          rankId: Number(rankId),
          pageId: page,
        })
      );
    }
  }, [year, month, rankId, fontId, page]);

  const handleChangeYearMonth = (date: Nullable<Dayjs>) => {
    const year = Number(date?.format('YYYY'));
    const month = Number(date?.format('M'));

    if (!!year && !!month) {
      navigate(
        `${URL.WHOLE_RESULT}/${year}/${month}/rank/${rankId}/${
          results ? `font/${fontId}` : 'overall'
        }`
      );
    }
  };

  const handleChangeView = (event: React.MouseEvent<HTMLElement>, val: string) => {
    setViewType(viewTypes.find((type) => type.key === val) as ViewType);
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleClickAccount = (userId?: string) => {
    navigate(`${URL.RESULT}?user_id=${userId}`);
  };

  return (
    <Box sx={{ pb: 8, pt: [2, 4], maxWidth: 680, m: 'auto' }}>
      <Box sx={{ px: [2, 0] }}>
        {/* 戻るリンク */}
        <BackLink />

        {/* タイトル */}
        <Typography
          variant="h2"
          align="center"
          sx={{ mt: 2, mb: 4, fontSize: 20, fontWeight: 'bold' }}
        >
          {`${font?.name ?? '総合'} ${t('pages:whole_result.title')}`}
        </Typography>

        {/* 年月選択 */}
        <Box sx={{ mb: 4 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
            <DatePicker
              views={['year', 'month']}
              value={dayjs(`${year}-${month}`)}
              label={t('pages:whole_result.selectDate')}
              format="YYYY/MM"
              openTo="month"
              onChange={handleChangeYearMonth}
            />
          </LocalizationProvider>
        </Box>

        {(!!font && status.bulkFetch === AsyncStatus.SUCCESS) ||
        (!font && status.fetchOverallResults === AsyncStatus.SUCCESS) ? (
          targetResults?.items?.length ? (
            <>
              <Box sx={{ mb: 2 }}>
                {isMobile && (
                  <ToggleButtonGroup
                    color="primary"
                    value={viewType.key}
                    exclusive
                    sx={{ width: '100%', display: 'flex' }}
                    onChange={handleChangeView}
                  >
                    {viewTypes.map(({ key, label }) => (
                      <ToggleButton key={key} value={key} sx={{ flex: 1 }}>
                        {label}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                )}
              </Box>
              {/* 作品/グラフ or 成績 */}
              <Stack spacing={4} sx={{ mb: 8 }}>
                {!!font &&
                  results?.items?.map((result, i) => (
                    <Box
                      key={i}
                      sx={{
                        border: isMobile ? 0 : 1,
                        borderColor: 'grey.300',
                        borderRadius: 4,
                        p: [0, 1],
                      }}
                    >
                      {isMobile && i !== 0 && <Divider sx={{ mb: 2, width: '100%' }} />}

                      <Box>
                        {/* ユーザ情報 */}
                        <Button
                          variant="text"
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            color: 'initial',
                            textTransform: 'initial',
                            mb: 1,
                          }}
                          onClick={() => handleClickAccount(result.user?.id)}
                        >
                          <Stack direction="row" alignItems="center" gap={2}>
                            {/* 書体/段級位 */}
                            <Stack
                              justifyContent="center"
                              sx={{
                                borderWidth: 2,
                                borderColor: 'primary.main',
                                borderRadius: '100%',
                                borderStyle: 'solid',
                                color: 'primary.main',
                                height: 50,
                                width: 50,
                              }}
                            >
                              <Box sx={{ fontSize: 10 }}>{result?.font?.name}</Box>
                              <Box sx={{ fontSize: 14, fontWeight: 'bold' }}>
                                {result?.rank?.current?.name}
                              </Box>
                            </Stack>
                            {/* ユーザ名 */}
                            <Typography sx={{ fontWeight: 'bold' }}>{result.user?.name}</Typography>
                          </Stack>
                          <ChevronRight sx={{ color: 'grey.700' }} />
                        </Button>

                        {/* データ */}
                        <Grid
                          container
                          sx={{
                            border: isMobile && viewType.key === 'chart' ? 1 : 0,
                            borderColor: 'grey.300',
                            borderRadius: 4,
                            p: [0, 1],
                          }}
                        >
                          {/* 作品/グラフ */}
                          {isMobile && viewType.key === 'score' ? null : (
                            <Grid item xs={12} sm={6}>
                              <Chart item={result} />
                            </Grid>
                          )}
                          {/* 成績 */}
                          {isMobile && viewType.key === 'chart' ? null : (
                            <Grid item xs={12} sm={6}>
                              <Score item={result} />
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Box>
                  ))}
                {!font &&
                  overallResults?.items?.map((overallResult, i) => (
                    <Box
                      key={i}
                      sx={{
                        border: isMobile ? 0 : 1,
                        borderColor: 'grey.300',
                        borderRadius: 4,
                        p: [0, 1],
                      }}
                    >
                      {isMobile && i !== 0 && <Divider sx={{ mb: 2, width: '100%' }} />}

                      <Box>
                        {/* ユーザ情報 */}
                        <Button
                          variant="text"
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            color: 'initial',
                            textTransform: 'initial',
                            mb: 1,
                          }}
                          onClick={() => handleClickAccount(overallResult.user?.id)}
                        >
                          <Stack direction="row" alignItems="center" gap={2}>
                            {/* 書体/段級位 */}
                            <Stack
                              justifyContent="center"
                              sx={{
                                borderWidth: 2,
                                borderColor: 'primary.main',
                                borderRadius: '100%',
                                borderStyle: 'solid',
                                color: 'primary.main',
                                height: 50,
                                width: 50,
                              }}
                            >
                              <Box sx={{ fontSize: 10 }}>{'総合'}</Box>
                              <Box sx={{ fontSize: 14, fontWeight: 'bold' }}>
                                {rankList.find((rank) => rank.id === Number(rankId))?.rank}
                              </Box>
                            </Stack>
                            {/* ユーザ名 */}
                            <Typography sx={{ fontWeight: 'bold' }}>
                              {overallResult.user?.name}
                            </Typography>
                          </Stack>
                          <ChevronRight sx={{ color: 'grey.700' }} />
                        </Button>

                        {/* データ */}
                        <Grid
                          container
                          sx={{
                            border: isMobile && viewType.key === 'chart' ? 1 : 0,
                            borderColor: 'grey.300',
                            borderRadius: 4,
                            p: [0, 1],
                          }}
                        >
                          {/* 作品/グラフ */}
                          {isMobile && viewType.key === 'score' ? null : (
                            <Grid item xs={12} sm={6}>
                              <Chart item={overallResult} />
                            </Grid>
                          )}
                          {/* 成績 */}
                          {isMobile && viewType.key === 'chart' ? null : (
                            <Grid item xs={12} sm={6}>
                              <OverallRank item={overallResult} />
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Box>
                  ))}
              </Stack>
              {(targetResults?.total ?? 0) > resultsNumPerPage ? (
                <Stack alignItems="center">
                  <Pagination
                    count={Math.ceil((targetResults?.total ?? 0) / resultsNumPerPage)}
                    page={page}
                    size="large"
                    onChange={handleChangePage}
                  />
                </Stack>
              ) : null}
            </>
          ) : (
            <Box sx={{ m: 'auto', p: 20, textAlign: 'center' }}>
              該当者なし、もしくは、非公開ユーザーのため表示できる情報がありません
            </Box>
          )
        ) : (
          <Loader />
        )}
      </Box>
    </Box>
  );
};

export default WholeResultDetails;
