import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Classroom, ClassroomApi, InputClassroom } from '@/generated/types/typescript-axios';
import { RootState } from '@/store';
import { AsyncStatus } from '@/types';
import { ApiConfig } from '@/utils/apiConfig';

type classroomState = {
  classroom: Classroom | undefined;
  classrooms: Classroom[];
  status: {
    fetch: AsyncStatus;
    post: AsyncStatus;
  };
};

const initialState: classroomState = {
  classroom: undefined,
  classrooms: [],
  status: {
    fetch: AsyncStatus.IDLE,
    post: AsyncStatus.IDLE,
  },
};

export const fetchClassrooms = createAsyncThunk('fetchClassrooms', async (_, thunkAPI) => {
  try {
    const apiConfig = ApiConfig();
    const classroomsApi = new ClassroomApi(apiConfig);
    const { data } = await classroomsApi.getClassrooms();
    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const postClassroom = createAsyncThunk(
  'postClassroom',
  async ({ name, teacher_email, student_type }: InputClassroom, thunkAPI) => {
    try {
      const apiConfig = ApiConfig();
      const classroomsApi = new ClassroomApi(apiConfig);
      const { data } = await classroomsApi.postClassroom(
        {
          name,
          teacher_email,
          student_type,
        },
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const classroomSlice = createSlice({
  name: 'classroom',
  initialState,
  reducers: {
    clearStatus: (state: classroomState) => {
      state.status.fetch = AsyncStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClassrooms.pending, (state) => {
        state.status.fetch = AsyncStatus.LOADING;
      })
      .addCase(fetchClassrooms.fulfilled, (state, { payload }: { payload: Classroom[] }) => {
        state.classrooms = payload;
        state.status.fetch = AsyncStatus.SUCCESS;
      })
      .addCase(fetchClassrooms.rejected, (state) => {
        state.status.fetch = AsyncStatus.FAILED;
      })
      .addCase(postClassroom.pending, (state) => {
        state.status.post = AsyncStatus.LOADING;
      })
      .addCase(postClassroom.fulfilled, (state, { payload }: { payload: Classroom }) => {
        state.classroom = payload;
        state.status.post = AsyncStatus.SUCCESS;
      })
      .addCase(postClassroom.rejected, (state) => {
        state.status.post = AsyncStatus.FAILED;
      });
  },
});

export const { clearStatus } = classroomSlice.actions;

export const classroomSelector = (state: RootState) => state.classroom;

export default classroomSlice.reducer;
