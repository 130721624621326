import { Divider } from '@mui/material';
import { Box, Stack } from '@mui/system';
import React from 'react';

import { ReviewRankOrders } from '@/generated/types/typescript-axios';

type Props = {
  rank_order: ReviewRankOrders | undefined;
};

export default function OrderContent({ rank_order }: Props) {
  const sameRankOrder = rank_order?.same_rank_order?.order ?? 0;
  const wholeRankOrder = rank_order?.whole_order?.order ?? 0;
  const isNoOrder = !sameRankOrder && !wholeRankOrder;

  return !isNoOrder ? (
    <Stack direction="row" alignItems="center" gap={1} sx={{ fontSize: 24 }}>
      {/* 全体順位 */}
      <Stack direction="row" alignItems="baseline" sx={{ color: 'grey.400' }}>
        <Box component="span" sx={{ fontSize: 10 }}>
          全体順位
        </Box>
        <Box component="span" sx={{ color: 'initial', marginLeft: 1 }}>
          {wholeRankOrder}
        </Box>
        <Box component="span" sx={{ fontWeight: 'normal' }}>
          位
        </Box>
      </Stack>

      <Divider orientation="vertical" flexItem />

      {/* 同位順位 */}
      <Stack direction="row" alignItems="baseline" sx={{ color: 'grey.400' }}>
        <Box component="span" sx={{ fontSize: 10 }}>
          同位順位
        </Box>
        <Box component="span" sx={{ color: 'initial', marginLeft: 1 }}>
          {sameRankOrder}
        </Box>
        <Box component="span" sx={{ fontWeight: 'normal' }}>
          位
        </Box>
      </Stack>
    </Stack>
  ) : (
    <Box sx={{ color: 'grey.400' }}>---</Box>
  );
}
