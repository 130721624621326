import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { LatestResults, ResultApi } from '@/generated/types/typescript-axios';
import { RootState } from '@/store';
import { AsyncStatus } from '@/types';
import { ApiConfig } from '@/utils/apiConfig';

type TimelineState = {
  timeline: LatestResults | undefined;
  status: { fetch: AsyncStatus };
};

const initialState: TimelineState = {
  timeline: undefined,
  status: {
    fetch: AsyncStatus.IDLE,
  },
};

export const fetchTimeline = createAsyncThunk('fetchTimeline', async (_, thunkAPI) => {
  try {
    const apiConfig = ApiConfig();
    const resultApi = new ResultApi(apiConfig);
    const { data } = await resultApi.getLatestResults();
    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const timelineSlice = createSlice({
  name: 'timeline',
  initialState,
  reducers: {
    clearStatus: (state: TimelineState) => {
      state.status.fetch = AsyncStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTimeline.pending, (state) => {
        state.status.fetch = AsyncStatus.LOADING;
      })
      .addCase(fetchTimeline.fulfilled, (state, { payload }: { payload: LatestResults }) => {
        state.timeline = payload;
        state.status.fetch = AsyncStatus.SUCCESS;
      })
      .addCase(fetchTimeline.rejected, (state) => {
        state.status.fetch = AsyncStatus.FAILED;
      });
  },
});

export const { clearStatus } = timelineSlice.actions;

export const timelineSelector = (state: RootState) => state.timeline;

export default timelineSlice.reducer;
