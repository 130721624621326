import { ChevronRight } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Stack } from '@mui/system';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';

import Loader from '@/components/Loader';
import { BackLink } from '@/components/ui/BackLink';
import Chart from '@/components/wholeResult/details/Chart';
import Score from '@/components/wholeResult/details/Score';
import GradeSelectionModal from '@/components/wholeResult/GradeSelectionModal';
import { findGrade, GradeList } from '@/const/grade';
import useFonts from '@/hooks/useFonts';
import useGrade from '@/hooks/useGrade';
import { authSelector } from '@/services/authSlice';
import { fetchHighScoreResults, resultSelector } from '@/services/resultSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { AsyncStatus, Nullable, URL } from '@/types';

type ViewType = { key: 'chart' | 'score'; label: string };

const HighScoresResult = () => {
  const { t } = useTranslation();
  const { highResults, status } = useAppSelector(resultSelector);
  const dispatch = useAppDispatch();
  const { account } = useAppSelector(authSelector);
  const { getGradeObjectByBirthday } = useGrade();
  const navigate = useNavigate();
  // Responsive themes
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  // URL params
  const { year, month, fontId } = useParams();
  const location = useLocation();
  // Target font
  const { fontList } = useFonts();
  const font = fontList.find((font) => font.id === Number(fontId));

  const adultId = 0;
  const studentCourseType = 2;
  const preSchoolFontId = 1;

  const viewTypes: ViewType[] = [
    { key: 'chart', label: t('pages:whole_result.viewChart') },
    { key: 'score', label: t('pages:whole_result.viewScore') },
  ];
  const [viewType, setViewType] = useState<ViewType>(viewTypes[0]);

  const courseInfo = getGradeObjectByBirthday(dayjs(account?.birthday ?? ''));
  const getSelectGradeId = (courseInfoId: number) => {
    const query = new URLSearchParams(location.search);
    const gradeIdParam = query.get('grade_id');
    const gradeId = gradeIdParam ? parseInt(gradeIdParam, 10) : undefined;
    return gradeId ? gradeId : courseInfoId === adultId ? preSchoolFontId : courseInfoId;
  };

  const [selectGrade, setSelectGrade] = useState<number>(getSelectGradeId(courseInfo.id));
  const [foundGrade, setFoundGrade] = useState<{ label: string; value: string } | undefined>();

  const [showModal, setShowModal] = useState<boolean>(false);
  useEffect(() => {
    handleCloseModal();
    setSelectGrade(getSelectGradeId(courseInfo.id));
  }, [location]);

  useEffect(() => {
    const result = findGrade(GradeList, selectGrade);
    setFoundGrade(result);
  }, [selectGrade]);

  useEffect(() => {
    dispatch(
      fetchHighScoreResults({
        year: Number(year),
        month: Number(month),
        fontId: Number(fontId),
        gradeId: font?.course_type === studentCourseType ? selectGrade : undefined,
      })
    );
    handleCloseModal();
  }, [year, month, fontId, selectGrade]);

  const handleChangeYearMonth = (date: Nullable<Dayjs>) => {
    const year = date?.year();
    const month = (date?.month() ?? 0) + 1;
    navigate(
      `${URL.WHOLE_RESULT}/high-scores/${year}/${month}/font/${fontId}` +
        (font?.course_type === studentCourseType ? `?grade_id=${selectGrade}` : '')
    );
  };

  const handleChangeView = (event: React.MouseEvent<HTMLElement>, val: string) => {
    setViewType(viewTypes.find((type) => type.key === val) as ViewType);
  };

  const handleClickAccount = (userId?: string) => {
    navigate(`${URL.RESULT}?user_id=${userId}`);
  };

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <Box sx={{ pb: 8, pt: [2, 4], maxWidth: 680, m: 'auto' }}>
      <Box sx={{ px: [2, 0] }}>
        {/* 戻るリンク */}
        <BackLink />

        {/* タイトル */}
        <Typography
          variant="h2"
          align="center"
          sx={{ mt: 2, mb: 4, fontSize: 20, fontWeight: 'bold' }}
        >
          {`${font?.name} ${t('pages:whole_result.highScoresResult')}`}
        </Typography>

        {/* 年月選択 */}
        <Box sx={{ mb: 4 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
            <DatePicker
              views={['year', 'month']}
              value={dayjs(`${year}-${month}`)}
              label={t('pages:whole_result.selectDate')}
              format="YYYY/MM"
              openTo="month"
              onChange={handleChangeYearMonth}
            />
          </LocalizationProvider>
        </Box>
        <Box sx={{ mb: 5 }}>
          {isMobile && (
            <ToggleButtonGroup
              color="primary"
              value={viewType.key}
              exclusive
              sx={{ width: '100%', display: 'flex' }}
              onChange={handleChangeView}
            >
              {viewTypes.map(({ key, label }) => (
                <ToggleButton key={key} value={key} sx={{ flex: 1 }}>
                  {label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          )}
        </Box>
        {font?.course_type === studentCourseType && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>
              {foundGrade?.label} {foundGrade?.value}
            </Typography>
            <Button onClick={handleOpenModal}>他の学年を見る</Button>
          </Box>
        )}
        {status.fetchHighResults === AsyncStatus.SUCCESS ? (
          highResults.length ? (
            <>
              {/* 作品/グラフ or 成績 */}
              <Stack spacing={4} sx={{ mb: 8 }}>
                {highResults?.map((result, i) => (
                  <Box
                    key={i}
                    sx={{
                      border: isMobile ? 0 : 1,
                      borderColor: 'grey.300',
                      borderRadius: 4,
                      p: [0, 1],
                    }}
                  >
                    {isMobile && i !== 0 && <Divider sx={{ mb: 2, width: '100%' }} />}

                    <Box>
                      {/* ユーザ情報 */}
                      <Button
                        variant="text"
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                          color: 'initial',
                          textTransform: 'initial',
                          mb: 1,
                        }}
                        onClick={() => handleClickAccount(result.user?.id)}
                      >
                        <Stack direction="row" alignItems="center" gap={2}>
                          {/* 書体/段級位 */}
                          <Stack
                            justifyContent="center"
                            sx={{
                              borderWidth: 2,
                              borderColor: 'primary.main',
                              borderRadius: '100%',
                              borderStyle: 'solid',
                              color: 'primary.main',
                              height: 50,
                              width: 50,
                            }}
                          >
                            <Box sx={{ fontSize: 10 }}>{result?.font?.name}</Box>
                            <Box sx={{ fontSize: 14, fontWeight: 'bold' }}>
                              {result?.rank?.current?.name}
                            </Box>
                          </Stack>
                          {/* ユーザ名 */}
                          <Typography sx={{ fontWeight: 'bold' }}>{result.user?.name}</Typography>
                        </Stack>
                        <ChevronRight sx={{ color: 'grey.700' }} />
                      </Button>

                      {/* データ */}
                      <Grid
                        container
                        sx={{
                          border: isMobile && viewType.key === 'chart' ? 1 : 0,
                          borderColor: 'grey.300',
                          borderRadius: 4,
                          p: [0, 1],
                        }}
                      >
                        {/* 作品/グラフ */}
                        {isMobile && viewType.key === 'score' ? null : (
                          <Grid item xs={12} sm={6}>
                            <Chart item={result} />
                          </Grid>
                        )}
                        {/* 成績 */}
                        {isMobile && viewType.key === 'chart' ? null : (
                          <Grid item xs={12} sm={6}>
                            <Score item={result} />
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </Box>
                ))}
              </Stack>
            </>
          ) : (
            <Box sx={{ m: 'auto', p: 20, textAlign: 'center' }}>作品がありません</Box>
          )
        ) : (
          <Loader />
        )}
      </Box>
      <GradeSelectionModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        isMobile={isMobile}
      />
    </Box>
  );
};

export default HighScoresResult;
