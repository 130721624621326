import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
dayjs.extend(isBetween);
import 'dayjs/locale/ja';

import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTranslation } from 'react-i18next';

import useAccount from '@/hooks/useAccount';
import useGrade from '@/hooks/useGrade';
import { authSelector } from '@/services/authSlice';
import { useAppSelector } from '@/store/hooks';
import { RegisterForm, URL } from '@/types';

const isStudentAge = (birthDate: Dayjs) => {
  const today = dayjs();
  const displayRangeBirthday = today.subtract(16, 'year').month(3).date(1).format('YYYY/MM/DD');

  return birthDate.isAfter(displayRangeBirthday);
};

const isAvailableBirthday = (birthDate: Dayjs) => {
  const today = dayjs();

  return birthDate.isBefore(today);
};

export default function SignUpRegistration() {
  const { t } = useTranslation();
  const { defaultValues, experienceList, genderList, prefectureList, validationRules } =
    useAccount();
  const { account } = useAppSelector(authSelector);
  const today = dayjs();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { getGradeObjectByBirthday } = useGrade();
  const { control, handleSubmit, setValue } = useForm<RegisterForm>({
    defaultValues,
    mode: 'onChange',
  });

  const [isStudent, setIsStudent] = useState<boolean>(false);
  const [isRegisteredBirthdayDone, setIsRegisteredBirthdayDone] = useState<boolean>(false);
  const [grade, setGrade] = useState<string>('');

  useEffect(() => {
    if (state) {
      const birthday = dayjs(state.birthday);
      setValue('nickname', state.nickname);
      setValue('first_name', state.first_name);
      setValue('last_name', state.last_name);
      setValue('gender_id', state.gender.value);
      setValue(
        'prefecture',
        `${prefectureList.find(({ label }) => label === state.prefecture)?.value}`
      );
      setValue('is_experienced', `${state.is_experienced.value}`);
      setValue('parent_first_name', state.parent_first_name);
      setValue('parent_last_name', state.parent_last_name);
      setValue('birthday', dayjs(state.birthday));
      setGradeInfo(birthday);
    }
  }, [state]);

  const setGradeInfo = (date: Dayjs) => {
    const isStudent = isStudentAge(date);
    setIsStudent(isStudent);
    setIsRegisteredBirthdayDone(isAvailableBirthday(date));
    setGrade(getGradeObjectByBirthday(date).label);
    if (!isStudent) {
      setValue('parent_first_name', '');
      setValue('parent_last_name', '');
    }
  };

  const onChangeBirthDate = (date: Dayjs) => {
    setGradeInfo(date);

    if (!isStudentAge(date)) {
      setValue('parent_first_name', '');
      setValue('parent_last_name', '');
    }
  };

  const onSubmitForm = ({
    gender_id,
    birthday,
    is_experienced,
    prefecture,
    ...others
  }: RegisterForm) => {
    // Create実行
    navigate(URL.SIGN_UP_CONFIRMATION, {
      state: {
        ...others,
        email: account?.email,
        course_type: isStudent
          ? { value: 2, label: t('pages:sign_up:labels:course_student') }
          : { value: 1, label: t('pages:sign_up:labels:course_adult') },
        is_experienced: {
          value: is_experienced === 'true',
          label: experienceList.find(({ value }) => value === is_experienced)?.label,
        },
        gender: {
          value: Number(gender_id),
          label: genderList.find(({ value }) => value === gender_id)?.label,
        },
        prefecture: prefectureList.find(({ value }) => value === +prefecture)?.label,
        birthday: (birthday as Dayjs).format('YYYY-MM-DD'),
      },
    });
  };

  return (
    <Container maxWidth="sm" sx={{ py: [6, 10] }}>
      <Typography variant="h5" textAlign="center" sx={{ fontWeight: 'bold', mb: 6 }}>
        {t('pages:sign_up:title')}
      </Typography>

      <Box component="form" noValidate onSubmit={handleSubmit(onSubmitForm)}>
        <Stack spacing={3} sx={{ mb: 6 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
            <Typography sx={{ mb: 2 }}>{t('pages:sign_up:labels:email')}</Typography>
            <Typography>{account?.email}</Typography>
          </Box>

          {/* ユーザー名 */}
          <Controller
            name="nickname"
            control={control}
            rules={validationRules.nickname}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                type="text"
                label={t('pages:sign_up:labels:nickname')}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
          />

          {/* 姓 */}
          <Box sx={{ display: 'flex', gap: 3, mb: 2 }}>
            <Controller
              name="last_name"
              control={control}
              rules={validationRules.last_name}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  type="text"
                  label={t('pages:sign_up:labels:last_name')}
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                />
              )}
            />

            {/* 名 */}
            <Controller
              name="first_name"
              control={control}
              rules={validationRules.first_name}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  type="text"
                  label={t('pages:sign_up:labels:first_name')}
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Box>

          {/* 性別 */}
          <Controller
            name="gender_id"
            control={control}
            rules={validationRules.gender_id}
            render={({ field, fieldState }) => (
              <FormControl fullWidth error={!!fieldState.error?.message}>
                <InputLabel id="area-label">{t('pages:sign_up:labels:gender')}</InputLabel>
                <Select {...field} labelId="area-label" label={t('pages:sign_up:labels:gender')}>
                  {genderList.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{fieldState.error?.message}</FormHelperText>
              </FormControl>
            )}
          />

          {/* 都道府県 */}
          <Controller
            name="prefecture"
            control={control}
            rules={validationRules.prefecture}
            render={({ field, fieldState }) => (
              <FormControl fullWidth error={!!fieldState.error?.message}>
                <InputLabel id="area-label">{t('pages:sign_up:labels:prefecture')}</InputLabel>
                <Select
                  {...field}
                  labelId="area-label"
                  label={t('pages:sign_up:labels:prefecture')}
                >
                  {prefectureList.map(({ label, value }: any) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{fieldState.error?.message}</FormHelperText>
              </FormControl>
            )}
          />

          {/* 生年月日 */}
          <Controller
            name="birthday"
            control={control}
            rules={validationRules.birthday}
            render={({ field, fieldState }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                <DatePicker
                  {...field}
                  label={t('pages:sign_up:labels:birthday')}
                  format="YYYY/MM/DD"
                  maxDate={today}
                  slotProps={{
                    textField: {
                      error: !!fieldState.error?.message,
                      helperText: fieldState.error?.message,
                    },
                  }}
                  onChange={(date) => {
                    field.onChange(date);
                    onChangeBirthDate(date ?? dayjs());
                  }}
                />
              </LocalizationProvider>
            )}
          />

          {/* 保護者氏名 */}
          {isStudent && (
            <div>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  mb: 2,
                  border: 1,
                  borderColor: 'grey.500',
                  borderRadius: '5px',
                  p: 2,
                }}
              >
                <Box sx={{ display: 'flex', mb: 2 }}>
                  <Typography>{t('pages:sign_up:labels:grade')}：</Typography>
                  <Typography>{grade}</Typography>
                </Box>
                <Typography>{t('pages:sign_up:labels:parent_name')}</Typography>
                <Box sx={{ display: 'flex', gap: 3, my: 1 }}>
                  <Controller
                    name="parent_last_name"
                    control={control}
                    rules={validationRules.parent_last_name}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        type="text"
                        label="姓"
                        error={!!fieldState.error?.message}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                  <Controller
                    name="parent_first_name"
                    control={control}
                    rules={validationRules.parent_first_name}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        type="text"
                        label="名"
                        error={!!fieldState.error?.message}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Box>
              </Box>
            </div>
          )}

          {/* 書道経験 */}
          <Controller
            name="is_experienced"
            control={control}
            rules={validationRules.is_experienced}
            render={({ field, fieldState }) => (
              <FormControl fullWidth error={!!fieldState.error?.message} sx={{ mb: 2 }}>
                <InputLabel id="area-label">{t('pages:sign_up:labels:experience')}</InputLabel>
                <Select
                  {...field}
                  labelId="area-label"
                  label={t('pages:sign_up:labels:experience')}
                >
                  {experienceList.map((item) => (
                    <MenuItem key={item.id} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{fieldState.error?.message}</FormHelperText>
              </FormControl>
            )}
          />

          {isRegisteredBirthdayDone && (
            <div>
              <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
                <Typography sx={{ mb: 2 }}>{t('pages:sign_up:labels:your_course')}</Typography>{' '}
                <Typography sx={{ fontWeight: 'bold' }} defaultValue="">
                  {' '}
                  {isStudent ? (
                    <span>{t('pages:sign_up:labels:course_student')}</span>
                  ) : (
                    <span>{t('pages:sign_up:labels:course_adult')}</span>
                  )}
                </Typography>{' '}
              </Box>
            </div>
          )}
        </Stack>

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button type="submit" color="primary" variant="contained">
            {t('pages:sign_up:buttons:next')}
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
