import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { DateApi } from '@/generated/types/typescript-axios';
import { RootState } from '@/store';
import { AsyncStatus } from '@/types';
import { ApiConfig } from '@/utils/apiConfig';

type DateState = {
  date: string | undefined;
  status: { fetch: AsyncStatus };
};

const initialState: DateState = {
  date: undefined,
  status: {
    fetch: AsyncStatus.IDLE,
  },
};

export const fetchDate = createAsyncThunk('fetchDate', async (_, thunkAPI) => {
  try {
    const apiConfig = ApiConfig();
    const dateApi = new DateApi(apiConfig);
    const { data: data } = await dateApi.getDate();
    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const dateSlice = createSlice({
  name: 'date',
  initialState,
  reducers: {
    clearStatus: (state: DateState) => {
      state.status.fetch = AsyncStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDate.pending, (state) => {
        state.status.fetch = AsyncStatus.LOADING;
      })
      .addCase(fetchDate.fulfilled, (state, { payload }: { payload: string }) => {
        state.date = payload;
        state.status.fetch = AsyncStatus.SUCCESS;
      })
      .addCase(fetchDate.rejected, (state) => {
        state.status.fetch = AsyncStatus.FAILED;
      });
  },
});

export const { clearStatus } = dateSlice.actions;

export const dateSelector = (state: RootState) => state.date;

export default dateSlice.reducer;
