import { Box, Stack } from '@mui/material';
import React from 'react';

import { fontList } from '@/const/result';
import { OverallResult } from '@/generated/types/typescript-axios/api';
import { FontTypeIDs } from '@/types';

import ResultItem from './ResultItem';

type Props = {
  item: OverallResult;
};

export default function OverallRank({ item }: Props) {
  return (
    <Stack
      gap={1}
      sx={{
        pl: [0, 1],
      }}
    >
      {item?.results
        ?.filter(
          (result) =>
            ![FontTypeIDs.KANA, FontTypeIDs.JOFUKU_ADULT].includes(result?.font?.id as number)
        )
        .map(({ font, rank }) => (
          <ResultItem
            title={font?.name}
            key={font?.id}
            disabled={!rank}
            color={`${fontList.find(({ fontId }) => fontId === font?.id)?.color}`}
          >
            <Box sx={{ fontSize: 24, color: rank ? '' : 'grey.400' }} component="span">
              {rank ? rank.name : '未提出'}
            </Box>
          </ResultItem>
        ))}
    </Stack>
  );
}
