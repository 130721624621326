import { Box, Stack } from '@mui/system';
import React from 'react';

import { ResultAssignmentsInnerScoresInner } from '@/generated/types/typescript-axios';

type Props = {
  scores: ResultAssignmentsInnerScoresInner[] | undefined;
  totalScore: number | undefined;
};

export default function ScoreContent({ scores, totalScore }: Props) {
  return (
    <>
      {/* スコア一覧 */}
      {totalScore ? (
        <Stack direction="row" alignItems="baseline" sx={{ color: 'grey.400', fontSize: 24 }}>
          <Stack direction="row" alignItems="center" gap={1}>
            <Box
              component="span"
              sx={{
                color:
                  scores?.length !== undefined && totalScore === scores.length * 10
                    ? 'success.light'
                    : totalScore <= 10
                    ? 'error.light'
                    : 'warning.light',
              }}
            >
              {totalScore}
            </Box>
          </Stack>
          /{scores?.length !== undefined && scores.length * 10}
        </Stack>
      ) : (
        <Box sx={{ color: 'grey.400', fontWeight: 'normal' }}>未提出</Box>
      )}
    </>
  );
}
