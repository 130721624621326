import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import React from 'react';
import { Bar } from 'react-chartjs-2';

import { ResultsAmountRankInner } from '@/generated/types/typescript-axios';
import useRanks from '@/hooks/useRanks';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

type Props = {
  courseType: 'adult' | 'student';
  ranks: ResultsAmountRankInner[];
  userRankOrder: number | undefined;
};

const BarChart = ({ courseType, ranks, userRankOrder }: Props) => {
  const theme = useTheme();
  // https://mui.com/material-ui/react-use-media-query/
  // https://mui.com/material-ui/customization/breakpoints/#usemediaquery-hook
  const isVertical = useMediaQuery(theme.breakpoints.up('md'));
  const { adultRankNames, studentRankNames } = useRanks();

  const labels = (courseType: 'adult' | 'student') => {
    switch (courseType) {
      case 'adult':
        return adultRankNames;
      case 'student':
        return studentRankNames;
      default:
        return [];
    }
  };

  const rankAxisOption = {
    grid: {
      display: false,
    },
    ticks: {
      autoSkip: false,
      maxRotation: 0,
    },
  };

  const peopleAxisOption = {
    ticks: {
      display: false,
    },
    border: {
      display: false,
    },
  };

  const options = {
    indexAxis: isVertical ? ('x' as const) : ('y' as const),
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: isVertical ? rankAxisOption : peopleAxisOption,
      y: isVertical ? peopleAxisOption : rankAxisOption,
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        anchor: 'end' as const,
        align: 'end' as const,
        padding: 0,
        font: ranks.map(({ order }) => ({ weight: order === userRankOrder ? 'bold' : 'normal' })),
      },
    },
  };

  const data = {
    labels: labels(courseType),
    datasets: [
      {
        label: '',
        data: [...labels(courseType)].reverse().map((_, index) => {
          return (
            ranks.find(({ order }) => order === labels(courseType).length - index)?.amount ?? 0
          );
        }),
        borderColor: ranks.map(({ order }) => (order === userRankOrder ? '#1e88e5' : '#93c6f3')),
        backgroundColor: '#93c6f3',
        borderWidth: 2,
        borderSkipped: false,
      },
    ],
  };

  return <Bar options={options} data={data} />;
};

export default BarChart;
