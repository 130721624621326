import CancelIcon from '@mui/icons-material/Cancel';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Box, Grid, IconButton, InputLabel } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useState } from 'react';

import { useToast } from '@/components/ToastProvider';

type Props = {
  videos: File[];
  videoUrls: string[];
  setVideos: React.Dispatch<React.SetStateAction<File[]>>;
};

export const VideoUpload = ({ videos, videoUrls, setVideos }: Props) => {
  const { toast } = useToast();
  const [isDroppable, setIsDroppable] = useState<boolean>(false);

  const handleOnAddVideo = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    setVideos([...videos, ...e.target.files]);
  };

  const handleOnRemoveVideo = (index: number) => {
    const newVideos = [...videos];
    newVideos.splice(index, 1);
    setVideos(newVideos);
  };

  const handleDragEventCanceller = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: any) => {
    handleDragEventCanceller(e);
    const inputFiles = e.dataTransfer.files;

    for (let i = 0; i < inputFiles.length; i++) {
      if (inputFiles[i].type.indexOf('video') == -1) {
        toast({
          message: '動画以外アップロードできません。',
          type: 'error',
        });
        return;
      }
    }

    if (inputFiles && inputFiles[0]) {
      setVideos([...videos, ...inputFiles]);
    }
    setIsDroppable(false);
  };

  const onDragEnter = () => {
    setIsDroppable(true);
  };

  const onDragLeave = () => {
    setIsDroppable(false);
  };

  const PreviewContainer = ({
    idx,
    url,
    isNew = false,
  }: {
    idx: number;
    url: string;
    isNew?: boolean;
  }) => (
    <Grid item key={idx} xs={6} sm={3}>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100px',
          borderStyle: 'solid',
          borderColor: grey[300],
        }}
      >
        {isNew ? (
          <>
            <IconButton
              aria-label="delete Video"
              style={{
                position: 'absolute',
                top: 3,
                right: 3,
                color: '#aaa',
                padding: 0,
                zIndex: 1,
              }}
              onClick={() => handleOnRemoveVideo(idx)}
            >
              <CancelIcon />
            </IconButton>
            <video
              style={{
                maxWidth: '100%',
                maxHeight: '100px',
              }}
              src={url}
              controls
            ></video>
          </>
        ) : (
          <iframe src={url} className="tw-w-full tw-h-full tw-border-none" allowFullScreen></iframe>
        )}
      </Box>
    </Grid>
  );

  return (
    <>
      <Box
        sx={{
          borderStyle: 'solid',
          borderColor: grey[300],
          borderRadius: 1,
          '&:hover': {
            borderColor: grey[700],
          },
          marginBottom: 1,
          marginTop: 1,
        }}
        onDrop={handleDrop}
        onDragOver={handleDragEventCanceller}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
      >
        <InputLabel
          sx={{
            backgroundColor: isDroppable ? grey[400] : grey[200],
            borderStyle: 'dashed',
            borderColor: isDroppable ? grey[500] : grey[300],
            py: 5,
            textAlign: 'center',
            whiteSpace: 'normal',
            fontSize: {
              md: '16px',
              xs: '12px',
            },
          }}
          htmlFor="videoInput"
        >
          <input
            id="videoInput"
            type="file"
            multiple
            accept="video/*"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnAddVideo(e)}
            style={{ display: 'none' }}
          />
          <FileUploadIcon fontSize="large" />
          <br />
          ここにファイルをドラッグアンドドロップするか
          <br />
          クリックしてファイルを選択してください
        </InputLabel>
      </Box>

      <Grid container rowSpacing={1} columnSpacing={1}>
        {videos.length
          ? videos.map((item, i) => (
              <PreviewContainer key={i} idx={i} url={URL.createObjectURL(item)} isNew />
            ))
          : videoUrls.map((item, i) => (
              <PreviewContainer key={i} idx={i} url={item + '?' + Date.now()} />
            ))}
      </Grid>
    </>
  );
};
