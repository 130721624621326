import { Button, ButtonGroup, Grid } from '@mui/material';
import { Container } from '@mui/material';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/system';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  PointElement,
  RadialLinearScale,
  Tooltip,
} from 'chart.js';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect } from 'react';
import { Radar } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';

import Loader from '@/components/Loader';
import CommentContent from '@/components/result/contents/CommentContent';
import Heading from '@/components/result/Heading';
import ResultList from '@/components/result/ResultList';
import { BackLink } from '@/components/ui/BackLink';
import { radarChartOptions } from '@/const/result';
import useResult from '@/hooks/useResult';
import { authSelector } from '@/services/authSlice';
import { fetchFonts, fontSelector } from '@/services/fontSlice';
import { clearStatus, fetchResult, resultSelector } from '@/services/resultSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { AsyncStatus, CourseId, FontTypeIDs, Nullable } from '@/types';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const displayTypes = [
  { key: 1, name: '成績' },
  { key: 2, name: '段級位' },
  { key: 3, name: '全体順位' },
];

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '1px solid #aaa',
  boxShadow: 24,
  p: 2,
  textAlign: 'center',
};

export default function Result() {
  const { account } = useAppSelector(authSelector);
  const { result, status } = useAppSelector(resultSelector);
  const { fonts } = useAppSelector(fontSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // 表示対象年月(パラメータ or 現在？前月？)
  const {
    targetMonth,
    targetYear,
    targetFontId,
    displayType,
    showChart,
    showModal,
    searchParams,
    setTargetMonth,
    setTargetYear,
    setTargetFontId,
    setDisplayType,
    setShowChart,
    setShowModal,
    targetFontResult,
    isStudent,
    chartData,
  } = useResult({ result });

  useEffect(() => {
    if (isStudent) {
      dispatch(fetchFonts(CourseId.STUDENT));
    } else {
      dispatch(fetchFonts(CourseId.ADULT));
    }
    dispatch(
      fetchResult({
        year: Number(targetYear),
        month: Number(targetMonth),
        userId: searchParams.get('user_id') ?? account.id,
      })
    );
  }, [targetYear, targetMonth, searchParams, isStudent]);

  useEffect(() => {
    if (status.fetch === AsyncStatus.FAILED) {
      dispatch(clearStatus());
      navigate('/');
    }
  }, [status.fetch, navigate]);

  const handleChangeYearMonth = (date: Nullable<Dayjs>) => {
    const year = Number(date?.format('YYYY'));
    const month = Number(date?.format('M'));

    if (!!year && !!month) {
      setTargetYear(year);
      setTargetMonth(month);
    }
  };

  const handleClickFont = (fontId: number) => {
    setTargetFontId(fontId);
    handleShowImage();
  };

  const handleClickDisplayType = (displayKey: number) => {
    setDisplayType(displayKey);
  };

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleShowImage = () => {
    setShowChart(false);
  };
  const handleClickShowChartSwitcher = () => {
    setShowChart(!showChart);
  };

  return (
    <Box sx={{ px: [1, 4], py: [4], bgcolor: 'background.paper', maxWidth: 1200, m: 'auto' }}>
      <BackLink />

      {/* 年月選択 */}
      <Box sx={{ mb: 5 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
          <DatePicker
            views={['year', 'month']}
            value={dayjs(`${targetYear}-${targetMonth}`)}
            label="年月を選択"
            format="YYYY/MM"
            onChange={handleChangeYearMonth}
          />
        </LocalizationProvider>
      </Box>

      <Heading
        fontCases={fonts}
        nickname={result?.user?.nickname ?? ''}
        results={result}
        targetFontId={targetFontId}
        handleClickFont={handleClickFont}
      />
      {status.fetch === AsyncStatus.LOADING || status.fetch === AsyncStatus.FAILED ? (
        <Loader />
      ) : (
        <Grid container spacing={2}>
          {/* ボタングループ */}
          {!targetFontResult && (
            <Grid item xs={12}>
              <ButtonGroup variant="outlined" sx={{ width: '100%' }}>
                {displayTypes.map(({ key, name }) => (
                  <Button
                    variant={displayType === key ? 'contained' : 'outlined'}
                    key={key}
                    sx={{
                      border: 1,
                      borderColor: 'grey.400',
                      borderRadius: 2,
                      color: displayType === key ? 'white' : 'initial',
                      fontWeight: 'bold',
                      height: '100%',
                      width: '100%',
                      p: [1, 2],
                    }}
                    onClick={() => handleClickDisplayType(key)}
                  >
                    {name}
                  </Button>
                ))}
              </ButtonGroup>
            </Grid>
          )}
          {/* Radarチャート */}
          <Grid item xs={12} md={4}>
            <Box sx={{ position: 'relative' }}>
              {targetFontResult && targetFontResult?.font?.id != FontTypeIDs.RINSHO ? (
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ position: 'absolute', top: 8, left: 8, bgcolor: 'white !important' }}
                  onClick={handleClickShowChartSwitcher}
                >
                  {showChart ? '提出した画像を見る' : 'チャートを見る'}
                </Button>
              ) : null}
              {!targetFontResult ||
              (targetFontResult?.font?.id != FontTypeIDs.RINSHO && showChart) ? (
                <Container disableGutters={true}>
                  <Radar data={chartData} options={radarChartOptions} width="100%" />
                </Container>
              ) : (
                <Container sx={{ textAlign: 'center', height: '100%', minHeight: '320px' }}>
                  <img
                    src={targetFontResult?.image_url}
                    style={{
                      maxWidth: '100%',
                      maxHeight: '320px',
                    }}
                  />
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{ position: 'absolute', bottom: 8, right: 8, bgcolor: 'white !important' }}
                    onClick={handleOpenModal}
                  >
                    拡大
                  </Button>
                </Container>
              )}
            </Box>
          </Grid>
          {targetFontId !== FontTypeIDs.RINSHO && (
            <Grid item xs={12} md={8}>
              <ResultList
                displayType={displayType}
                result={result}
                fonts={fonts}
                targetFontResult={targetFontResult}
                isStudent={isStudent}
                handleClickItem={handleClickFont}
              />
            </Grid>
          )}

          {/* 審査所感 */}
          {!!targetFontResult && <CommentContent targetFontResult={targetFontResult} />}
        </Grid>
      )}

      <Modal open={showModal} onClose={handleCloseModal}>
        <Box sx={modalStyle}>
          <img
            src={targetFontResult?.image_url}
            style={{
              maxWidth: '100%',
              maxHeight: '640px',
            }}
          />
        </Box>
      </Modal>
    </Box>
  );
}
