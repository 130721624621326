import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FontApi, FontsInner } from '@/generated/types/typescript-axios';
import { RootState } from '@/store';
import { AsyncStatus, CourseId, Nullable } from '@/types';
import { ApiConfig } from '@/utils/apiConfig';

type FontState = {
  fonts: FontsInner[];
  status: { fetch: AsyncStatus };
};

const initialState: FontState = {
  fonts: [],
  status: {
    fetch: AsyncStatus.IDLE,
  },
};

export const fetchFonts = createAsyncThunk(
  'fetchFonts',
  async (courseType: Nullable<CourseId>, thunkAPI) => {
    try {
      const apiConfig = ApiConfig();
      const fontsApi = new FontApi(apiConfig);
      const { data } = await fontsApi.getFonts(courseType ?? undefined);
      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fontSlice = createSlice({
  name: 'font',
  initialState,
  reducers: {
    clearStatus: (state: FontState) => {
      state.status.fetch = AsyncStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFonts.pending, (state) => {
        state.status.fetch = AsyncStatus.LOADING;
      })
      .addCase(fetchFonts.fulfilled, (state, { payload }: { payload: FontsInner[] }) => {
        state.fonts = payload;
        state.status.fetch = AsyncStatus.SUCCESS;
      })
      .addCase(fetchFonts.rejected, (state) => {
        state.status.fetch = AsyncStatus.FAILED;
      });
  },
});

export const { clearStatus } = fontSlice.actions;

export const fontSelector = (state: RootState) => state.font;

export default fontSlice.reducer;
