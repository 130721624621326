import { Chip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';

import { Result } from '@/generated/types/typescript-axios';
import { Font } from '@/types';

type Props = {
  fontCases: Font[];
  nickname: string;
  results: Result | undefined;
  targetFontId: number;
  handleClickFont: (fontId: number) => void;
};

export default function Heading({
  fontCases,
  nickname,
  results,
  targetFontId,
  handleClickFont,
}: Props) {
  const allFontsId = 0;
  return (
    <Stack direction="row" justifyContent="space-between" gap={2} flexWrap="wrap" sx={{ mb: 3 }}>
      {/* ユーザー名 */}
      <Typography>{nickname}</Typography>
      {/* 書体Chips */}
      <Stack direction="row" spacing={1} sx={{ overflow: 'auto' }}>
        {/* すべて */}
        <Chip
          color={targetFontId === allFontsId ? 'primary' : 'default'}
          label="すべて"
          variant="outlined"
          onClick={targetFontId === allFontsId ? undefined : () => handleClickFont(allFontsId)}
        />
        {/* 各書体 */}
        {fontCases?.map(({ id, name }: any) => (
          <Chip
            key={id}
            color={targetFontId === id ? 'primary' : 'default'}
            label={name}
            variant={
              results?.assignments?.find((assignment) => {
                return assignment?.font?.id == id;
              })
                ? 'outlined'
                : 'filled'
            }
            onClick={
              results?.assignments?.find((assignment) => {
                return assignment?.font?.id == id;
              })
                ? () => handleClickFont(id)
                : undefined
            }
          />
        ))}
      </Stack>
    </Stack>
  );
}
