import { useEffect, useState } from 'react';

import { esStudentCases, jhsStudentCases, preStudentCases } from '@/const';
import { assignmentSelector, clearStatusLabel, getStatusLabel } from '@/services/assignmentSlice';
import { clearStatus as clearFontStatus, fetchFonts, fontSelector } from '@/services/fontSlice';
import {
  clearStatus as clearHandbookStatus,
  fetchHandbooks,
  handbookSelector,
} from '@/services/handbookSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { AsyncStatus, CourseId, GradeObject, HandbookStudentCaseWithStatus } from '@/types';

type Props = {
  year: number;
  month: number;
};

export default function useHandbookManagement({ year, month }: Props) {
  const dispatch = useAppDispatch();
  const { handbooks, status } = useAppSelector(handbookSelector);
  const { fonts } = useAppSelector(fontSelector);
  const { statusLabel, status: assignmentStatus } = useAppSelector(assignmentSelector);
  const studentFonts = fonts.filter((font) => font.course_type === CourseId.STUDENT);

  // Local state
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    dispatch(fetchFonts(null));
    dispatch(
      getStatusLabel({
        year,
        month,
      })
    );
    dispatch(
      fetchHandbooks({
        year,
        month,
      })
    );

    // Unmount時にstatusをクリア
    return () => {
      dispatch(clearFontStatus());
      dispatch(clearHandbookStatus());
      dispatch(clearStatusLabel());
    };
  }, [year, month]);

  const setStudentCases = (studentCase: GradeObject[]): HandbookStudentCaseWithStatus[] => {
    return studentCase.map((studentCase) => {
      return {
        ...studentCase,
        fonts_array: studentFonts.map((item) => {
          return {
            font_id: item.id,
            name: item.name,
            is_registered: !!handbooks.find(
              ({ font, grade_id }) => font?.id === item.id && grade_id === studentCase.grade_id
            ),
          };
        }),
        is_all_registered: !studentFonts
          .map((item) => {
            return !!handbooks.find(
              ({ font, grade_id }) => font?.id === item.id && grade_id === studentCase.grade_id
            );
          })
          .some((is_registered) => !is_registered),
      };
    });
  };

  // 一般/学生api切り分け
  // 一般
  const adultCasesWithStatus = fonts
    .filter((font) => font.course_type === CourseId.ADULT)
    .map(({ id, name }) => {
      return {
        id: id,
        name: name,
        is_registered: !!handbooks.find(({ font }) => font?.id === id),
      };
    });

  // 学生
  const preStudentCasesWithStatus = setStudentCases(preStudentCases);
  const esStudentCasesWithStatus = setStudentCases(esStudentCases);
  const jhsStudentCasesWithStatus = setStudentCases(jhsStudentCases);

  return {
    activeTab,
    setActiveTab,
    adultCasesWithStatus,
    preStudentCasesWithStatus,
    esStudentCasesWithStatus,
    jhsStudentCasesWithStatus,
    loading: status.bulkFetch === AsyncStatus.LOADING,
    statusLabel,
    getStatusLabelLoading: assignmentStatus.fetchStatusLabel === AsyncStatus.LOADING,
  };
}
