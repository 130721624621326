import CircleIcon from '@mui/icons-material/Circle';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Box,
  Breadcrumbs,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import Loader from '@/components/Loader';
import { fetchFonts, fontSelector } from '@/services/fontSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { CourseId, URL } from '@/types';
import { AsyncStatus } from '@/types';

export default function FontList() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { year, month, gradeId } = useParams();
  const { fonts, status } = useAppSelector(fontSelector);

  useEffect(() => {
    if (gradeId) {
      dispatch(fetchFonts(CourseId.STUDENT));
    } else {
      dispatch(fetchFonts(CourseId.ADULT));
    }
  }, []);

  const onClickNavigation = (fontId: number) => {
    if (gradeId) {
      return navigate(`/handbook-detail/${year}/${month}/grade/${gradeId}/font/${fontId}`);
    } else {
      return navigate(`/handbook-detail/${year}/${month}/adult/font/${fontId}`);
    }
  };

  return (
    <Box sx={{ p: 4, pt: 0 }}>
      <Breadcrumbs
        sx={{ pb: 3 }}
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link to={URL.HANDBOOKS}>&gt; 月別 競書課題</Link>
      </Breadcrumbs>
      <Box sx={{ width: '100%', mx: 'auto', maxWidth: 360, bgcolor: 'background.paper' }}>
        <Typography
          variant="h2"
          align="center"
          sx={{ mt: 2, mb: 4, fontSize: 20, fontWeight: 'bold' }}
        >
          {year}年{month}月 競書課題
        </Typography>
      </Box>
      {status.fetch === AsyncStatus.LOADING ? (
        <Loader />
      ) : (
        fonts.map(({ id, name }) => (
          <ListItem disablePadding key={id}>
            <ListItemButton onClick={() => onClickNavigation(id)}>
              <CircleIcon fontSize="small" sx={{ color: grey[600] }} />
              <ListItemText primary={'「' + name + '」 課題手本'} />
            </ListItemButton>
          </ListItem>
        ))
      )}
    </Box>
  );
}
