import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { authSelector, fetchUserInfo } from '@/services/authSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { AsyncStatus } from '@/types';

import Loader from './Loader';

const LOGIN_URL = process.env.REACT_APP_SAASUS_URL ?? '';

export default function Auth() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { status } = useAppSelector(authSelector);

  useEffect(() => {
    dispatch(fetchUserInfo()).then((res) => {
      if (res.meta.requestStatus === 'rejected') {
        window.location.href = LOGIN_URL;
      }
    });
  }, [location.pathname]);

  return status.fetch === AsyncStatus.LOADING ? (
    <Loader height="100vh" />
  ) : status.fetch === AsyncStatus.SUCCESS ? (
    <Outlet />
  ) : null;
}
