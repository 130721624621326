import axios from 'axios';

import { onReqFulfilled, onResFulfilled, onResRejected } from '@/utils/interceptor';

const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'XMLHttpRequest',
  },
  withCredentials: true,
});

axiosInstance.interceptors.request.use(onReqFulfilled);
axiosInstance.interceptors.response.use(onResFulfilled, onResRejected);

export default axiosInstance;
