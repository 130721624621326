import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Grid, Stack } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  data: {
    fontName: string;
    groups: {
      name: string;
      sum: number;
      ranks: {
        rankName: string;
        path: string;
        count: number;
      }[];
    }[];
  };
};

const TableArea = ({ data }: Props) => {
  return (
    <Grid container maxWidth="md" alignItems="center" spacing={2}>
      {data.groups.map((group, i) => (
        <Grid key={i} item xs={12} md={i === 0 ? 12 : 6}>
          <Grid
            container
            sx={{
              width: '100%',
              borderRadius: 1,
              overflow: 'hidden',
              border: '1px solid #546d7529',
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                backgroundColor: '#1e88e529',
                color: '#1e88e5',
                fontSize: 16,
                fontWeight: 700,
                textAlign: 'center',
                py: 1,
              }}
            >
              {data.fontName} {group.name} ({group.sum}名)
            </Grid>
            {group.ranks.map((rank, j) => (
              <Grid
                key={j}
                item
                xs={6}
                sx={{
                  borderRight: j % 2 === 0 ? '1px solid #546d7529' : 'none',
                  borderBottom:
                    (group.ranks.length % 2 === 0 && j <= group.ranks.length - 3) ||
                    (group.ranks.length % 2 === 1 && j <= group.ranks.length - 2)
                      ? '1px solid #546d7529'
                      : '',
                  ':hover': { backgroundColor: '#546d751a' },
                }}
              >
                <Link to={rank.path} style={{ textDecoration: 'none' }}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ py: 1, px: 2, color: 'initial' }}
                  >
                    <Stack direction="row" justifyContent="space-between" flexGrow={1}>
                      <Box>{rank.rankName}</Box>
                      <Box>{rank.count}人</Box>
                    </Stack>
                    <Box sx={{ fontSize: 8, pl: 1 }}>
                      <ArrowForwardIosIcon fontSize="inherit" />
                    </Box>
                  </Stack>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default TableArea;
